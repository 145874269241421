import * as hdf5 from 'jsfive';
import * as PIXI from 'pixi.js-legacy';
import { Dataset } from './types';

export enum GL_FORMATS {
  RED = 0x1903,
  RGBA = 0x1908,
  RGB = 0x1907,
  // NOTE: There are additional WebGL formats that could be used.
}

export enum GL_INTERNAL_FORMATS {
  R32F = 0x822e,
  // NOTE: There are additional WebGL formats that could be used.
}

export interface ImageBuffer {
  buffer: Uint8Array | Float32Array;
  width: number;
  height: number;
  format: GL_FORMATS;
  internalFormat: GL_INTERNAL_FORMATS;
  type: PIXI.TYPES;
}

/**
 * We could set the visibility to false but that must actually remove the mesh because it messes up the CPR centerline centering.
 */
export const blankImageBuffer = {
  width: 1,
  height: 1,
  buffer: new Float32Array([-10000]),
  format: GL_FORMATS.RED,
  internalFormat: GL_INTERNAL_FORMATS.R32F,
  type: PIXI.TYPES.FLOAT,
};

class CustomBufferResource extends PIXI.Resource {
  data: Float32Array | Uint8Array;
  format: GL_FORMATS;
  internalFormat: GL_INTERNAL_FORMATS;
  type: PIXI.TYPES;

  constructor(
    data: Float32Array | Uint8Array,
    width: number,
    height: number,
    format: GL_FORMATS,
    internalFormat: GL_INTERNAL_FORMATS,
    type: PIXI.TYPES
  ) {
    super(width, height);
    this.data = data;
    this.format = format;
    this.internalFormat = internalFormat;
    this.type = type;
  }

  upload(
    renderer: PIXI.Renderer,
    baseTexture: PIXI.BaseTexture,
    glTexture: any
  ) {
    if (baseTexture.target == null) {
      return false;
    }

    const gl = renderer.gl;

    gl.pixelStorei(
      gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL,
      baseTexture.alphaMode === 1 // PIXI.ALPHA_MODES.UNPACK but `PIXI.ALPHA_MODES` are not exported
    );

    glTexture.width = baseTexture.width;
    glTexture.height = baseTexture.height;

    gl.texImage2D(
      // Note, a GL Target Type is a number.
      // See: https://pixijs.download/dev/docs/PIXI.html#TARGETS
      baseTexture.target.valueOf(),
      0,
      this.internalFormat,
      baseTexture.width,
      baseTexture.height,
      0,
      this.format,
      this.type,
      this.data
    );

    return true;
  }
}

/**
 * Create an image buffer object from a dataset object.
 * If transpose is true the image data rows and columns will be flipped as will the shape (ie [X, Y] vs the default [Y, X]).
 * The buffer will be normalized from the range HU_MIN to HU_MAX.
 */
export const createImageBufferFromDataset = (
  dataset: Dataset,
  transpose: boolean
): ImageBuffer => {
  const width = dataset.shape[transpose ? 0 : 1];
  const height = dataset.shape[transpose ? 1 : 0];
  const buffer = new Float32Array(width * height);

  const data = dataset.value;
  // By default the data is stored row by row.
  if (!transpose) {
    for (let index = 0; index < width * height; index++) {
      buffer[index] = data[index];
    }
  }
  // If the image is transposed then rather than being stored row by row it is stored column by column.
  else {
    let index = 0;
    for (let j = 0; j < height; j++) {
      for (let i = 0; i < width; i++) {
        buffer[index++] = data[i * height + j];
      }
    }
  }

  return {
    buffer,
    width,
    height,
    format: GL_FORMATS.RED,
    internalFormat: GL_INTERNAL_FORMATS.R32F,
    type: PIXI.TYPES.FLOAT,
  };
};

// Utility function
const createHDF = (data: ArrayBuffer) => {
  return new hdf5.File(data, '');
};

/**
 * Take a raw buffer of imageData, convert it into a H5 file, then extract the image data out into an ImageBuffer.
 */
export const createImageBufferFromImageData = (
  imageData: ArrayBuffer,
  transpose: boolean
): ImageBuffer => {
  if (imageData) {
    const file = createHDF(imageData);
    if (file) {
      return createImageBufferFromDataset(file.get('data'), transpose);
    }
  }
  // If something went wrong we return a default fallback buffer.
  return blankImageBuffer;
};

/**
 * Create a new PIXI.Texture from a ImageBuffer definition.
 */
export const createTexture = (imageBuffer: ImageBuffer): PIXI.Texture => {
  const resource = new CustomBufferResource(
    imageBuffer.buffer,
    imageBuffer.width,
    imageBuffer.height,
    imageBuffer.format,
    imageBuffer.internalFormat,
    imageBuffer.type
  );
  return new PIXI.Texture(
    new PIXI.BaseTexture(resource, { scaleMode: PIXI.SCALE_MODES.LINEAR })
  );
};
