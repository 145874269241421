// @ts-ignore
import { pointInPolygon } from 'geometric';
import * as PIXI from 'pixi.js-legacy';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  KEY_CT_NON_CONTRAST,
  LOG_LEVEL,
  MOUSE_BUTTONS,
  NAV_TABS,
  PRIMARY_VESSEL_COLOR_MAPPING,
  PRESET_MAP,
} from '../../config';
import { CTVolumeOverlay } from '../../context/contrast-types';
import { useCprContext } from '../../context/cpr-context';
import { isNonContrastSeries } from '../../context/drag-n-drop/helpers';
import { useStoreContext } from '../../context/store-context';
import { ProcessedLesionData } from '../../context/types';
import { useViewConfigContext } from '../../context/viewConfig';
import { WindowLevels } from '../../context/window-types';
import { CTNonContrastViewerData, XYCoords } from '../../reducers/vessel-data';
import { ObjectArray } from '../../types';
import { captureMouse } from '../../utils/captureMouse';
import NonContrastViewHeader from '../../views/CTVolume/NonContrastViewHeader/NonContrastViewHeader';
import DropZone, { NON_CONTRAST_DROP_ZONE } from '../DropZone';
import { OnReadyInf } from '../WebGLViewer/types';
import WebGLViewer from '../WebGLViewer/WebGLViewer';
// @ts-ignore
import worker from './CTVolumeNonContrastViewer.worker';
import { TessGraphics } from './TessGraphics';
import { useWindowContext } from '../../context/window-context';
import { useMeasurementToolContext } from '../../context/measurement-tools-context';
import {
  createEllipseSprite,
  createRulerSprite,
  onMouseDownRuler,
  onMouseMoveRuler,
  onMouseUpRuler,
  onMouseMoveEllipse,
  onMouseUpEllipse,
  onZoomMeasurementTools,
  MEASUREMENT_SETTINGS,
  isRulerHandled,
  onMouseDownEllipse,
} from '../../utils/measurementTools';
import {
  MeasurementGraphics,
  markerPositionsInterface,
  HuData,
  MM_TO_PX,
  HandlePosition,
} from '../../utils/measurementTools/types';

const LESION_SETTINGS = {
  MAX_AMOUNT: 50000,
  COLORS: PRIMARY_VESSEL_COLOR_MAPPING,
  MARKER_DEFAULT_WIDTH: 80,
  MARKER_MIN_WIDTH: 10,
};

// The size of each lesion sprite texture dimension.
const lesionSpriteSize = 1;

/**
 * Create a super simple lesionSpriteSize x lesionSpriteSize white rectangular lesion texture.
 */
const createLesionTexture = () => {
  const buffer = new Uint8Array(4 * lesionSpriteSize * lesionSpriteSize);
  for (let i = 0; i < 4 * lesionSpriteSize * lesionSpriteSize; i++) {
    buffer[i] = 255;
  }
  return PIXI.Texture.fromBuffer(buffer, lesionSpriteSize, lesionSpriteSize);
};

const bringToFront = (sprite: PIXI.Sprite) => {
  if (sprite.parent) {
    const parent = sprite.parent;
    parent.removeChild(sprite);
    parent.addChild(sprite);
  }
};

const polygonToArray = (polygon: XYCoords[]) => {
  return polygon.map((point) => [point.x, point.y]);
};

const getLesionColor = (category: string): number => {
  if (LESION_SETTINGS.COLORS[category]) {
    return Number(LESION_SETTINGS.COLORS[category].replace('#', '0x'));
  }
  return Number('0xff0000');
};

interface SpriteLesion {
  sprite: PIXI.Sprite;
  lesion: ProcessedLesionData;
}

interface Props {
  updatedLesions: any[];
  onLesionEnter: (lesion: ProcessedLesionData, event: React.MouseEvent) => void;
  onLesionLeave: () => void;
  onLesionLasso: (selected: any[], event: Event) => void;
  onWindowLevelsChange: (windowLevels: WindowLevels) => void;
  slice: number;
  onSliceChange: (sliceIndex: number) => void;
  onHueChange: (hue: number | null) => void;
  onUpdateLesions: () => void;
  onZoom: () => void;
  onDrag: () => void;
  onDraw: () => void;
  onReady: (params: any) => void;
  label: string;
  nonContrastViewOverlay: CTVolumeOverlay;
  setNonContrastViewOverlay: (overlay: CTVolumeOverlay) => void;
  screenshotRef: any;
  onTakeScreenshot: (image: any) => void;
  onCloseScreenshot: () => void;
  onSetFullLesionData: (lesionData: any[]) => void;
  vesselCalciumVisible: { [key: string]: boolean } | undefined;
  viewerData?: CTNonContrastViewerData;
}

export const CTVolumeNonContrastViewer: React.FC<Props> = ({
  updatedLesions,
  onLesionEnter,
  onLesionLeave,
  onLesionLasso,
  onWindowLevelsChange: _onWindowLevelsChange,
  slice,
  onSliceChange,
  onHueChange,
  onUpdateLesions,
  onZoom: onZoomCTVolume,
  onDrag,
  onDraw,
  onReady,
  label,
  nonContrastViewOverlay,
  setNonContrastViewOverlay,
  screenshotRef,
  onTakeScreenshot,
  onCloseScreenshot,
  onSetFullLesionData,
  vesselCalciumVisible,
  viewerData,
}) => {
  const {
    patientID,
    runID,
    lesionData,
    visibleTab,
    nonContrastSpacing,
  } = useStoreContext();
  const {
    state: { seriesId },
  } = useViewConfigContext();
  const { triggerResetPanAndZoom } = useCprContext();

  //measurement tool context
  const {
    isEllipseActive,
    isRulerActive,
    measurementToolStartPoint,
    setMeasurementToolStartPoint,
    isDraggingMeasurementToolRef,
    isClickDownMeasurementToolRef,
    measurementTargetRef,
    isMeasurementMode,
    isOverMeasurementToolRef,
    hitAreaEllipseToolRef,
    clearMeasurements,
  } = useMeasurementToolContext();

  // measurement tools
  const msmToolsContainerRef = useRef<PIXI.Container | null>(null);
  const measurementSpriteRef = useRef<MeasurementGraphics | null>(null);
  const handleTargetNameRef = useRef<HandlePosition | null>(null);
  const isEllipseActiveRef = useRef(isEllipseActive);
  const isRulerActiveRef = useRef(isRulerActive);
  //store all positions of markers of the measurement tools to work with collision detection
  const markerPositionsRef = useRef<markerPositionsInterface>({});
  const [initialPixiZoom, setInitialPixiZoom] = useState<number | null>(null);

  // The default scaling to apply to the image so that it will fit snugly inside the view.
  const defaultScaleRef = useRef<number>(1);
  // Is this component performing its initial loading sequence?
  const [loading, setLoading] = useState(true);
  // huData including mean and standard deviation within an area - used with measurement tool
  const [huData, setHuData] = useState<HuData | undefined>();
  // True if the user is currently holding down the lasso modifier keyboard key.
  const lassoKeyDownRef = useRef<boolean>(false);
  const [disableControls, setDisableControls] = useState(false);
  const [lesionMarkers, setLesionMarkers] = useState<any>();
  const {
    nonContrastWindowLevels,
    setNonContrastWindowLevels,
  } = useWindowContext();
  const containerRef = useRef<PIXI.Container | undefined>(undefined);
  const holderRef = useRef<HTMLDivElement | undefined>(undefined);
  const lassoSpriteRef = useRef<TessGraphics | undefined>();
  const getContainerMousePosRef = useRef<
    (holderPoint: XYCoords | undefined) => XYCoords
  >();
  const appRef = useRef<PIXI.Application | undefined>();
  const lesionContainerRef = useRef<PIXI.ParticleContainer>();
  const lesionSpritesRef = useRef<PIXI.Sprite[]>([]);
  // Are we currently processing the lesionData to make it fit the structure this component wants.
  const [processingLesionData, setProcessingLesionData] = useState(true);
  // The array of processed lesionData.
  const processedLesionDataRef = useRef<ProcessedLesionData[][]>([]);
  // The array of all currently selected lesions. If hovering the house this will have at most one entry, if a lasso was drawn then multiple lesions will be in this array.
  const selectedLesionsRef = useRef<SpriteLesion[]>([]);
  const lassoPointsRef = useRef<XYCoords[]>([]);
  const mountedRef = useRef(false);
  const currentMousePosRef = useRef({ x: 0, y: 0 });
  const primaryColorRef = useRef(0xff9900);
  const visibleVesselRef = useRef<ObjectArray<boolean> | undefined>();

  useEffect(() => {
    if (visibleTab !== NAV_TABS.ctVolumeTab) return;

    isEllipseActiveRef.current = isEllipseActive;
    isRulerActiveRef.current = isRulerActive;
    const scale = getMeasurementRendererScale();
    // if there is a measurement tool active, reset the state
    if (
      measurementTargetRef.current &&
      measurementTargetRef.current.lineName ===
        MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse
    ) {
      createEllipseSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.NonContrast,
        sprite: measurementTargetRef.current,
        points: measurementTargetRef.current.points,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        callback: onMouseDownEllipseNode,
        hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
        huData,
        pixelsPerMillimeter: getMillimeterPerPixel(),
      });
    }
    if (
      measurementTargetRef.current &&
      measurementTargetRef.current.lineName ===
        MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler
    ) {
      createRulerSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.NonContrast,
        sprite: measurementTargetRef.current,
        start: measurementTargetRef.current.startPoint,
        end: measurementTargetRef.current.endPoint,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        pixelsPerMillimeter: getMillimeterPerPixel(),
      });
    }
    // disable click event for ellipse
    if (isEllipseActiveRef.current && msmToolsContainerRef.current) {
      msmToolsContainerRef.current.children.forEach((child: any) => {
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse)
          child.cursor = 'move';
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler) {
          child.cursor = 'default';
          child._fillStyle.alpha = 1;
        }
      });
    }
    // disable click event for ruler
    if (isRulerActiveRef.current && msmToolsContainerRef.current) {
      msmToolsContainerRef.current.children.forEach((child: any) => {
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler)
          child.cursor = 'move';
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse) {
          child.cursor = 'default';
          child._fillStyle.alpha = 0;
        }
      });
    }

    isDraggingMeasurementToolRef.current = undefined;
    measurementTargetRef.current = null;
    handleTargetNameRef.current = null;
    isOverMeasurementToolRef.current = '';
  }, [isRulerActive, isEllipseActive]);

  /**
   * Calculate the length, in millimeters, of each pixel being rendered by the viewer.
   **/
  const getMillimeterPerPixel = (): number => {
    if (nonContrastSpacing?.x == null || containerRef.current == null) {
      return MM_TO_PX;
    }
    let pixiZoom = initialPixiZoom;
    if (pixiZoom == null) {
      setInitialPixiZoom(containerRef.current.scale.x);
      pixiZoom = containerRef.current.scale.x;
    }
    return (nonContrastSpacing.x * pixiZoom) / defaultScaleRef.current;
  };

  /**
   * cleanup all measurement tools
   **/
  const cleanUpMeasurementTools = useCallback(() => {
    if (msmToolsContainerRef.current) {
      msmToolsContainerRef.current.destroy({
        children: true,
      });
      msmToolsContainerRef.current = null;
      measurementSpriteRef.current = null;
      handleTargetNameRef.current = null;
      markerPositionsRef.current = {};
      isOverMeasurementToolRef.current = '';
      hitAreaEllipseToolRef.current = {};

      msmToolsContainerRef.current = new PIXI.Container();
      if (containerRef.current) {
        containerRef.current.addChild(msmToolsContainerRef.current);
      }
    }
  }, [hitAreaEllipseToolRef, isOverMeasurementToolRef]);

  useEffect(() => {
    if (!isMeasurementMode) cleanUpMeasurementTools();
    return () => {
      isDraggingMeasurementToolRef.current = undefined;
      measurementTargetRef.current = null;
    };
  }, [isMeasurementMode]);

  useEffect(() => {
    cleanUpMeasurementTools();
  }, [clearMeasurements]);

  /**
   * Cleanup any PIXI WebGL objects that we will recreate once a reset finishes or we need to cleanup on unmount.
   */
  const cleanup = () => {
    // TODO: Ideally the selected lesions and the context menu could persist over a reset but because the
    // selectedLesions resefernce the sprites we're going to recreate this makes it a fair bit of work to
    // support a small edge case.

    // Hide the context menu etc.
    if (onDraw) {
      onDraw();
    }
    // Clear the selected lesions.
    selectedLesionsRef.current = [];

    // Clear references to avoid cyclic references and help the garbage collector out.
    appRef.current = undefined;
    containerRef.current = undefined;
    holderRef.current = undefined;
    getContainerMousePosRef.current = undefined;

    // Remove any lesion sprites from the lesionContainerRef and free the lesionContainerRef.
    lesionContainerRef.current && lesionContainerRef.current.removeChildren();
    if (lesionContainerRef.current) {
      lesionContainerRef.current.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
      lesionContainerRef.current = undefined;
    }

    // Destroy up the lesion sprites.
    lesionSpritesRef.current.forEach((sprite) => {
      sprite.destroy();
    });
    lesionSpritesRef.current = [];

    // Clear the lasso sprite.
    if (lassoSpriteRef.current) {
      lassoSpriteRef.current.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
      lassoSpriteRef.current = undefined;
    }

    // Only clean up the processed lesion data if we have unmounted.
    if (!mountedRef.current) {
      processedLesionDataRef.current = [];
    }
  };

  // WARNING below matches windowing context, however that context
  // is not expected to be used for the non-contrast
  const KEYBOARD_BUTTONS = {
    LASSO: ['Space', 'ShiftLeft', 'ShiftRight'],
    WINDOWING: Object.keys(PRESET_MAP),
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If the conponent and the lesionData have loaded and we have yet to process the lesionData
   * then process it now.
   */
  useEffect(() => {
    if (lesionData && !loading && processingLesionData) {
      processLesionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesionData, loading]);

  /**
   * When the lesion data processing has finished we can show the results.
   */
  useEffect(() => {
    visibleVesselRef.current = vesselCalciumVisible;
    if (!processingLesionData) {
      drawLesionDataLayer(slice);
      createLesionMarkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingLesionData, vesselCalciumVisible]);

  const init = ({
    app,
    container,
    holder,
    getContainerMousePos,
    sliceNo,
    defaultScale,
  }: OnReadyInf) => {
    appRef.current = app;
    containerRef.current = container;
    lassoSpriteRef.current = new TessGraphics();
    appRef.current.stage.addChild(lassoSpriteRef.current);
    holderRef.current = holder;
    getContainerMousePosRef.current = getContainerMousePos;
    createLesionSprites();
    // Set the initial slice index to the last slice? Only do this on the initial load.
    if (loading) {
      onSliceChange(sliceNo - 1);
    }
    setLoading(false);
    // Pass the deselectLesions function up to the CTVolume component.
    onReady && onReady({ deselectLesions });
    // If the lesion data has already been processed (like if we reset due to a lost context) then we should draw the lesion layer now.
    if (lesionData && !processingLesionData) {
      drawLesionDataLayer(slice);
      createLesionMarkers();
      // Draw the lasso (as required). Tthe user may have been drawing it when the reset happened.
      drawLasso();
    }

    // initialise msmToolsContainerRef if not already initialised
    if (!msmToolsContainerRef.current) {
      defaultScaleRef.current = defaultScale;
      msmToolsContainerRef.current = new PIXI.Container();
      msmToolsContainerRef.current.sortableChildren = true;
    }
    container.addChild(msmToolsContainerRef.current!);
  };

  let keysPressed: any = {};

  const getMarkerComponent = (width: number, vessel: string) => {
    // If the calculated with is less than the min width
    // Set marker width to the min width value
    const markerWidth =
      width > LESION_SETTINGS.MARKER_MIN_WIDTH
        ? width
        : LESION_SETTINGS.MARKER_MIN_WIDTH;

    return (
      <span
        title={vessel}
        className="lesion-marker"
        style={{
          width: `${markerWidth}px`,
          background: `${PRIMARY_VESSEL_COLOR_MAPPING[vessel]}`,
        }}
      />
    );
  };

  const createLesionMarkers = useCallback(() => {
    let highestLesionCount = 0;
    let lesionPerSlice: { [key: number]: any } = {};
    if (!processedLesionDataRef.current) return;
    const lesionSliceCount = processedLesionDataRef.current.length;

    for (var i = 0; i < lesionSliceCount; i++) {
      const sliceLesions = processedLesionDataRef.current[i];
      if (sliceLesions == null) {
        console.warn(
          `CTVolumeNonContrastViewer: Missing lesion data at position ${i}.`
        );
        continue;
      }
      const filteredSliceLesions = sliceLesions.filter(
        (l) => l['category'] !== 'other'
      );
      // Group lesions on each slice and the total number of point
      // This will determine what colour and the width of the marker
      if (filteredSliceLesions.length > 0) {
        const reduced = filteredSliceLesions.reduce((acc: any, obj: any) => {
          let key = obj['category'];
          if (acc[key]) {
            acc[key]++;
          } else {
            acc[key] = 1;
          }
          return acc;
        }, {});
        lesionPerSlice[i] = reduced;
        highestLesionCount =
          filteredSliceLesions.length > highestLesionCount
            ? filteredSliceLesions.length
            : highestLesionCount;
      }
    }

    // Scan through each slice and only choose the vessel with the largest lesion
    // create marker element based on the vessel and the size of that lession
    const markers = Object.entries(lesionPerSlice).reduce((prev, current) => {
      const currentValue = current[1];
      const highestVessel = Object.keys(currentValue).reduce((a, b) =>
        currentValue[a] > currentValue[b] ? a : b
      );
      const markerWidth = Math.ceil(
        (currentValue[highestVessel] / highestLesionCount) *
          LESION_SETTINGS.MARKER_DEFAULT_WIDTH
      );
      return {
        ...prev,
        [current[0]]: getMarkerComponent(markerWidth, highestVessel),
      };
    }, {});

    setLesionMarkers(markers);
  }, [setLesionMarkers]);

  /**
   * Create an array of lesion sprites that can be positioned where the lesions are on the active slice.
   */
  const createLesionSprites = () => {
    // Check we haven't already created the lesion sprites.
    if (!lesionContainerRef.current && lesionSpritesRef.current.length === 0) {
      // Create a PIXI container for the lesions and add it to the containerRef.
      const lesionContainer = new PIXI.ParticleContainer();
      lesionContainer.autoResize = true;
      // @ts-ignore maxSize?
      lesionContainer.maxSize = LESION_SETTINGS.MAX_AMOUNT;
      lesionContainer.setProperties({
        tint: true,
      });
      lesionContainerRef.current = lesionContainer;
      containerRef.current && containerRef.current.addChild(lesionContainer);

      // Create a new lesion texture.
      const texture = createLesionTexture();
      // Create the maximum number of lesion sprites.
      for (let i = 0; i <= LESION_SETTINGS.MAX_AMOUNT; i++) {
        const sprite = new PIXI.Sprite(texture);
        // Set the sprite to be one (scaled) pixel big.
        sprite.width = 1;
        sprite.height = 1;
        lesionSpritesRef.current.push(sprite);
      }
    }
  };

  /**
   * Process the lesionData so that it fits the structure used by this component.
   */
  const processLesionData = () => {
    if (!lesionData) {
      return;
    }

    // Create a new web worker.
    const localWorker = new worker();
    localWorker.onerror = (err: Error) => {
      console.error('Non Contrast WebWorker error', err);
    };
    localWorker.onmessage = (e: any) => {
      if (mountedRef.current) {
        processedLesionDataRef.current = e.data;
        setProcessingLesionData(false);
        onSetFullLesionData && onSetFullLesionData(e.data);
      }
      // Clean up the web worker - we only wanted it to do a single task.
      localWorker.terminate();
    };
    localWorker.postMessage(lesionData);
  };

  /**
   * Draw the lesions for the specified slice index (index) by using our pool of lesion sprites (in lesionSpritesRef)
   * and adding the ones we want to the lesionContainerRef after updating their position and tint.
   */
  const drawLesionDataLayer = useCallback((index) => {
    // Check we have initialized the lesion container.
    if (lesionContainerRef.current) {
      // Remove all lesion sprites from the lesion container.
      lesionContainerRef.current.removeChildren();

      // Check we have lesion data for this slice.
      if (
        processedLesionDataRef.current &&
        processedLesionDataRef.current[index]
      ) {
        // Loop through every lesion position on this slice.
        processedLesionDataRef.current[index].forEach((lesion, i) => {
          // Do not draw if the lesion's vessel is toggled off in info section.
          if (!visibleVesselRef.current?.[lesion.category]) return;
          // Position the lesion sprite where the lesion is positioned and tint it the colour of this lesion's category.
          if (lesionSpritesRef.current[i]) {
            const sprite = lesionSpritesRef.current[i];
            sprite.x = lesion.x;
            sprite.y = lesion.y;
            sprite.tint = getLesionColor(lesion.category);
            if (lesionContainerRef.current) {
              lesionContainerRef.current.addChild(sprite);
            }
          } else {
            console.error(
              `Error: not enough pre rendered sprites to draw lesion '${i}'`
            );
          }
        });
      }
    } else {
      if (LOG_LEVEL > 0) {
        console.log(
          'Warning: Lesion container not initialised, lesion layer was not drawn for slice',
          index
        );
      }
    }
  }, []);

  /**
   * One or more lesions have changed their category; reassign the lesions, redraw all lesions, and
   * call the callback to notify the parent object.
   */
  const updateLesions = useCallback(() => {
    if (processedLesionDataRef.current) {
      // Loop through every slice of lesion data.
      processedLesionDataRef.current.forEach((slice) => {
        // Loop through every lesion in this slice.
        slice.forEach((lesion) => {
          // Update this slice with the new category if it was found in the list of lesions that have updated.
          updatedLesions.forEach((updated) => {
            if (updated.lesion_id === lesion.lesion_id) {
              lesion.category = updated.category;
            }
          });
        });
      });
      drawLesionDataLayer(slice);
      createLesionMarkers();
      onUpdateLesions && onUpdateLesions();
    }
  }, [
    onUpdateLesions,
    slice,
    updatedLesions,
    onUpdateLesions,
    createLesionMarkers,
    drawLesionDataLayer,
  ]);

  useEffect(() => {
    if (updatedLesions && updatedLesions.length) {
      updateLesions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedLesions, updateLesions]);

  /**
   * Redraw the slices when the active slice has changed.
   */
  useEffect(() => {
    drawLesionDataLayer(slice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slice]);

  /**
   * Recolour the selected lesions with their lesion colour and clear the selected lesions list.
   */
  const deselectLesions = () => {
    if (selectedLesionsRef.current.length) {
      selectedLesionsRef.current.forEach((selected) => {
        selected.sprite.tint = getLesionColor(selected.lesion.category);
      });
    }
    selectedLesionsRef.current = [];
  };

  const selectLesionWithHover = (event: React.MouseEvent) => {
    if (
      selectedLesionsRef.current.length > 1 ||
      lassoKeyDownRef.current ||
      !getContainerMousePosRef.current
    ) {
      return false;
    }
    const point = getContainerMousePosRef.current(currentMousePosRef.current);
    let over = undefined;
    if (
      processedLesionDataRef.current &&
      processedLesionDataRef.current[slice] &&
      point
    ) {
      processedLesionDataRef.current[slice].forEach((lesion, i) => {
        const sprite = lesionSpritesRef.current[i];
        if (sprite) {
          const hitArea = [
            [sprite.x, sprite.y],
            [sprite.x + sprite.width, sprite.y],
            [sprite.x + sprite.width, sprite.y + sprite.height],
            [sprite.x, sprite.y + sprite.height],
          ];
          const hit = pointInPolygon([point.x, point.y], hitArea);
          if (hit) {
            over = lesion;
            bringToFront(sprite);
            sprite.tint = getLesionColor('selected');
            let enter = false;
            if (selectedLesionsRef.current.length === 0) {
              enter = true;
            } else if (selectedLesionsRef.current.length === 1) {
              if (
                JSON.stringify(lesion) !==
                JSON.stringify(selectedLesionsRef.current[0].lesion)
              ) {
                enter = true;
              }
            }
            enter && onLesionEnter && onLesionEnter(lesion, event);
            selectedLesionsRef.current = [{ sprite, lesion }];
          } else if (sprite.tint === getLesionColor('selected')) {
            sprite.tint = getLesionColor(lesion.category);
          }
        }
      });
    }
    if (!over) {
      unselectLesions();
    }
    return true;
  };

  const clearLasso = useCallback(() => {
    if (lassoSpriteRef.current) {
      lassoSpriteRef.current.clear();
      lassoSpriteRef.current.lineStyle(1, primaryColorRef.current);
      lassoSpriteRef.current.beginFill(primaryColorRef.current, 0.2);
    }
  }, []);

  const resetLasso = useCallback(() => {
    clearLasso();
    lassoPointsRef.current = [];
  }, []);

  const selectLesionsWithLasso = useCallback(
    (event: Event) => {
      if (!lassoPointsRef.current.length || !getContainerMousePosRef.current) {
        return false;
      }
      const selected: XYCoords[] = [];
      if (
        lesionSpritesRef.current[slice] &&
        processedLesionDataRef.current &&
        processedLesionDataRef.current[slice]
      ) {
        const lasso = lassoPointsRef.current.map((point) => {
          return getContainerMousePosRef.current
            ? getContainerMousePosRef.current(point)
            : point;
        });
        // We precalculate these values outside the inner loop for speed.
        const polygonArray: number[][] = polygonToArray(lasso);
        const selectedLesionColor: number = getLesionColor('selected');
        // Loop through every lesion in the slice and apply the lasso selection.
        processedLesionDataRef.current[slice].forEach((lesion, i) => {
          if (lesionSpritesRef.current[i]) {
            const sprite = lesionSpritesRef.current[i];
            if (pointInPolygon([sprite.x, sprite.y], polygonArray)) {
              // The lesion is inside the lasso: select it.
              sprite.tint = selectedLesionColor;
              bringToFront(sprite);
              selected.push(lesion);
              selectedLesionsRef.current.push({ sprite, lesion });
            } else if (sprite.tint === selectedLesionColor) {
              // The lesion is currently selected but outside the lasso: unselect it.
              sprite.tint = getLesionColor(lesion.category);
            }
          }
        });
      }
      resetLasso();
      onLesionLasso && onLesionLasso(selected, event);
      return true;
    },
    [
      resetLasso,
      slice,
      onLesionLasso,
      getLesionColor,
      bringToFront,
      polygonToArray,
      pointInPolygon,
    ]
  );

  const drawLasso = useCallback(() => {
    if (
      lassoKeyDownRef.current &&
      lassoPointsRef.current &&
      lassoPointsRef.current.length &&
      lassoSpriteRef.current
    ) {
      bringToFront(lassoSpriteRef.current as any);
      clearLasso();
      lassoSpriteRef.current.drawPolygon(lassoPointsRef.current as any);
      lassoSpriteRef.current.endFill();
    }
  }, [clearLasso]);

  const onLassoDraw = () => {
    // Add the next point to the lasso.
    lassoPointsRef.current.push(currentMousePosRef.current);
    // Draw the lasso (as required).
    drawLasso();
    // Hide the context menu etc.
    onDraw && onDraw();
  };

  const getHolderMousePos = useCallback((point): XYCoords => {
    const holder = holderRef.current;
    if (!holder) return point;
    const offset = holder.getBoundingClientRect();
    return {
      x: point.x - offset.x,
      y: point.y - offset.y,
    };
  }, []);

  /**
   * Get the position of the mouse over the container from the global page position in the event.
   */
  const getMousePosForMeasurementTool = (event: React.MouseEvent): XYCoords => {
    const pos = { x: 0, y: 0 };
    if (holderRef.current && containerRef.current) {
      // Get the position and size of the component on the page.
      const holderOffset = holderRef.current.getBoundingClientRect();
      pos.x =
        (event.pageX - holderOffset.x - containerRef.current.x) /
        containerRef.current.scale.x;
      pos.y =
        (event.pageY - holderOffset.y - containerRef.current.y) /
        containerRef.current.scale.y;
    }
    return pos;
  };

  /**
   * Unselect any selected lesions, restore their colours to their correct colours, close the context menu.
   */
  const unselectLesions = useCallback(() => {
    if (selectedLesionsRef.current.length) {
      onLesionLeave && onLesionLeave();
      deselectLesions();
    }
  }, [deselectLesions, onLesionLeave]);

  const resetStateMeasurementTool = (resetState: any) => {
    if (!resetState) return;
    'measurementToolStartPoint' in resetState &&
      setMeasurementToolStartPoint(resetState.measurementToolStartPoint);
    'isDraggingMeasurementToolRef' in resetState &&
      (isDraggingMeasurementToolRef.current =
        resetState.isDraggingMeasurementToolRef);
    'measurementTargetRef' in resetState &&
      (measurementTargetRef.current = resetState.measurementTargetRef);
    'handleTargetNameRef' in resetState &&
      (handleTargetNameRef.current = resetState.handleTargetNameRef);
    'isClickDownMeasurementToolRef' in resetState &&
      (isClickDownMeasurementToolRef.current =
        resetState.isClickDownMeasurementToolRef);
    'isOverMeasurementToolRef' in resetState &&
      (isOverMeasurementToolRef.current = resetState.isOverMeasurementToolRef);
    'hitAreaEllipseToolRef' in resetState &&
      (hitAreaEllipseToolRef.current = resetState.hitAreaEllipseToolRef);
  };

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      // Clear any currently selected lesions.
      unselectLesions();
      // Capture the mouse pointer.
      captureMouse(event as any);

      const scale = getMeasurementRendererScale();
      if (isMeasurementMode) {
        let resetState = null;
        if (isRulerActiveRef.current) {
          resetState = onMouseDownRuler({
            isClickDownMeasurementToolRef:
              isClickDownMeasurementToolRef.current,
            isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
            measurementTargetRef: measurementTargetRef.current,
            scale: scale,
            btnClicked: event.buttons,
            pixelsPerMillimeter: getMillimeterPerPixel(),
          });
        }
        if (isEllipseActiveRef.current && msmToolsContainerRef.current) {
          resetState = onMouseDownEllipse({
            isClickDownMeasurementToolRef:
              isClickDownMeasurementToolRef.current,
            isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
            measurementSpriteRef: measurementSpriteRef.current,
            measurementTargetRef: measurementTargetRef.current,
            scale,
            onMouseDownEllipseNode,
            hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
            huData,
            pixelsPerMillimeter: getMillimeterPerPixel(),
            isOverMeasurementToolRef: isOverMeasurementToolRef.current,
            msmToolsContainerRef: msmToolsContainerRef.current,
            btnClicked: event.buttons,
          });
        }

        resetStateMeasurementTool(resetState);

        setMeasurementToolStartPoint(getMousePosForMeasurementTool(event));

        measurementSpriteRef.current = new PIXI.Graphics() as MeasurementGraphics;
        msmToolsContainerRef.current &&
          msmToolsContainerRef.current.addChild(measurementSpriteRef.current);
      }
    },
    [unselectLesions]
  );

  const onMouseDownEllipseNode = (event: React.MouseEvent) => {
    if (isMeasurementMode && isEllipseActiveRef.current && event.target) {
      isDraggingMeasurementToolRef.current =
        MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle;
      isClickDownMeasurementToolRef.current = true;

      measurementTargetRef.current = event.target;
    }
  };

  const onMouseDownStraightLine = (event: any) => {
    if (isMeasurementMode && isRulerActiveRef.current && event.target) {
      const scale = getMeasurementRendererScale();

      //active ruler clickin on a handle or line
      let target = event.target;
      if (isRulerHandled(target.lineName)) {
        target = target.parent;
      }
      if (target && target.startPoint) {
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: target,
          start: target.startPoint,
          end: target.endPoint,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale,
          pixelsPerMillimeter: getMillimeterPerPixel(),
        });
      }
      //if there is a ruler active and the user clicks on the ruler, then we need to change the state to inative
      if (
        (isDraggingMeasurementToolRef.current ===
          MEASUREMENT_SETTINGS.RULER_STATE.Active ||
          isDraggingMeasurementToolRef.current ===
            MEASUREMENT_SETTINGS.RULER_STATE.Handle) &&
        measurementTargetRef.current
      ) {
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef.current,
          start: measurementTargetRef.current.startPoint,
          end: measurementTargetRef.current.endPoint,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
          state: MEASUREMENT_SETTINGS.STATES.finish,
          scale,
          pixelsPerMillimeter: getMillimeterPerPixel(),
        });
      }
      if (isRulerHandled(event.target.lineName)) {
        isDraggingMeasurementToolRef.current =
          MEASUREMENT_SETTINGS.RULER_STATE.Handle;
        handleTargetNameRef.current = event.target.lineName;
        isClickDownMeasurementToolRef.current = true;
      }

      measurementTargetRef.current = target;
      isDraggingMeasurementToolRef.current =
        isDraggingMeasurementToolRef.current ||
        MEASUREMENT_SETTINGS.RULER_STATE.Active;
      isClickDownMeasurementToolRef.current = true;
    }
  };

  const onMouseMove = (event: React.MouseEvent) => {
    // We won't have a holderRef when initially loading or when resetting.
    if (holderRef.current) {
      currentMousePosRef.current = getHolderMousePos({
        x: event.pageX,
        y: event.pageY,
      });

      const scale = getMeasurementRendererScale();
      if (isMeasurementMode) {
        // if measurement  tools are active, don't zoom
        if (
          isMeasurementMode &&
          isDraggingMeasurementToolRef.current &&
          event.buttons === MOUSE_BUTTONS.BOTH
        ) {
          measurementTargetRef.current = null;
          handleTargetNameRef.current = null;
          isClickDownMeasurementToolRef.current = true;
          isDraggingMeasurementToolRef.current = undefined;
          return;
        }
        let resetState: any = null;

        if (isRulerActiveRef.current && isClickDownMeasurementToolRef.current)
          resetState = onMouseMoveRuler({
            isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
            measurementSpriteRef: measurementSpriteRef.current,
            measurementTargetRef: measurementTargetRef.current,
            handleTargetNameRef: handleTargetNameRef.current,
            start: measurementToolStartPoint,
            mousePosition: getMousePosForMeasurementTool(event),
            scale: scale,
            offsetX: event.movementX,
            offsetY: event.movementY,
            recalculatePixelsToMm: () => getMillimeterPerPixel(),
          });
        if (isEllipseActiveRef.current)
          resetState = onMouseMoveEllipse({
            isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
            measurementSpriteRef: measurementSpriteRef.current,
            measurementTargetRef: measurementTargetRef.current,
            start: measurementToolStartPoint,
            mousePosition: getMousePosForMeasurementTool(event),
            scale: scale,
            offsetX: event.movementX,
            offsetY: event.movementY,
            isOverMeasurementToolRef: isOverMeasurementToolRef.current,
            hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
            huData,
            recalculatePixelsToMm: () => getMillimeterPerPixel(),
          });

        resetStateMeasurementTool(resetState);
      } else if (
        event.buttons === MOUSE_BUTTONS.LEFT &&
        lassoKeyDownRef.current
      ) {
        // To be lassoing we need to have the left button down and one of the lasso keyboard modifier keys down.
        onLassoDraw();
      } else {
        selectLesionWithHover(event);
      }
    }
  };

  const onMouseUp = (event: React.MouseEvent) => {
    if (lassoKeyDownRef.current && lassoPointsRef.current.length) {
      selectLesionsWithLasso(event.nativeEvent);
    } else {
      unselectLesions();
    }
    //measurement tools mouseUp
    const scale = getMeasurementRendererScale();

    if (isMeasurementMode) {
      let resetState = null;
      if (isRulerActiveRef.current) {
        resetState = onMouseUpRuler({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          start: measurementToolStartPoint,
          end: getMousePosForMeasurementTool(event),
          scale: scale,
          callbackMouseDownStraightLine: onMouseDownStraightLine,
          pixelsPerMillimeter: getMillimeterPerPixel(),
        });
      }
      if (isEllipseActiveRef.current) {
        resetState = onMouseUpEllipse({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          scale: scale,
          callbackMouseDownEllipseNode: onMouseDownEllipseNode,
          hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
          huData,
          pixelsPerMillimeter: getMillimeterPerPixel(),
        });
      }

      resetStateMeasurementTool(resetState);
    }
    //end measurement tools mouseUp
  };

  const onZoom = () => {
    onZoomCTVolume();
    // Set the measurement tools to the correct position for the current zoom.
    if (
      msmToolsContainerRef.current &&
      msmToolsContainerRef.current?.children.length > 0
    ) {
      const scale = getMeasurementRendererScale();
      onZoomMeasurementTools(
        MEASUREMENT_SETTINGS.PARENT.NonContrast,
        msmToolsContainerRef.current?.children,
        scale,
        onMouseDownEllipseNode,
        () => getMillimeterPerPixel(),
        hitAreaEllipseToolRef.current,
        huData
      );
    }
  };

  const getMeasurementRendererScale = () => {
    // We use `y` here, but it could've just as easily have been `x`. We just need a scalar.
    return containerRef.current?.scale.y ?? 1;
  };

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      //delete current measurement tool active
      if (
        MEASUREMENT_SETTINGS.DELETE_KEYS.includes(event.code) &&
        measurementTargetRef.current
      ) {
        measurementTargetRef.current.destroy({
          children: true,
        });
        measurementTargetRef.current = null;
        isDraggingMeasurementToolRef.current = undefined;
      }
      keysPressed[event.code] = true;

      if (KEYBOARD_BUTTONS.LASSO.includes(event.code)) {
        // NOTE: the shift key is also used with control + tab to switch browser tabs.
        // Given this, we record previous keypresses and check if either control keys have been pressed
        // If so, we can assume the shift key has been used in a hotkey combo, and ignore the Lasso tool trigger
        if (keysPressed['ControlLeft'] || keysPressed['ControlRight']) {
          return;
        }

        // NOTE: The 'spacebar' key will auto repeat and we only want to clear the lesions on the first "keyDown' event.
        // This detects if the event is a repeat and ignore it if so.
        if (!event.repeat) {
          unselectLesions();
          setDisableControls(true);
          lassoKeyDownRef.current = true;
        }
      } else if (KEYBOARD_BUTTONS.WINDOWING.includes(event.code)) {
        // Check if the user is focused on an input field, if so do not set window levels with PRESETS
        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement ||
          event.target instanceof HTMLSelectElement
        ) {
          return;
        }
        setNonContrastWindowLevels(PRESET_MAP[event.code].window);
        _onWindowLevelsChange &&
          _onWindowLevelsChange(PRESET_MAP[event.code].window);
        event.preventDefault();
      }
    },
    [_onWindowLevelsChange, setDisableControls, setNonContrastWindowLevels]
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (KEYBOARD_BUTTONS.LASSO.includes(event.code)) {
        selectLesionsWithLasso(event);
        setDisableControls(false);
        lassoKeyDownRef.current = false;
      }

      // This clears out the record of keys pressed
      delete keysPressed[event.code];
    },
    [setDisableControls, selectLesionsWithLasso]
  );

  // We should only listen to the keyboard if the user is on the CT Volume tab and viewing the non-contrast viewer (NOTE: This stays mounted even if viewing the contrast views).
  const listenToKeyboard =
    visibleTab === NAV_TABS.ctVolumeTab && isNonContrastSeries(seriesId);
  useEffect(() => {
    // Update the keyboard listeners.
    if (listenToKeyboard) {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      };
    }
    // If we aren't listening to the keyboard we shouldn't have any lesions selected, the lasso key down can be cleared, and controls should be re-enabled.
    if (!listenToKeyboard) {
      unselectLesions();
      lassoKeyDownRef.current = false;
      setDisableControls(false);
    }
    return () => {};
  }, [onKeyDown, onKeyUp, listenToKeyboard]);

  const onWindowLevelsChange = useCallback(
    (x) => {
      setNonContrastWindowLevels(x);
      _onWindowLevelsChange && _onWindowLevelsChange(x);
    },
    [_onWindowLevelsChange]
  );

  return (
    <div className="ctVolumeNonContrastViewer">
      {patientID && runID && (
        <>
          <DropZone viewIndex={NON_CONTRAST_DROP_ZONE} />
          <NonContrastViewHeader
            seriesDescription={label}
            nonContrastViewOverlay={nonContrastViewOverlay}
            setNonContrastViewOverlay={setNonContrastViewOverlay}
            screenshotRef={screenshotRef}
            onTakeScreenshot={onTakeScreenshot}
            onCloseScreenshot={onCloseScreenshot}
          />
          <div
            className="ctVolumeNonContrastViewer_inner_view"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
          >
            <WebGLViewer
              loadingCTvolume={loading}
              reverseSlider={false}
              viewType={KEY_CT_NON_CONTRAST}
              showSlider={true}
              slice={slice}
              onSliceChange={onSliceChange}
              windowLevels={nonContrastWindowLevels}
              onReady={init}
              onCleanup={cleanup}
              onHueChange={onHueChange}
              onZoom={onZoom}
              onDrag={onDrag}
              onWindowLevelsChange={onWindowLevelsChange}
              loadingInfo={
                processingLesionData ? 'Processing lesion data' : undefined
              }
              disableControls={disableControls}
              sliderMarkers={lesionMarkers}
              triggerResetPanAndZoom={triggerResetPanAndZoom}
              shapeData={viewerData?.shape}
              onUpdateHuData={(huData) => setHuData(huData)}
              imageBufferData={viewerData?.imageBufferData}
            />
          </div>
        </>
      )}
    </div>
  );
};

CTVolumeNonContrastViewer.defaultProps = {
  updatedLesions: [],
};
