import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { getType } from 'typesafe-actions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { StudyActions } from '../reducers/study';
import { currentStudySelector } from '../selectors/study';
import { useAbortController } from '../utils/use-abort-controller';
import VersionHeadUpdater from '../utils/versionUpdater';
import {
  ContrastLesionData,
  defaultImageLoadingThrottle,
  DraftReport,
  EditMode,
  EditModeAction,
  EditModeActions,
  LesionDataResponse,
  ListReview,
  PatientStats,
  Report,
  ReportHistoryResponse,
  StoreContextValue,
  StudyData,
  VolumeSpacing,
} from './types';

const StoreContext = React.createContext<StoreContextValue | undefined>(
  undefined
);
StoreContext.displayName = 'StoreContext';

interface Props {
  children: ReactNode;
}

function editModeReducer(
  state: EditMode = { editing: false },
  action: EditModeAction
): EditMode {
  switch (action.type) {
    case getType(EditModeActions.startEditing):
      if (!state.editing) {
        return {
          editing: true,
          showAnnos: action.payload.showAnnos,
          showAuxAnnos: action.payload.showAuxAnnos,
        };
      }
      break;

    case getType(EditModeActions.modifiedCenterline):
      if (state.editing && !state.modified) {
        return {
          ...state,
          modified: true,
        };
      }
      break;

    case getType(EditModeActions.updateCenterline):
      if (state.editing) {
        return {
          ...state,
          modified: true,
          centerline: action.payload,
        };
      }
      break;

    case getType(EditModeActions.stopEditing):
      if (state.editing) {
        return {
          editing: false,
          // We need to remember these values after we finish editing so we can restore them in the store via a hook.
          showAnnos: state.showAnnos,
          showAuxAnnos: state.showAuxAnnos,
        };
      }
      break;
  }
  return state;
}

export function StoreProvider({ children }: Props): ReactElement<Props> {
  const [patientID, setPatientID] = useState<string | undefined>();
  const [postingReport, setPostingReport] = useState(false);
  const [postingPartialReport, setPostingPartialReport] = useState(false);
  const [runID, setRunID] = useState<string | undefined>();
  const [versionHead, setVersionHead] = useState<string | undefined>();
  const [backendVersionHead, setBackendVersonHead] = useState<
    string | undefined
  >();
  const [status, setStatus] = useState<any | undefined>();
  const [patientDataReloadCount, setPatientDataReloadCount] = useState(0);
  const [currentReport, setCurrentReport] = useState<Report | undefined>();
  const [fetchingReport, setFetchingReport] = useState<boolean>(false);
  const [
    fetchingOverallMeasurements,
    setFetchingOverallMeasurements,
  ] = useState<boolean>(false);

  const [selectedStudyClient, setSelectedStudyClient] = useState<
    any | undefined
  >();
  const [draftReport, setDraftReport] = useState<DraftReport | undefined>();
  const [editingReport, setEditingReport] = useState(false);
  const [editingImpressions, setEditingImpressions] = useState(false);
  const [editingCoronaryFindings, setEditingCoronaryFindings] = useState(false);

  const [reportHistory, setReportHistory] = useState<
    ReportHistoryResponse | undefined
  >();
  const [reviewList, setReviewList] = useState<ListReview[]>();

  const clearDraftReport = useCallback(() => {
    setDraftReport(undefined);
  }, [setDraftReport]);

  const updateDraftReport = useCallback(
    (report: DraftReport) => {
      setDraftReport({ ...draftReport, ...report });
    },
    [draftReport, setDraftReport]
  );

  const [
    warnUserOfChangeInVesselGroup,
    setWarnUserOfChangeInVesselGroup,
  ] = useState(false);

  const [dashboardData, setDashboardData] = useState<any | undefined>();
  const [displayMeasurements, setDisplayMeasurements] = useState<boolean>(
    false
  );
  const [newDashboardData, setNewDashboardData] = useState(false);
  const [reloadDashboardData, setReloadDashboardData] = useState(false);
  const [decryptDashboardData, setDecryptDashboardData] = useState<
    any | undefined
  >();
  const [decryptedStudies, setDecryptedStudies] = useState<string[]>([]);
  const [studyData, setStudyData] = useState<StudyData | undefined>();
  const [calciumScoreData, setCalciumScoreData] = useState<any | undefined>();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const [lesionData, _setLesionData] = useState<
    LesionDataResponse | undefined
  >();
  const setLesionData = (
    data: LesionDataResponse | undefined,
    patientID: string,
    runID: string
  ) => {
    // Check we are still showing this study before setting the lesionData; the data is fetched
    // asynchronously so could potentially arrive after the user has left the study and opened another.
    // The suspicion is this was causing the rare AP-950 bug.
    if (
      patientID === patientAndRunIDs.current.patientID &&
      runID === patientAndRunIDs.current.runID
    ) {
      _setLesionData(data);
    }
  };
  const [contrastLesionData, setContrastLesionData] = useState<
    { [key: string]: { [key: string]: ContrastLesionData } } | undefined
  >();

  const [patientStats, setPatientStats] = useState<PatientStats | undefined>();

  const [stenosis, setStenosis] = useState<any | undefined>();

  const [editMode, dispatchEditModeAction] = useReducer(editModeReducer, {
    editing: false,
  });
  const [showCTVolume, setShowCTVolume] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [studyLocked, setStudyLocked] = useState(false);
  const [studyLockedBy, setStudyLockedBy] = useState<string | undefined>();
  const [inactivity, setInactivity] = useState(false);
  const [lastStudyId, setLastStudyId] = useState<string | undefined>();
  const [visibleTab, setVisibleTab] = useState(0);
  const [imageLoadingThrottle, setImageLoadingThrottle] = useState<number>(
    defaultImageLoadingThrottle
  );
  const [nonContrastSlice, setNonContrastSlice] = useState<number>(0);
  const [nonContrastSpacing, setNonContrastSpacing] = useState<
    VolumeSpacing | undefined
  >();

  // Base64 encoded screen grab of 3D Model
  const [modelImage, setModelImage] = useState<any | null>(null);

  const versionHeadUpdater = useRef<VersionHeadUpdater>(
    new VersionHeadUpdater()
  );

  // Since these values are baked into functions on creation we need a way to access the currently active values.
  interface PatientAndRunIDs {
    patientID: string | undefined;
    runID: string | undefined;
  }
  const patientAndRunIDs = useRef<PatientAndRunIDs>({
    patientID: undefined,
    runID: undefined,
  });

  // set defaults on study entry
  useEffect(() => {
    // Update the currently active patient and run IDs (including if the patient and run IDs are now undefined).
    patientAndRunIDs.current = { patientID, runID };
    if (!patientID || !runID) return;
    versionHeadUpdater.current.reset();
  }, [patientID, runID]);

  // reusing this function due to the ubiquity of how it's used currently
  const updateVersionHead = useCallback((versionNumber?: string) => {
    if (!versionNumber) return;
    versionHeadUpdater.current.updateVersionCountMap(versionNumber);
    setVersionHead(versionNumber);
  }, []);

  const signal = useAbortController();

  const updateBackendVersionHead = useCallback(
    async (versionNumber: string, force: boolean = false): Promise<void> => {
      if (force) {
        setBackendVersonHead(versionNumber);
        return;
      }
      versionHeadUpdater.current
        .updateBackendVersionHead(versionNumber, patientID, runID)
        .then((response) => {
          if (!signal().aborted && response) {
            setBackendVersonHead(response);
          }
        })
        .catch((error) =>
          console.warn(
            'An error occurred while updating the version head',
            error
          )
        );
    },
    [patientID, runID, signal]
  );

  const dispatch = useAppDispatch();

  const clearPatient = () => {
    setPatientID(undefined);
    setRunID(undefined);
    setCurrentReport(undefined);
    setReportHistory(undefined);
    setReviewList(undefined);
    setStatus(undefined);
    setPatientDataReloadCount(0);
    setCalciumScoreData(undefined);
    _setLesionData(undefined);
    setContrastLesionData(undefined);
    setInitialDataLoaded(false);
    dispatchEditModeAction(EditModeActions.stopEditing());
    setShowCTVolume(false);
    setVisibleTab(0);
    dispatch(StudyActions.clearSelection());
    setStenosis(undefined);
    setNonContrastSlice(0);
  };

  /**
   * @param nSlices Normally leaving this as the default 0 is fine because we can get the number from the vesselData
   *        but if we just added the vessel this won't be up to date yet and the value needs to be passed in.
   */
  useEffect(() => {
    if (!studyData) return;

    setPatientStats({
      cad_rads: studyData.cad_rads_str,
      calcium_score: studyData.calcium_score,
      maximum_stenosis: studyData.stenosis_max,
      priority_vessel: studyData.priority_vessel_id,
      sis: studyData.sis,
    });
  }, [studyData]);

  // Use patientID to extract runID from dashboard data
  useEffect(() => {
    if (!patientID || !dashboardData) return;
    const patient = dashboardData[patientID] || {};

    setRunID(patient.active_run || 'na');
    setStatus(patient.status);
  }, [patientID, dashboardData, setRunID, setStatus]); // eslint-disable-line

  const selectedStudy = useAppSelector(currentStudySelector);

  // Use patientID to extract runID from dashboard data
  useEffect(() => {
    if (!selectedStudy) return;
    setRunID(selectedStudy.active_run);
    setStatus(selectedStudy.status);
  }, [selectedStudy, setRunID, setStatus]);

  const storeContext: StoreContextValue = {
    backendVersionHead,
    calciumScoreData,
    clearDraftReport,
    clearPatient,
    currentReport,
    dashboardData,
    draftReport,
    displayMeasurements,
    editMode,
    editingCoronaryFindings,
    editingImpressions,
    editingReport,
    studyLocked,
    studyLockedBy,
    inactivity,
    initialDataLoaded,
    setInitialDataLoaded,
    lastStudyId,
    lesionData,
    contrastLesionData,
    modelImage,
    newDashboardData,
    studyData,
    patientDataReloadCount,
    patientID,
    patientStats,
    postingPartialReport,
    postingReport,
    reloadDashboardData,
    decryptDashboardData,
    decryptedStudies,
    reportHistory,
    reviewList,
    runID,
    selectedStudyClient,
    setCalciumScoreData,
    setCurrentReport,
    setDashboardData,
    setDisplayMeasurements,
    dispatchEditModeAction,
    setEditingCoronaryFindings,
    setEditingImpressions,
    setEditingReport,
    setStudyLocked,
    setStudyLockedBy,
    setInactivity,
    setLastStudyId,
    setLesionData,
    setContrastLesionData,
    setModelImage,
    setNewDashboardData,
    setPatientID,
    setPostingPartialReport,
    setPostingReport,
    setReloadDashboardData,
    setDecryptDashboardData,
    setDecryptedStudies,
    setReportHistory,
    setReviewList,
    setRunID,
    setSelectedStudyClient,
    setShowCTVolume,
    setShowReport,
    setStenosis,
    setStudyData,
    setVisibleTab,
    setWarnUserOfChangeInVesselGroup,
    showCTVolume,
    showReport,
    status,
    stenosis,
    updateBackendVersionHead,
    updateDraftReport,
    updateVersionHead,
    versionHead,
    visibleTab,
    warnUserOfChangeInVesselGroup,
    fetchingReport,
    setFetchingReport,
    fetchingOverallMeasurements,
    setFetchingOverallMeasurements,
    imageLoadingThrottle,
    setImageLoadingThrottle,
    setPatientStats,
    nonContrastSlice,
    setNonContrastSlice,
    nonContrastSpacing,
    setNonContrastSpacing,
  };
  return (
    <StoreContext.Provider value={storeContext}>
      {children}
    </StoreContext.Provider>
  );
}
/**
 * Hook to access the StoreContext
 * @returns {StoreContextValue} StoreContextValue
 */
export function useStoreContext(): StoreContextValue {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStoreContext must be used within a StoreProvider.');
  }
  return context;
}
