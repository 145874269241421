import TWEEN, { Tween } from '@tweenjs/tween.js';
import React, { Component } from 'react';
import { DoubleSide, WebGLRenderer } from 'three';
import { Loader } from '../../../components/Loader/Loader';
import { showToast } from '../../../components/Toast/showToast';
import {
  COLOR_MODEL_AORTA,
  COLOR_MODEL_BACK_LIGHT_DIRECTIONAL,
  COLOR_MODEL_DEFAULT,
  COLOR_MODEL_LIGHT_AMBIENT,
  COLOR_MODEL_LIGHT_DIRECTIONAL,
  COLOR_MODEL_LIGHT_SPECULAR,
  COLOR_MODEL_PLAQUE,
  COLOR_MODEL_SLICER,
  MODEL_BACK_LIGHT_INTENSITY_DIRECTIONAL,
  MODEL_CAMERA_MAX_DISTANCE,
  MODEL_CAMERA_MAX_ZOOM,
  MODEL_CAMERA_MIN_DISTANCE,
  MODEL_CAMERA_MIN_ZOOM,
  MODEL_DEBUG_MODE,
  MODEL_ENABLE_SCROLL_ZOOM,
  MODEL_FULLSCREEN_DEMO,
  MODEL_LIGHT_INTENSITY_AMBIENT,
  MODEL_LIGHT_INTENSITY_DIRECTIONAL,
  MODEL_LIGHT_SPECULAR_SHININESS,
  MODEL_RENDER_BACK_LIGHT,
  MODEL_RENDER_FOG,
  MODEL_RENDER_ROTATION_ANIMATION,
  MODEL_RENDER_SHADOWS,
  MODEL_RENDER_SPECULAR,
  MODEL_TIME_POSITION_TWEEN,
  MODEL_TIME_TARGET_TWEEN,
  MODEL_USE_LIGHTING_FIX,
  MODEL_USE_LOGARITHMIC_DEPTH_BUFFER,
  STENOSIS_COLOR_MAP,
  THEME,
  VESSEL_STENOSIS_COLOR,
} from '../../../config';
import {
  ContrastLesionData,
  ContrastLesionDataResponse,
} from '../../../context/types';
import {
  Centerline,
  Model3dData,
  MPRAxes,
  Vertex2Slice,
  VesselDataResponse,
} from '../../../reducers/vessel-data';
import * as api from '../../../utils/api';
import { captureMouse } from '../../../utils/captureMouse';
import { MeshLine, MeshLineMaterial } from './THREE.Meshline';
import { getCameraPosition } from './utils';

var THREE = (window.THREE = require('three'));
require('./ModelControls');
require('three/examples/js/modifiers/SimplifyModifier');

// Everything we render should have this rotation applied to it because the groupMeshes have this rotation applied to it.
const groupRotation = (3 * Math.PI) / 2;
// The camera's vertical Field Of View.
const fovH = 50;

/**
 * If we access any points in the mesh directly they won't have groupRotation applied ... so apply it here.
 */
const localToWorld = (pos: THREE.Vector3) => {
  const y = pos.y;
  const z = pos.z;
  pos.y = y * Math.cos(groupRotation) - Math.sin(groupRotation) * z;
  pos.z = y * Math.sin(groupRotation) + Math.cos(groupRotation) * z;
};

// TODO should be in a utils library
// Creates a material using the default color consistently
function getDefaultMaterial() {
  if (MODEL_RENDER_SPECULAR) {
    return new THREE.MeshPhongMaterial({
      color: COLOR_MODEL_DEFAULT,
      side: THREE.DoubleSide, // See inside and outside of vessels
      specular: COLOR_MODEL_LIGHT_SPECULAR,
      shininess: MODEL_LIGHT_SPECULAR_SHININESS,
    });
  } else {
    return new THREE.MeshLambertMaterial({
      color: COLOR_MODEL_DEFAULT,
      side: THREE.DoubleSide, // See inside and outside of vessels
    });
  }
}

// TODO should be in a utils library
// Creates a material using the plaque color consistently
function getDefaultPlaqueMaterial() {
  return new THREE.MeshToonMaterial({
    color: COLOR_MODEL_PLAQUE,
    side: THREE.DoubleSide, // See inside and outside of plaque
  });
}

// The number of positions a VP marker could have in the positioning circle about the vessel.
const VP_CIRCLE_POINTS = 32;
// The distance from the center of the vessel at which the VP marker positions are set.
const VP_CIRCLE_RADIUS = 3.0;
// The radius +/- out from the target marker position that we will test for collisions to decide if it is valid.
const VP_COLLISION_RADIUS = 1.0;

type MarkerPoints = {
  sliceIdx: number;
  point: [number, number, number];
  vpBioMarkers: string[];
}[];

type Model3dState = {
  queryVessel: string | null;
  querySlice: any | null;
  queryStenosis: any | null;
  queryPlaqueInfo: any | null;
  loaded: boolean;
  oldY: number;
};

type Model3dProps = {
  patientID: any;
  runID: any;
  versionHead: string | undefined;
  user: any;
  vessels: string[];
  showPlaque: boolean;
  vesselData: VesselDataResponse | undefined;
  showVP: boolean;
  stenosis: any;
  vesselID: any;
  priorityVesselName: any;
  modelRef: any;
  sliceidx: number;
  resetCamera: any;
  showReport: any;
  openTab: any;
  plaqueOpacity: number;
  contrastLesionData: ContrastLesionDataResponse | undefined;
  setModelLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setModelCameraLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setHoverSliceIdx: React.Dispatch<React.SetStateAction<null>>;
  setHoverVessel: React.Dispatch<React.SetStateAction<null>>;
  setShowHoverData: (toggle: boolean) => void;
  setSelectedVesselName: (
    selectedVesselName: string | undefined,
    newHighSliceIndex?: number,
    newMidSliceIndex?: number,
    newLowSliceIndex?: number,
    nSlices?: number
  ) => void;
  savingSelectedVessel?: boolean;
  model3dData?: Model3dData;
  // This is used in the fullscreen demo animation.
  setVPAndPlaqueVisibility?: (show: boolean) => void;
};

class Model3d extends Component<Model3dProps, Model3dState> {
  // Is this component still mounted?
  mounted: boolean = true;
  // Has the 3D scene been initialized at least once?
  initialized: boolean = false;
  container: HTMLElement | null = null;
  width: number = 0;
  height: number = 0;
  renderer: THREE.WebGLRenderer | undefined;
  mount: HTMLDivElement | null;
  clock: any;
  scene: any;
  lightTarget: THREE.Object3D = new THREE.Object3D();
  groupMeshes: any;
  groupVulnerablePlaques: any;
  groupPlaques: any;
  groupInteract: any;
  camera: any;
  controls: any;
  dirLight: THREE.DirectionalLight | undefined;
  // The background light (to fill a bit of light into the shadows from the left side of the screen).
  backLight: THREE.DirectionalLight | undefined;
  fullScreenMediaQueryList: MediaQueryList | undefined;
  moved: boolean | undefined;
  clicking: boolean | undefined;
  vesselDirections: any[] = [];
  vp_data_points: any[] = [];
  cameraLoadedTimeout: number | undefined;
  baseTween: Tween<any> | undefined;
  resetTween: Tween<any> | undefined;
  panTween: Tween<any> | undefined;
  zoomTween: Tween<any> | undefined;
  // The bounding sphere that encloses the whole 3d model. This needs to be updated if the 3D model is changed.
  boundingSphere: THREE.Sphere | undefined;

  constructor(props: Model3dProps) {
    super(props);

    // Bind member functions explictly in constructor
    this.update = this.update.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.pickHover = this.pickHover.bind(this);
    this.pickClick = this.pickClick.bind(this);
    this.mouseDownListener = this.mouseDownListener.bind(this);
    this.mouseUpListener = this.mouseUpListener.bind(this);
    this.loadAorta = this.loadAorta.bind(this);
    this.loadAortaAndVessels = this.loadAortaAndVessels.bind(this);
    this.onMainGeometryLoad = this.onMainGeometryLoad.bind(this);
    this.onPlaqueGeometryLoad = this.onPlaqueGeometryLoad.bind(this);
    this.mount = null;

    // Default display is stenosis and readout should be renrendered with
    // any change
    this.state = {
      queryVessel: null,
      querySlice: null,
      queryStenosis: null,
      queryPlaqueInfo: null,
      loaded: false,
      oldY: 0,
    };
  }

  initRenderer() {
    // Get reference to DOM node AFTER mounting
    this.container = document.getElementById('scene-container');
    if (this.container !== null) {
      this.width = this.container.clientWidth;
      this.height = this.container.clientHeight;
    }

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true,
      logarithmicDepthBuffer: MODEL_USE_LOGARITHMIC_DEPTH_BUFFER,
      // precision: "mediump", // "highp" is the default precision and probably the best option.
      powerPreference: MODEL_USE_LIGHTING_FIX ? 'high-performance' : undefined, // or "low-power" or "default".
    });

    if (this.renderer == null || this.mount == null) {
      console.error('Failed to intialize 3d Model renderer.');
      return;
    }

    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x000000, 0); // the default
    this.renderer.setPixelRatio(window.devicePixelRatio);
    // Enable the shadow map (an extra rendering pass generates the shadows from the point of view of the light source)?
    if (MODEL_RENDER_SHADOWS) {
      this.renderer.shadowMap.enabled = true;
      // Set the shadow map type: THREE.BasicShadowMap, THREE.PCFShadowMap, THREE.PCFSoftShadowMap, THREE.VSMShadowMap;
      this.renderer.shadowMap.type = THREE.VSMShadowMap;
    }

    this.mount.appendChild(this.renderer.domElement);
  }

  initScene() {
    // Create a clock to use for animation and sync
    this.clock = new THREE.Clock();
    this.clock.start();

    // Create a scene object
    this.scene = new THREE.Scene();

    // And some containers for organisation - all defaultly off - we turn
    // them on once constructed

    // Holds default meshes
    this.groupMeshes = new THREE.Group();
    this.groupMeshes.name = 'groupMeshes';
    this.groupMeshes.rotation.x = groupRotation;
    this.groupMeshes.visible = false;
    this.scene.add(this.groupMeshes);

    // Holds all plaques
    this.groupPlaques = new THREE.Group();
    this.groupPlaques.name = 'groupPlaques';
    this.groupPlaques.rotation.x = groupRotation;
    this.groupPlaques.visible = this.props.showPlaque;
    this.scene.add(this.groupPlaques);

    // Holds all vuleranble plaque
    this.groupVulnerablePlaques = new THREE.Group();
    this.groupVulnerablePlaques.name = 'groupVulnerablePlaques';
    this.groupVulnerablePlaques.rotation.x = groupRotation;
    this.groupVulnerablePlaques.visible = this.props.showVP;
    this.scene.add(this.groupVulnerablePlaques);

    // The 3D volume has a slicer as well, we'll put this in an interactable
    // group with other fun objects
    this.groupInteract = new THREE.Group();
    this.groupInteract.name = 'groupInteract';
    this.groupInteract.rotation.x = groupRotation;
    this.groupInteract.visible = false;
    this.scene.add(this.groupInteract);

    // Apply fog (fade to black) as a depth cue?
    if (MODEL_RENDER_FOG) {
      this.scene.fog = new THREE.Fog('#000000', 150, 300);
    }

    // The first interactible is a plane which slices the 3D model to show the
    // user where the current slice info is coming from
    var slicerGeo = new THREE.BufferGeometry();
    let slicerExtent = 3.2;

    var vertexPositions = [
      [+slicerExtent, +slicerExtent, 0],
      [+slicerExtent, -slicerExtent, 0],
      [-slicerExtent, -slicerExtent, 0],
      [-slicerExtent, +slicerExtent, 0],
      [+slicerExtent, +slicerExtent, 0],
    ];

    var vertices = new Float32Array(vertexPositions.length * 3); // three components per vertex
    // components of the position vector for each vertex are stored
    // contiguously in the buffer.
    for (var i = 0; i < vertexPositions.length; i++) {
      vertices[i * 3 + 0] = vertexPositions[i][0];
      vertices[i * 3 + 1] = vertexPositions[i][1];
      vertices[i * 3 + 2] = vertexPositions[i][2];
    }

    // itemSize = 3 because there are 3 values (components) per vertex
    slicerGeo.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

    let material = new MeshLineMaterial({
      color: COLOR_MODEL_SLICER,
      lineWidth: 0.3,
    });

    // TODO once this issue https://github.com/spite/THREE.MeshLine/issues/133
    // is fixed we should remove the THREE.Meshline file and switch back to the repo
    let line = new MeshLine();
    line.setGeometry(slicerGeo);
    let slicer = new THREE.Mesh(line, material);
    slicer.name = 'slicer';
    this.groupInteract.add(slicer);

    // Ambient light to give light to the whole scene
    const ambLight = new THREE.AmbientLight(
      COLOR_MODEL_LIGHT_AMBIENT,
      MODEL_LIGHT_INTENSITY_AMBIENT
    );
    this.scene.add(ambLight);

    // Camera! (perspective camera translated to incude view of entire coronary tree)
    this.camera = new THREE.PerspectiveCamera(
      fovH, // FOV - vertical
      this.width / this.height, // Aspect ratio
      0.01, // zNear
      1000 // zFar
    );

    // Add a directional light and set its position to that of the camera and its target to the lightTarget position.
    // Note we DON'T want to use the groupMesh because it's position is not the center of rotation. We will set the lightTarget's
    // desired position after the full mesh has loaded.
    this.dirLight = new THREE.DirectionalLight(
      COLOR_MODEL_LIGHT_DIRECTIONAL,
      MODEL_LIGHT_INTENSITY_DIRECTIONAL
    );
    if (this.dirLight) {
      if (MODEL_USE_LIGHTING_FIX) {
        this.scene.add(this.lightTarget);
        this.dirLight.target = this.lightTarget;
      } else {
        this.dirLight.target = this.groupMeshes;
      }
      this.dirLight.castShadow = false;
      this.scene.add(this.dirLight);

      // Set up shadow properties for the light?
      if (MODEL_RENDER_SHADOWS) {
        this.dirLight.castShadow = true;
        this.dirLight.shadow.mapSize.width = 1024; // The width of the texture the shadow map is rendered to.
        this.dirLight.shadow.mapSize.height = 1024; // The height of the texture the shadow map is rendered to.
        this.dirLight.shadow.camera.near = 200; // The near clipping plane for the shadow camera (make this as close as possible to the start of 3D model).
        this.dirLight.shadow.camera.far = 800; // The far clipping plane for the shadow camera (make this as close as possible after the end of the 3D model).
        this.dirLight.shadow.camera.zoom = 0.06; // Controls the width and height of the area that can be shadowed. (1 default, < 1 is wider, > 1 is narrower)
        this.dirLight.shadow.bias = -0.005; // Reduce self shadow by appling a small negative bias.
      }
    }

    // Create the additional un-shadowed back light source?
    if (MODEL_RENDER_BACK_LIGHT && MODEL_RENDER_SHADOWS) {
      this.backLight = new THREE.DirectionalLight(
        COLOR_MODEL_BACK_LIGHT_DIRECTIONAL,
        MODEL_BACK_LIGHT_INTENSITY_DIRECTIONAL
      );
      if (this.backLight) {
        if (MODEL_USE_LIGHTING_FIX) {
          this.backLight.target = this.lightTarget;
        } else {
          this.backLight.target = this.groupMeshes;
        }
        this.backLight.castShadow = false;
        this.scene.add(this.backLight);
      }
    }

    // Set the initial light(s) position(s).
    this.updateLight();

    // For debugging, note this will disable ray trace picking functionality
    // x = red, y = green, z = blue
    if (MODEL_DEBUG_MODE) {
      this.scene.add(new THREE.AxesHelper(1024));
    }

    // Set up listeners on the canvas element.
    const canvas = this.renderer?.getContext().canvas;
    if (canvas != null) {
      // Set up listeners for mouse events
      canvas.addEventListener('pointermove', this.pickHover);
      canvas.addEventListener('pointerdown', this.mouseDownListener);
      canvas.addEventListener('pointerup', this.mouseUpListener);
      // WebGL (and thus THREE) losing the context means everything will be broken until it is restored, and then everything needs to be re-created to actually work.
      canvas.addEventListener('webglcontextlost', this.contextLostListener);
      canvas.addEventListener(
        'webglcontextrestored',
        this.contextRestoredListener
      );
    }

    window.addEventListener('resize', this.onWindowResize);

    this.fullScreenMediaQueryList = window.matchMedia(
      '(display-mode: fullscreen)'
    );
    this.fullScreenMediaQueryList.addListener(this.onWindowResize);
  }

  initControls() {
    if (this.renderer == null) {
      console.error('3dModel initControls failed due to null renderer.');
      return;
    }

    // Action! (orbit controls with some dampening for smoother movement)
    this.controls = new THREE.ModelControls(
      this.camera,
      this.renderer.domElement
    );
    this.controls.enableKeys = true;
    this.controls.maxDistance = MODEL_CAMERA_MAX_DISTANCE;
    this.controls.minDistance = MODEL_CAMERA_MIN_DISTANCE;
    this.controls.enableZoom = MODEL_ENABLE_SCROLL_ZOOM;

    // When the orbital controls change update the directional light position
    this.controls.addEventListener('change', this.updateLight);
  }

  // Update the light when the orbital controls change so the light
  // is "attached" to the camera
  updateLight = () => {
    if (this.dirLight && this.camera) {
      // If rendering shadows position the light source so that it isn't sitting on the camera (as the shadows would all be hidden then).
      if (MODEL_RENDER_SHADOWS) {
        // Get the camera direction.
        const direction = new THREE.Vector3(0, 0, 0);
        this.camera.getWorldDirection(direction);
        // Get the camera 'up' vector (NOTE this isn't affected by camera rotation so it isn't the 'up' we ultimately want).
        const up = this.camera.up.clone();
        // Calculate the unit vector pointing right from the camera.
        const right = new THREE.Vector3(0, 0, 0);
        right.crossVectors(direction, up);
        right.normalize();
        // Calculate the true 'up' unit vector for the camera.
        up.crossVectors(right, direction);
        up.normalize();
        // Set the light position.
        const lightUp = 200;
        const lightRight = 100;
        this.dirLight.position.set(
          this.camera.position.x + lightUp * up.x + lightRight * right.x,
          this.camera.position.y + lightUp * up.y + lightRight * right.y,
          this.camera.position.z + lightUp * up.z + lightRight * right.z
        );

        // Set the back light position.
        if (this.backLight) {
          const backLightRight = -400;
          const backLightAway = 100;
          this.backLight.position.set(
            this.lightTarget.position.x +
              backLightRight * right.x +
              backLightAway * direction.x,
            this.lightTarget.position.y +
              backLightRight * right.y +
              backLightAway * direction.y,
            this.lightTarget.position.z +
              backLightRight * right.z +
              backLightAway * direction.z
          );
        }
      } else {
        this.dirLight.position.set(
          this.camera.position.x,
          this.camera.position.y,
          this.camera.position.z
        );
      }
    }
  };

  // AP-249: Removed createDebugLightGUI
  // TODO:   Confirm if this is required 'dat-gui' package is deprecated
  //         Suggested to move to 'dat.gui'. However, this has some high vulnerabilities

  getUserGroup() {
    const { user } = this.props;
    if (user.groups && user.groups.length > 0) {
      return user.groups[0];
    } else {
      return '';
    }
  }

  // Helper to reduce code duplication for AWS or local loading for geometry
  // TODO
  // allow for variable auxiliary callback args
  loadAorta(loader: any): Promise<any> {
    return loader
      .loadAsync(`/data/${this.props.patientID}/${this.props.runID}/aorta`)
      .then((geometry: any) => {
        return this.onMainGeometryLoad({ geometry, label: 'AORTA' });
      });
  }

  /**
   * Load and add a single vessel to the 3d model.
   */
  loadVessel(loader: any, vesselID: string): Promise<any> {
    // We should already has the v2s mapping and cl_mm (ie centerline) in the vessel data.
    const vesselData = this.props.vesselData
      ? this.props.vesselData[vesselID]
      : undefined;
    let centerline: Centerline | undefined = vesselData?.centerline;
    let v2s: Vertex2Slice | undefined = vesselData?.vertex_to_slice_mapping;

    return Promise.allSettled([
      // Load the vessel geometry and axes at the same time, then add the geometry to the 3d model.
      Promise.all([
        loader.loadAsync(
          `/data/${this.props.patientID}/${this.props.runID}/vessel/${vesselID}/geometry`
        ),
        api.getJSON(
          `data/${this.props.patientID}/${this.props.runID}/vessel/${vesselID}/mpr/axes/all`
        ),
      ]).then(([geometry, axes]: [any, any]) => {
        // If all the required data loaded then add the vessel to the 3d model.
        return this.onMainGeometryLoad({
          geometry,
          label: vesselID,
          cl_mm: centerline,
          v2s,
          axes,
        });
      }),
      // At the same time the geometry for the 3d model is loading we can be loading the vulnerable plaque for the vessel.
      this.loadVulnerablePlaqueForVessel(vesselID),
      this.loadPlaqueForVessel(loader, vesselID),
    ]);
  }

  async loadAortaAndVessels(loader: any) {
    // Add the aorta.
    const promises: Promise<any>[] = [this.loadAorta(loader)];
    // Add the vessels.
    this.props.vessels.forEach(async (vesselID: any) => {
      promises.push(this.loadVessel(loader, vesselID));
    });
    // Wait for everything to finish.
    await Promise.allSettled(promises);

    // Check we are still mounted.
    if (this.mounted) {
      this.attachCameraFocusPoses();
      this.changeVisuals('stenosis');

      // Set the target position of the light to be the center of the 3d model (ie the point we rotate about).
      const center = this.getAnatomicalCentre();
      if (MODEL_USE_LIGHTING_FIX) {
        this.lightTarget.position.set(center.x, center.y, center.z);
      }

      // Set to initial camera position.
      this.camera.position.set(300, 200, -300);
      // Point the camera to the center of the model.
      this.controls.target = center;
      this.controls.update();

      // Determine what VP marker positions are valid and set the initial positions.
      this.precomputeVPMarkerPositions();

      if (MODEL_RENDER_ROTATION_ANIMATION) {
        // Start the 3D model rotation animation.
        this.startRotationAnimation();
      } else {
        // Animate the camera on initialization but not on a reset after a lost context.
        this.defaultCameraPose(!this.initialized);
        this.initialized = true;
      }

      // Make default visibilities
      this.groupMeshes.visible = true;
      this.groupInteract.visible = true;
      this.groupVulnerablePlaques.visible = true;

      // Now we're loaded
      this.setState({ loaded: true });
      this.props.setModelLoaded(true);

      // Wait for camera to finish moving

      this.cameraLoadedTimeout = window.setTimeout(
        () => this.props.setModelCameraLoaded(true),
        MODEL_TIME_POSITION_TWEEN
      );
    }
  }

  /**
   * Load the plaque geometry for the specified vessel returning it as a promise.
   */
  loadPlaqueForVessel(loader: any, vesselID: string): Promise<any> {
    if (this.props.vesselData) {
      // Now load the plaque and provide other information
      // for (var j = 0; j < plaqueInfos.length; j++) {
      // Get the geometry itself pretaining to the given label
      // const promises = Object.keys(this.props.vesselData).map((key) => {
      const vesselLesions =
        this.props.vesselData &&
        this.props.vesselData[vesselID].contrast_lesion_ids;
      if (vesselLesions && vesselLesions.length) {
        const promises = vesselLesions.map(async (id) => {
          return loader
            .loadAsync(
              `/data/${this.props.patientID}/${this.props.runID}/vessel/${vesselID}/plaque-geometries/${id}?version=${this.props.versionHead}`
            )
            .then((geometry: any) => {
              return this.onPlaqueGeometryLoad(geometry);
            });
        });
        return Promise.allSettled(promises);
      }
    }
    return Promise.reject();
  }

  /**
   * Get the best position around the circle of slice points that is closest to the current camera position.
   */
  getMarkerPosition(
    validPositions: THREE.Vector3[],
    group: THREE.Group
  ): THREE.Vector3 | undefined {
    if (!validPositions) return undefined;

    // Un-apply the group transformation to the camera vs applying the the group transformation to each point in the group.
    const invMatrix = new THREE.Matrix4();
    invMatrix.copy(group.matrixWorld).invert();
    const cameraPosition = this.camera.position.clone().applyMatrix4(invMatrix);

    // Default to the first point.
    let bestPoint: number = -1;
    let bestDistance = 1000000;
    // Loop through each point around the ring to find the one closest to the camera.
    for (var i = 0; i < validPositions.length; i++) {
      const cameraDistance = new THREE.Vector3();
      cameraDistance.subVectors(cameraPosition, validPositions[i]);
      const distance = cameraDistance.length();
      if (distance < bestDistance) {
        bestPoint = i;
        bestDistance = distance;
      }
    }
    if (bestPoint >= 0) {
      return validPositions[bestPoint];
    }
    return undefined;
  }

  /**
   * Create a promise to load the vulnerable plaque for the specified vessel.
   */
  loadVulnerablePlaqueForVessel(vesselID: string): Promise<any> {
    if (!this.props.contrastLesionData || !this.props.vesselData) {
      throw Error(`loadVulnerablePlaque failed for vessel ${vesselID}`);
    }
    return api
      .getJSON(
        `/data/${this.props.patientID}/${this.props.runID}/vessel/${vesselID}/mpr/axes/all?version=${this.props.versionHead}`
      )
      .then((result) => {
        // Add the vulnerable plaque to the 3d model.
        if (this.props.contrastLesionData && this.props.vesselData) {
          const vesselCenterline = this.props.vesselData[vesselID]?.centerline;
          const vesselLesions = this.props.contrastLesionData[vesselID];
          const sliceLesionMapping = this.props.vesselData[vesselID]
            ?.slice_to_lesion_mapping;
          const markerPoints = this.getMarkerPoints(
            vesselCenterline,
            vesselLesions,
            sliceLesionMapping
          );
          Object.keys(result.view_idx).forEach((key) => {
            this.vesselDirections[parseInt(key)] = result.view_idx[key];
          });
          if (markerPoints) {
            this.renderVulnerablePlaque(markerPoints as MarkerPoints, vesselID);
          }
          return true;
        }
        throw Error(`loadVulnerablePlaque failed for vessel ${vesselID}`);
      });
  }

  getMarkerPoints(
    vesselCenterline: [number, number, number][],
    vesselLesions: { [key: string]: ContrastLesionData },
    sliceLesionMapping: number[][]
  ) {
    const markerPointsEntry = vesselLesions
      ? Object.entries(vesselLesions)
      : undefined;
    const returnVal = markerPointsEntry
      ? markerPointsEntry.map(([lesionId, value]) => {
          let priority = undefined;
          // Set the marker position to the vessel priority vessel
          if (typeof value.priority_slice !== 'undefined') {
            priority = value.slices[value.priority_slice];
          }
          // if priority_slice does not exist in the array of slice of the lesion
          // calculate the slice based on the slices exist i.e. the middle of the lesion
          if (priority === undefined) {
            priority = value.slices[Math.floor(value.slices.length / 2)];
          }
          // If slice is still not available, don't show a marker
          if (priority === undefined) {
            return false;
          }
          const priorityLesionId = sliceLesionMapping[priority][0];
          const vpBioMarkers: string[] = [];
          Object.entries(value.vp_biomarker_counts).forEach(([key, mark]) => {
            // Only get the VP markers for the priorty lesion on that slice
            if (mark > 0 && lesionId === priorityLesionId.toString()) {
              vpBioMarkers.push(key);
            }
          });
          return {
            sliceIdx: priority,
            point: vesselCenterline[priority],
            vpBioMarkers: vpBioMarkers,
          };
        })
      : [];

    return returnVal;
  }

  renderVulnerablePlaque(markerPoints: MarkerPoints, vesselID: string) {
    // Generate the markers
    markerPoints.forEach((mark) => {
      const thisPoint = mark.point;
      if (!mark.vpBioMarkers || mark.vpBioMarkers.length <= 0) return;

      let vp_group = new THREE.Group();
      vp_group.name = `vp_group__${vesselID}`;
      vp_group.position.set(thisPoint[0], thisPoint[1], thisPoint[2]);
      this.groupVulnerablePlaques.add(vp_group);

      let cl_norms = this.vesselDirections[mark.sliceIdx].dirs;
      // Get rotation from normal and apply
      var mx = new THREE.Matrix4().lookAt(
        new THREE.Vector3(cl_norms[0], cl_norms[1], cl_norms[2]),
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 1, 0)
      );
      vp_group.setRotationFromMatrix(mx);

      // Create a ring about the vessel of positions we may move the VP marker to. It's set up to spin about the
      // vessel rather than billboard on the vessel center. We will then check which of these positions are valid
      // once we have all the geometry loaded.
      let geom = new THREE.CircleGeometry(VP_CIRCLE_RADIUS, VP_CIRCLE_POINTS);
      let mat = new THREE.LineBasicMaterial({
        visible: false,
      });
      const c = new THREE.LineLoop(geom, mat);
      c.name = 'vp_circle';
      vp_group.add(c);

      let marker_group = new THREE.Group();
      marker_group.name = 'vp_marker';

      // marker outer ring
      const markerBgGeometry = new THREE.RingGeometry(0.5, 1.5, 32);
      const markerBgMaterial = new THREE.MeshBasicMaterial({
        color: 0xffeeee,
        side: DoubleSide,
        opacity: 0.25,
        transparent: true,
        fog: false, // Don't ever fog the marker.
        depthWrite: false, // render objects behind
      });
      const bgMarker = new THREE.Mesh(markerBgGeometry, markerBgMaterial);
      // marker dark border
      const markerOutlineGeometry = new THREE.RingGeometry(0.55, 0.4, 32);
      const markerOutlineMaterial = new THREE.MeshBasicMaterial({
        color: 0x0d0d0d,
        side: DoubleSide,
        fog: false, // Don't ever fog the marker.
      });
      const OutlineMarker = new THREE.Mesh(
        markerOutlineGeometry,
        markerOutlineMaterial
      );

      const markerGeometry = new THREE.CircleGeometry(0.4, 32);
      const markerMaterial = new THREE.MeshBasicMaterial({
        color: 0xffeeee,
        side: DoubleSide,
        fog: false, // Don't ever fog the marker.
      });
      const marker = new THREE.Mesh(markerGeometry, markerMaterial);
      marker.name = `vp-slice-${mark.sliceIdx}`;

      marker_group.add(marker);
      marker_group.add(OutlineMarker);
      marker_group.add(bgMarker);

      // Disable shadows on the marker.
      marker_group.castShadow = false;
      marker_group.receiveShadow = false;

      vp_group.add(marker_group);

      // Set the marker to the cernter of the circle (TODO: this will need to be altered).
      marker_group.position.set(0, 0, 0);
    });
  }

  getVesselColor(vesselId: string) {
    switch (vesselId) {
      case 'lm':
        return THEME.colors.vessels.lm;
      case 'lad':
        return THEME.colors.vessels.lad;
      case 'rca':
        return THEME.colors.vessels.rca;
      case 'lcx':
        return THEME.colors.vessels.lcx;
      default:
        return THEME.colors.vessels.other;
    }
  }

  // Swaps between the desired colour for all meshes in the scene depending
  // on the code
  changeVisuals(code: string) {
    if (code === 'default') {
      // All meshes need to go to basic colour (not from a colour buffer)
      // Default colour is defined at the top of the file in a constant
      this.groupMeshes.traverse(function (node: any) {
        if (node instanceof THREE.Mesh) {
          let mesh = node;
          // Swap from vertex to solid colours, and use the same solid colour
          mesh.material.vertexColors = THREE.NoColors;
          mesh.material.color = new THREE.Color(COLOR_MODEL_DEFAULT);
          mesh.material.needsUpdate = true;
        }
      });
      // Turn on/off the right groups
      this.groupMeshes.visible = true;
      this.groupPlaques.visible = false;
    } else if (code === 'stenosis') {
      // All meshes with stenosis colour buffers need to be switched to using
      // colour buffers with the desired colour profile. All meshes without
      // stenosis colour buffers need to use the first colour in the profile,
      // with a single solid colour
      this.groupMeshes.traverse(function (node: any) {
        if (node instanceof THREE.Mesh) {
          let mesh = node;
          // Swap from solid to vertex colours and set colours depending on
          // available colour buffers
          if (mesh['stenosis']) {
            // Copy the desired colour attribute into the color attribute
            let colbuffer = mesh.geometry.getAttribute('color');
            colbuffer.copy(mesh.geometry.getAttribute('stenosistexture'));
            colbuffer.needsUpdate = true;
            // Use the vertex colours just assigned in the color attribute
            mesh.material.vertexColors = THREE.VertexColors;
            // Decrease the tint to improve colour saturation in reflective areas.
            mesh.material.color = new THREE.Color('#AAAAAA');
          } else {
            // Then can use its vertex colours
            mesh.material.vertexColors = THREE.NoColors;
            // Needs the value, not the string
            const thisVesselColor =
              mesh.name !== 'AORTA'
                ? STENOSIS_COLOR_MAP[0].color
                : COLOR_MODEL_AORTA;
            mesh.material.color = new THREE.Color(thisVesselColor);
          }
          // Swap between vertex and flat colours
          mesh.material.needsUpdate = true;
        }
      });
      // Turn on/off the right groups
      this.groupMeshes.visible = true;
      this.groupPlaques.visible = this.props.showPlaque;
      // TODO functionise this swapping rather than copy paste
    } else if (code === 'toggle_plaque') {
      // Plaque and all else aren't mutually exclusive!
      this.groupPlaques.visible = this.props.showPlaque;
    } else if (code === 'toggle_vulnerable_plaque') {
      this.groupVulnerablePlaques.visible = this.props.showVP;
    } else if (code === 'plaque_opacity') {
      this.groupPlaques.children.forEach((element: any) => {
        element.material.transparent = true;
        element.material.opacity = this.props.plaqueOpacity;
      });
    } else {
      console.log('Error: unrecognised colour change code requested: ' + code);
    }
  }

  // Data loader callback for loading main anatomical structure meshes and
  // ancillary information
  async onMainGeometryLoad({
    geometry,
    label,
    cl_mm,
    v2s,
    axes,
  }: {
    geometry: any;
    label: string;
    cl_mm?: Centerline;
    v2s?: Vertex2Slice;
    axes?: MPRAxes;
  }) {
    // Check this component is still mounted.
    if (!this.mounted) {
      return;
    }

    // PLY's defaultly don't have face normals, so they need to
    // be calculated to be outward facing.
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();

    // And collision information
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();

    // Swap between this and default color
    let material = getDefaultMaterial();

    // Add mesh and perform translations to move it into the correct position
    // in R^3
    let mesh = new THREE.Mesh(geometry, material);
    this.groupMeshes.add(mesh);
    mesh.updateMatrixWorld(true);

    // Lighting presets
    mesh.castShadow = MODEL_RENDER_SHADOWS;
    mesh.receiveShadow = MODEL_RENDER_SHADOWS;

    // Make the mesh searchable in the scene tree
    // TODO should move to id or ensure that labels are enforceably unique
    mesh.name = label;

    // If directed to attempt a load of the geometry to slice mapping, then do
    // so, because this is a vessel
    if (label !== 'AORTA') {
      // Piggyback the mesh structure and attach f2s to it for querying - this
      // will exceptionally simplify picking behaviour
      // TODO add f2s

      // Get the vertex to slice mapping.
      if (!v2s) {
        v2s = await api.getJSON(
          `data/${this.props.patientID}/${this.props.runID}/vessel/${label}/vertex-to-slice-mapping`
        );
      }
      if (v2s) {
        mesh.v2s = v2s;
      }
      // Add the centerline points which can be used for label placement.
      if (!cl_mm) {
        cl_mm = await api.getJSON(
          `data/${this.props.patientID}/${this.props.runID}/vessel/${label}/centerline`
        );
      }
      if (cl_mm) {
        mesh['cl_mm'] = cl_mm;
      }
      // Get the mesh normals from the mpr axes directions.
      if (!axes) {
        axes = await api.getJSON(
          `data/${this.props.patientID}/${this.props.runID}/vessel/${label}/mpr/axes/all`
        );
      }
      if (axes) {
        const axesViews = axes?.view_idx;
        const norms = new Array(axesViews ? Object.keys(axesViews).length : 0);
        for (const key in axesViews) {
          norms[parseInt(key)] = axesViews[key].dirs;
        }
        mesh['norms'] = norms;
      }

      // Use the slice mappings and the stenosis values to create a set of
      // vertex colors which can be assigned to the buffer geometry as a
      // list of colours

      // Start by setting up a new attribute and attaching it to the buffer
      // geometry, then getting an editing reference to it
      let buffergeo = mesh.geometry;
      let nverts = buffergeo.getAttribute('position').count;
      // This is the buffer that actually get's drawn, everyone hasAxesHelper one of these
      buffergeo.setAttribute(
        'color',
        new THREE.BufferAttribute(new Float32Array(3 * nverts), 3)
      );
      // STENOSIS
      // Get and commit the stenosis and plaque values to the vessel
      if (
        this.props.contrastLesionData &&
        this.props.vesselData &&
        this.props.vesselData[label]
      ) {
        mesh.stenosis = this.props.stenosis[label];
        mesh.plaque = this.props.vesselData[label].plaque;
        mesh.slice_cmap = this.props.vesselData[label].slice_cmap;
      }

      // Create and write into the stenosis buffer attribute
      buffergeo.setAttribute(
        'stenosistexture',
        new THREE.BufferAttribute(new Float32Array(3 * nverts), 3)
      );
      let stenosistexture = buffergeo.getAttribute('stenosistexture');
      for (var i = 0; i < nverts; i++) {
        // Set the i'th vertex to a given RGB based on the stenosis color at
        // that pointAxesHelper
        let slice = mesh.v2s[i];

        let value = mesh.slice_cmap[slice];
        let colAtValue = new THREE.Color(VESSEL_STENOSIS_COLOR[value]);
        if (colAtValue !== null) {
          stenosistexture.setXYZ(i, colAtValue.r, colAtValue.g, colAtValue.b);
        }
      }
      // TEXTURE MAPPINGS COMPLETE

      // Set an anchor position which is roughly in/on the mesh
      let cl = mesh['cl_mm'];
      let clMid = cl[Math.round(cl.length / 2)];
      let anchor = new THREE.Vector3(clMid[0], clMid[1], clMid[2]);
      mesh['anchor'] = anchor;
    } else {
      // Aorta at this time does not have any special attributes or colour
      // attributes

      // Set an anchor position which is roughly in/on the mesh
      let vpos = mesh.geometry.getAttribute('position').array;
      let anchor = new THREE.Vector3(vpos[0], vpos[1], vpos[2]);
      mesh['anchor'] = anchor;
    }

    return mesh;
  }

  // Data loader callback for loading plaque volumes
  async onPlaqueGeometryLoad(geometry: any) {
    // Check this component is still mounted.
    if (!this.mounted) {
      return;
    }
    // PLY's defaultly don't have face normals, so they need to
    // be calculated to be outward facing
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();
    // And collision information
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();

    // Swap between this and default color
    let material = getDefaultPlaqueMaterial();

    // Add mesh and perform translations to move it into the correct position
    // in R^3
    let mesh = new THREE.Mesh(geometry, material);
    mesh.updateMatrixWorld(true);

    // Lighting presets (if shadows are enabled plaque should receive shadows but not cast them).
    mesh.castShadow = false;
    mesh.receiveShadow = MODEL_RENDER_SHADOWS;

    // Add into the plaque's group
    this.groupPlaques.add(mesh);
  }

  /**
   * Initialize THREE.JS, the scene, the 3d model, the camera etc.
   * This can be called when first loaded or after cleanup() to reset the renderer.
   */
  async init() {
    // Check the component is still mounted.
    if (!this.mounted) {
      return;
    }

    // Initialize the THREE JS scene and associated member variables. Must be
    // done after mounting to successfully get element by ID. Note that these
    // functions write new member variables to this
    this.initRenderer();
    this.initScene();
    this.initControls();

    // Define a load manager to report progress/have progress bars etc and
    // attach to loader
    let manager = new THREE.LoadingManager();
    manager.onStart = function (
      url: string,
      itemsLoaded: string,
      itemsTotal: string
    ) {
      console.log(
        'Started loading file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      );
    };
    manager.onProgress = function (
      url: string,
      itemsLoaded: string,
      itemsTotal: string
    ) {
      console.log(
        'Loading file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      );
    };
    manager.onLoad = function () {
      console.log(
        'Entirety of loading is complete, conducting load dependant ops'
      );
    };
    manager.onError = function (url: string) {
      console.log('There was an error loading ' + url);
    };

    // Start with geometry loads using a PLY loader (just one - they're
    // expensive). This actually loads our stuff into scene.
    // We'll hold a direct reference to our loaded objects because that
    // will be easier than using the scene tree to locate entities
    const loader = await api.newPLYLoader();
    // Check the component is still mounted.
    if (!this.mounted) {
      return;
    }

    // Load the aorta and vessels in tandem.
    await this.loadAortaAndVessels(loader);
    // Check the component is still mounted.
    if (!this.mounted) {
      return;
    }

    // Enter the busy rendering loop and show the loading screen.
    this.update();
  }

  async componentDidMount() {
    this.init();

    // For console testing
    // @ts-ignore
    window.test_model = this;
  }

  cleanupCanvasListeners = (renderer: WebGLRenderer | undefined): void => {
    if (renderer == null) {
      console.error(
        '3d Model cleanupCanvasListeneres failed due to null renderer.'
      );
      return;
    }

    const canvas = renderer.getContext().canvas;
    if (canvas != null) {
      canvas.removeEventListener('pointermove', this.pickHover);
      canvas.removeEventListener('pointerdown', this.mouseDownListener);
      canvas.removeEventListener('pointerup', this.mouseUpListener);
      canvas.removeEventListener('webglcontextlost', this.contextLostListener);
      canvas.removeEventListener(
        'webglcontextrestored',
        this.contextRestoredListener
      );
    }
  };

  /**
   * Cleanup THREE.JS releasing the WebGL context, stop the update loop, clear the scene, the 3d model, the camera etc.
   * This can be called on unmount or before init() to reset the renderer.
   */
  cleanup = () => {
    // Cancel any tween animations.
    this.stopTweenAnimations();

    this.cleanupCanvasListeners(this.renderer);

    window.removeEventListener('resize', this.onWindowResize);
    this.controls.removeEventListener('change', this.updateLight);

    if (this.fullScreenMediaQueryList) {
      this.fullScreenMediaQueryList.removeListener(this.onWindowResize);
    }

    window.clearTimeout(this.cameraLoadedTimeout);

    // Remove the THREE js element.
    if (this.renderer) {
      this.mount?.removeChild(this.renderer.domElement);
      this.renderer
        .getContext()
        .getExtension('WEBGL_lose_context')
        ?.loseContext();
    }

    // Clean up all variables.
    this.container = null;
    this.width = 0;
    this.height = 0;
    this.renderer = undefined;
    this.clock = undefined;
    this.scene = undefined;
    this.groupMeshes = undefined;
    this.groupVulnerablePlaques = undefined;
    this.groupPlaques = undefined;
    this.groupInteract = undefined;
    this.camera = undefined;
    this.controls = undefined;
    this.dirLight = undefined;
    this.backLight = undefined;
    this.fullScreenMediaQueryList = undefined;
    this.moved = undefined;
    this.clicking = undefined;
    this.vesselDirections = [];
    this.vp_data_points = [];
    this.cameraLoadedTimeout = undefined;

    // Clean up all state.
    this.setState({
      queryVessel: null,
      querySlice: null,
      queryStenosis: null,
      queryPlaqueInfo: null,
      loaded: false,
      oldY: 0,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
    this.cleanup();
  }

  onWindowResize() {
    // Set height and width of renderer, recalc camera aspect, and update
    // projection matrix
    if (this.renderer == null || this.container == null) {
      console.error(
        '3dModel onWindowsResize failed due to null renderer or container.'
      );
      return;
    }

    this.width = this.props.modelRef.current.clientWidth;
    this.height = this.props.modelRef.current.clientHeight;
    this.renderer.setSize(this.width, this.height);
    let w = this.container.clientWidth;
    let h = this.container.clientHeight;
    this.camera.aspect = w / h;

    this.camera.updateProjectionMatrix();
  }

  /**
   * Resize the rendering canvas to the given size. Useful for taking screenshots.
   */
  resize(width: number, height: number) {
    if (this.renderer == null || this.container == null) {
      console.error('3dModel resize failed due to null renderer or container.');
      return;
    }

    this.width = width;
    this.height = height;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = width / height;

    this.camera.updateProjectionMatrix();
    this.update(false);
  }

  /**
   * Use a ray cast to detect a collision at any point between the origin and dir away from it.
   */
  detectCollsion(
    origin: THREE.Vector3,
    children: any[],
    dir: THREE.Vector3,
    groupName: string
  ) {
    if (children.length === 0) {
      return false;
    }

    // Find all collisions between the origin and 2 * VP_COLLISION_RADIUS far away.
    const raycaster = new THREE.Raycaster(
      origin,
      dir.clone().normalize(),
      0,
      2 * VP_COLLISION_RADIUS
    );

    const intersects = raycaster.intersectObjects(children);
    const filteredIntersects = intersects.filter(
      (i: any) => groupName.indexOf(i.object.name) < 0
    );
    return filteredIntersects.length > 0;
  }

  // Helper for returning the closest picked object and associated information
  pickClosestMesh(event: any, containers: any) {
    // Attempt to retreive mesh info for the object at the pick
    // Note that renderer is at an offset into the page, and the event is given
    // wrt page coordinates.

    // Get the children of all the provided containers to pick from
    if (containers.length === 0) {
      return null;
    }
    let children = []; // A branch new array - not a reference to an old one
    for (var i = 0; i < containers.length; i++) {
      // Use spread operator to pass all elements of this conatiners children
      // to push function
      children.push(...containers[i].children);
    }

    // Note that this.container =/= containers!!!
    if (this.container === null) return null;

    let offsetRect = this.container.getBoundingClientRect();
    let normDevX = +(((event.clientX - offsetRect.left) / this.width) * 2 - 1);
    let normDevY = -(((event.clientY - offsetRect.top) / this.height) * 2 - 1);

    // Setup a raycaster
    let raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(new THREE.Vector2(normDevX, normDevY), this.camera);

    // For debugging
    // this.scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 300, 0xff0000) );

    // Perform a raycast all the way to the vertex level. Only select from
    // meshes, not both wireframe and meshes
    let intersects = raycaster.intersectObjects(children);
    if (intersects.length !== 0) {
      // Only return meshes no lights or groups or other garbage
      if (intersects[0].object.type === 'Mesh') {
        return intersects[0];
      }
    } else {
      return null;
    }
  }

  /**
   * Ensure the VP markers are facing the camera (ie billboards).
   * This is a pretty fast operation so we can afford to update
   * them every frame.
   */
  orientateVPMarkers = () => {
    if (!this.groupVulnerablePlaques || !this.props.showVP) return;
    this.groupVulnerablePlaques.children.forEach((vpGroup: THREE.Group) => {
      // Find the marker.
      const marker = vpGroup.children.find(
        (x): x is THREE.Mesh => x.name === 'vp_marker'
      );
      // Move the marker to point to the camera.
      if (marker) {
        marker.lookAt(this.camera.position, Math.PI);
      }
    });
  };

  /**
   * Run through all the marker positions on the vp_circle and test which are inside a vessel.
   */
  precomputeVPMarkerPositions = () => {
    if (this.renderer == null) {
      console.error(
        '3dModel precomputeVPMarkerPositions failed due to null renderer'
      );
      return;
    }

    // We need to render the scene at least once with the current geometry before we can precomputeVPMarkerPositions.
    this.renderer.render(this.scene, this.camera);

    // Get an array of all the groupMeshes children.
    const groupMeshesChildren = [...this.groupMeshes.children];

    // Loop through each VP.
    this.groupVulnerablePlaques.children.forEach((vpGroup: THREE.Group) => {
      const marker = vpGroup.children.find(
        (x): x is THREE.Mesh => x.name === 'vp_marker'
      );
      const circle = vpGroup.children.find(
        (x): x is THREE.LineLoop => x.name === 'vp_circle'
      );

      if (marker && circle) {
        const geom = circle.geometry;
        const circlePoints = geom.attributes.position.array as number[];
        const validPositions = [];

        // Get the center of the circle (this is the first point) and convert it to a world position.
        const center = new THREE.Vector3(
          circlePoints[0],
          circlePoints[1],
          circlePoints[2]
        );
        const worldCenter = center.clone().applyMatrix4(vpGroup.matrixWorld);

        // Check that the position does not collide with another vessel.
        for (let i = 1; i < circlePoints.length / 3; i++) {
          // Get this point on the circle and convert it to a world position.
          const circlePoint = new THREE.Vector3(
            circlePoints[3 * i],
            circlePoints[3 * i + 1],
            circlePoints[3 * i + 2]
          );
          const worldPoint = circlePoint
            .clone()
            .applyMatrix4(vpGroup.matrixWorld);

          // Get the direction from the circle center out to the circle point.
          const dirWorld = worldPoint.clone().sub(worldCenter).normalize();

          // Add -1/2 the radius we will be testing to the world position (ie move it this distance closer to the center)
          worldPoint.addScaledVector(dirWorld, -VP_COLLISION_RADIUS);

          // Test if there is a collision with geometry from this position out to 2 * VP_COLLISION_RADIUS away.
          const collision = this.detectCollsion(
            worldPoint,
            groupMeshesChildren,
            dirWorld,
            vpGroup.name
          );

          // If there was no collision we can save this as a valid marker position.
          if (!collision) {
            validPositions.push(circlePoint);
          }
        }

        // Save the valid positions in the vp_marker userData.
        marker.userData.validPositions = validPositions;
      }
    });
  };

  /**
   * The VP markers can swing about the mesh slice to try to be visible.
   * TODO: Unless this is really the desired behaviour having them act
   * like billboards with a Z offset (eg like the vessel labels) makes
   * more sense to Steven.
   */
  moveVPMarkers = () => {
    if (!this.groupVulnerablePlaques || !this.props.showVP) return;
    this.groupVulnerablePlaques.children.forEach((vpGroup: THREE.Group) => {
      const marker = vpGroup.children.find(
        (x): x is THREE.Mesh => x.name === 'vp_marker'
      );
      if (marker) {
        // Get the valid positions from the vp_marker userData.
        const validPositions = marker.userData.validPositions;
        if (validPositions) {
          // Get the closest point to the camera from the array of valid points.
          const closestPoint = this.getMarkerPosition(validPositions, vpGroup);
          if (closestPoint !== undefined) {
            // Set the marker position to the closest point.
            marker.position.copy(closestPoint);
          }
        }
      }
    });
  };

  performManualZoom = (e: any) => {
    let yDirection = null;

    // detect which direction mouse is moving
    if (this.state.oldY < e.pageY) {
      yDirection = 'down';
    } else if (this.state.oldY > e.pageY) {
      yDirection = 'up';
    }

    this.setState({ oldY: e.pageY });

    // Holding down left and right click +
    //    moving up = zooming in
    //    moving down = zooming out
    // Als have min max zoom level on camera
    if (yDirection === 'down' && this.camera.zoom > MODEL_CAMERA_MIN_ZOOM) {
      this.camera.zoom = this.camera.zoom - 0.1;
    } else if (
      yDirection === 'up' &&
      this.camera.zoom < MODEL_CAMERA_MAX_ZOOM
    ) {
      this.camera.zoom = this.camera.zoom + 0.1;
    }
    this.camera.updateProjectionMatrix();
  };

  /**
   * Stop all current tween animations.
   */
  stopTweenAnimations = () => {
    if (this.baseTween) {
      this.baseTween.stop();
      this.baseTween = undefined;
    }

    if (this.resetTween) {
      this.resetTween.stop();
      this.resetTween = undefined;
    }

    if (this.panTween) {
      this.panTween.stop();
      this.panTween = undefined;
    }

    if (this.zoomTween) {
      this.zoomTween.stop();
      this.zoomTween = undefined;
    }
  };

  mouseDownListener = (event: any) => {
    // re-enable panning and rotate
    this.controls.enablePan = true;
    this.controls.enableRotate = true;
    this.moved = false;
    this.clicking = true;
    /* TODO This debug hack lets us force a lost context.
    if (event.button === 0) {
      console.log("3D model to lose the context");
      this.renderer.forceContextLoss();
    }
    else {
      console.log("3D model to restore the context");
      this.renderer.forceContextRestore();
    }
    */
    // Capture the mouse pointer.
    captureMouse(event);

    this.controls.update();
    this.stopTweenAnimations();
  };

  mouseUpListener = (event: any) => {
    // re-enable panning and rotate
    this.controls.enablePan = true;
    this.controls.enableRotate = true;
    this.clicking = false;
    if (!this.moved) this.pickClick(event);
  };

  contextLostListener = (event: any) => {
    console.log(
      'The 3D model has lost its WebGL context, resetting the 3D model'
    );
    // Inform WebGL that we handle context restoration.
    event.preventDefault();
    this.cleanup();
    this.init();
  };

  contextRestoredListener = (event: any) => {
    console.log('The 3D model has restored its WebGL context');
  };

  pickHover(event: any) {
    let pick = this.pickClosestMesh(event, [this.groupMeshes]);
    this.moved = true;
    // Only manually scroll if wheel scroll is disabled
    if (!MODEL_ENABLE_SCROLL_ZOOM && event.buttons === 3) {
      this.controls.enablePan = false;
      this.controls.enableRotate = false;
      this.performManualZoom(event);
      return;
    }
    if (this.clicking) {
      return;
    }
    if (pick) {
      // Key things here are the mesh itself, with associated risk info, as
      // well as fine details of the pick, such as:
      // pick.object
      // pick.object.name
      // pick.object.cl_mm
      // pick.object.stenosis
      // pick.object.v2s
      // pick.distance
      // pick.face
      // pick.faceIndex

      // Can only get risk and other details from vessel, not aorta
      let name = pick.object.name;
      if (name !== 'AORTA') {
        // You may get an error here because the meshes haven't fully loaded,
        // so we check if defined before querying
        const mesh = pick.object;
        const vidx = pick.face.a; // At a small enough scale that face ~ vertex
        let sliceidx;
        // TODO add face to slice mapping check
        if (mesh.v2s) {
          sliceidx = mesh.v2s[vidx];
        }
        let hasLesion = false;
        if (this.props.vesselData) {
          // Check if this slice has a lesion
          const vesselData = this.props.vesselData[name];
          if (vesselData) {
            const sliceLesions =
              vesselData['slice_to_lesion_mapping'][sliceidx];
            hasLesion = sliceLesions && sliceLesions.length > 0;
          }
        }

        if (typeof sliceidx !== 'undefined') {
          const stenosisAtPick = mesh.stenosis && mesh.stenosis[sliceidx];

          // Update a graphical screen with all information
          this.setState({
            queryVessel: name,
            querySlice: sliceidx,
            queryStenosis: stenosisAtPick,
          });
          this.props.setHoverSliceIdx(sliceidx);
          this.props.setHoverVessel(name);
          this.props.setShowHoverData(hasLesion); // Only showing hover data if there is a lesion
        }
      } else {
        // Nothing on the aorta, binding on 2, click outta 3...
        this.setState({
          queryVessel: null,
          querySlice: null,
          queryStenosis: null,
        });
        this.props.setShowHoverData(false);
      }
    } else {
      // Queried nothing
      this.setState({
        queryVessel: null,
        querySlice: null,
        queryStenosis: null,
      });
      this.props.setShowHoverData(false);
    }
  }

  pickClick(event: any) {
    // Only accept left clicks
    if (event.button !== 0) {
      console.log('Non left click, discarding');
      return;
    }
    if (this.props.savingSelectedVessel) {
      showToast.warning('Please wait for changes to finish saving');
      return;
    }

    // Attempt to pick an object and select object for scrutiny in the
    // wider application
    let pick = this.pickClosestMesh(event, [
      this.groupMeshes,
      // TODO: temporarily deprecating plaques being clickable
      // this.groupPlaques,
    ]);
    if (pick) {
      // Then we want to update slice idxs in rest of page
      if (pick.object.name !== 'AORTA') {
        // Check if the object has a plaqueinfo field, in which case its a
        // plaque and we can call this early
        if (pick.object.plaqueInfo) {
          this.setState({
            queryPlaqueInfo: pick.object.plaqueInfo,
          });
        } else {
          // Use pick information to reposition camera

          // Update selected vessel
          // this.props.setSelectedVesselName(pick.object.name);
          // You may get an error here because the meshes haven't fully loaded,
          // so we check if defined before querying
          let mesh = pick.object;
          let vidx = pick.face.a; // At a small enough scale that face ~ vertex
          const vid = pick.object.name.toLowerCase();
          if (mesh.v2s) {
            let sliceidx = mesh.v2s[vidx];
            // check if on a lesion
            const lesions =
              (this.props.vesselData &&
                this.props.vesselData[vid]?.slice_to_lesion_mapping &&
                this.props.vesselData[vid]?.slice_to_lesion_mapping[
                  sliceidx
                ]) ||
              undefined;
            if (lesions && lesions.length > 0) {
              // TODO be smarter about how to prioritise the lesions
              // for now just defaulting to first in mapping
              const lesionId = lesions[0];
              const lesionData =
                (this.props.contrastLesionData &&
                  this.props.contrastLesionData[vid] &&
                  this.props.contrastLesionData[vid][lesionId]) ||
                undefined;
              if (
                lesionData?.priority_slice !== undefined &&
                lesionData?.slices
              ) {
                sliceidx =
                  lesionData.slices[lesionData.priority_slice] !== undefined
                    ? lesionData.slices[lesionData.priority_slice]
                    : lesionData.slices[
                        Math.floor(lesionData.slices.length / 2)
                      ];
              }
            }
            // Then update the slice
            if (sliceidx) {
              if (pick.object.name) {
                this.props.setSelectedVesselName(
                  pick.object.name,
                  undefined,
                  sliceidx,
                  undefined
                );
              }
            }
          }

          // Queried nothing
          this.setState({
            queryPlaqueInfo: null,
          });
        }
      }
    } else {
      // Queried nothing
      this.setState({
        queryPlaqueInfo: null,
      });
    }
  }

  // Helper function to move camera based on a target point and an object with
  // a camera focus position
  moveCameraToFit(object: any, target: any, animate: boolean = true) {
    // Can't move camera if we don't have a focus position, so check this
    // first
    if (!object['cameraFocusPosition']) {
      console.log(
        "Can't focus on object with name '" +
          object.name +
          "' as it has no camera focus position calculated"
      );
      return;
    }

    // For inner scope visibility
    let ctrl = this.controls;

    // We don't need to calculate a pose on the fly as we can just use the precalculated focus position but
    // we do want to adjust the distance of the camera from the target position to ensure the 3d model's
    // bounding sphere fits snugly edge to edge on the viewport's shortest dimension.
    const newpos = new THREE.Vector3().copy(object['cameraFocusPosition']);
    // Calculate and save the 3d model's bounding sphere if it needs updating.
    if (!this.boundingSphere) {
      this.boundingSphere = this.computeBoundingSphere();
    }
    if (this.boundingSphere) {
      // Get the minimum FOV (based on the viewport width or height).
      const fovW = (fovH * this.width) / this.height;
      const fov = Math.min(fovW, fovH);
      // Use half the FOV because we are using the sphere radius vs diameter.
      const desiredDistance =
        this.boundingSphere.radius / Math.tan((0.5 * fov * Math.PI) / 180.0);
      // Reposition the camera so that its vector to the target position is the same but the distance is such
      // that the bounding sphere fits snugly in the viewport.
      newpos
        .sub(target)
        .normalize()
        .multiplyScalar(desiredDistance)
        .add(target);
    }

    // Stop all current animations.
    this.stopTweenAnimations();

    // When animating the transition we use tweens, otherwise not
    if (animate) {
      // Move the camera target (what it's looking at) to the center of the model as required.
      if (target.distanceTo(ctrl.target) > 0.1) {
        this.resetTween = new TWEEN.Tween(ctrl.target)
          .to(
            { x: target.x, y: target.y, z: target.z },
            MODEL_TIME_TARGET_TWEEN
          )
          .easing(TWEEN.Easing.Quadratic.Out)
          .onUpdate((t: any) => {
            ctrl.update();
          })
          .onComplete(() => {
            this.resetTween = undefined;
          });
      }

      if (this.camera.zoom >= 1) {
        this.resetZoom(false);
      }
      // Using a tween, which works on write protected data!
      // eslint-disable-next-line no-unused-vars
      this.baseTween = new TWEEN.Tween(this.camera.position)
        .to(
          { x: newpos.x, y: newpos.y, z: newpos.z },
          MODEL_TIME_POSITION_TWEEN
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onComplete(() => {
          this.baseTween = undefined;
        });

      // Animate a reset of the camera pan via the control.
      this.panTween = new TWEEN.Tween(ctrl.pan)
        .to({ x: 0, y: 0 }, MODEL_TIME_POSITION_TWEEN)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onComplete(() => {
          this.panTween = undefined;
        });

      // Start all the animations together.
      this.baseTween?.start();
      this.resetTween?.start();
      this.panTween?.start();
      this.zoomTween?.start();
    } else {
      // Move directly
      ctrl.target = target;
      this.camera.position.set(newpos.x, newpos.y, newpos.z);
      // Reset the camera pan via the camera control.
      ctrl.pan.x = 0;
      ctrl.pan.y = 0;
      // Reset the camera zoom.
      this.camera.zoom = 1;
    }
  }

  // Resetting zoom level on vessel change and reset camera click
  resetZoom(start: boolean) {
    const zoom = {
      value: this.camera.zoom, // from current zoom (no matter if it's more or less than 1)
    };
    var zoomEnd = {
      value: 1, // to the zoom of 1
    };
    // If there is a zoomTween existing, ensure it has stopped before starting another
    if (this.zoomTween) {
      this.zoomTween.stop();
    }
    this.zoomTween = new TWEEN.Tween(zoom)
      .to(zoomEnd, MODEL_TIME_POSITION_TWEEN)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        this.camera.zoom = zoom.value;
        this.camera.updateProjectionMatrix();
      });
    if (start) {
      this.zoomTween.start();
    }
  }

  // Helper function which snaps to an object/mesh based on its name
  focusOn(name: string, pick?: any, animate: boolean = true) {
    // TODO: The 'pick mode' isn't currently used ... should we remove it?
    if (pick && !name) {
      this.moveCameraToFit(pick.object, pick.point, animate);
    } else if (name && !pick) {
      // Find the mesh, target and distance to target if not provided in pick
      let mesh = this.groupMeshes.getObjectByName(name);
      if (mesh) {
        let cent = new THREE.Vector3();
        mesh.geometry.boundingBox.getCenter(cent);

        // Nudered UI controls because old system was too rollercoastery
        let target = this.getAnatomicalCentre();
        this.moveCameraToFit(mesh, target, animate);
      } else {
        console.log(
          "Requested focus target '" + name + "' does not (yet) exist in scene"
        );
      }
    }
  }

  /**
   * Update the 3D model with data for a particular vessel.
   */
  async addVessel(model3dData: Model3dData) {
    // Check the vessel doesn't already exist.
    let mesh = this.groupMeshes.getObjectByName(model3dData.vesselId);
    if (!mesh) {
      // We're loading the new vessel.
      this.setState({ loaded: false });
      api
        .newPLYLoader()
        .then((loader: any) => {
          // Parse the ArrayBuffer to load the 3D model geometry object.
          return loader.parse(model3dData.geometry);
        })
        .then((geometry: any) => {
          this.onMainGeometryLoad({
            geometry,
            label: model3dData.vesselId,
            cl_mm: model3dData.centerline,
            v2s: model3dData.v2s,
            axes: model3dData.axes,
          })
            .then(() => {
              if (this.mounted) {
                this.attachCameraFocusPoses();
                this.changeVisuals('stenosis');
                this.controls.update();
                this.boundingSphere = undefined;
                this.focusOn(model3dData.vesselId);
              }
              return true;
            })
            .finally(() => {
              if (this.mounted) {
                // We've now loaded the new vessel (or failed to load it).
                this.setState({ loaded: true });
              }
            });
        });
    }
  }

  // Helper to return the centre of all anatomical meshes (bounding box, not
  // barycentre)
  getAnatomicalCentre() {
    let cent = new THREE.Vector3();
    new THREE.Box3().setFromObject(this.groupMeshes).getCenter(cent);
    return cent;
  }

  /**
   * Helper to get the bounding sphere of the 3d model.
   * Make the center of the bounding sphere the anatomical center as we use this in our other calculations.
   */
  computeBoundingSphere() {
    // Set the sphere center to the anatomical center of the 3d model.
    let center = this.getAnatomicalCentre();
    const sphere = new THREE.Sphere(center, 0);
    // Loop through each mesh (vessels and aorta).
    this.groupMeshes.traverse(function (node: any) {
      if (node instanceof THREE.Mesh) {
        let mesh = node;
        const points = mesh.geometry.getAttribute('position');
        // Loop through each point in the mesh.
        for (let index = 0; index < points.count; index++) {
          // Get the mesh point and convert it to world coordinates.
          const point = new THREE.Vector3(
            points.array[3 * index],
            points.array[3 * index + 1],
            points.array[3 * index + 2]
          );
          localToWorld(point);
          // Update the sphere radius if this point is further away.
          const distance = center.distanceTo(point);
          if (distance > sphere.radius) {
            sphere.radius = distance;
          }
        }
      }
    });
    return sphere;
  }

  /* TODO: This isn't currently used
  // Helper to return the centre of the aorta for rotation
  getAortaCentre() {
    let cent = new THREE.Vector3();
    new THREE.Box3()
      .setFromObject(this.groupMeshes.getObjectByName('AORTA'))
      .getCenter(cent);
    return cent;
  }
  */

  // Recentres to arbitrary hardcoded general center (and orbits around it)
  defaultCameraPose(animate: boolean) {
    this.focusOn(this.props.vesselID, undefined, animate);

    // Show the 3d model's bounding sphere in debug mode.
    if (MODEL_DEBUG_MODE && this.boundingSphere) {
      const geometry = new THREE.SphereGeometry(
        this.boundingSphere.radius,
        32,
        32
      );
      const material = new THREE.MeshBasicMaterial({
        color: 0xffff00,
        opacity: 0.2,
        transparent: true,
      });
      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.copy(this.boundingSphere.center);
      this.scene.add(sphere);
    }
  }

  /**
   * Slowing rotate the camera about the 3d model in a continuous animation.
   */
  startRotationAnimation() {
    let center = this.getAnatomicalCentre();

    // Set up the camerta orientation relative to the model's center point.
    const cameraDistance = 140;
    const cameraAngle = (30 * Math.PI) / 180;

    // Set the controls 'target' to the center of the model and the initial camera position.
    this.camera.position.set(
      center.x,
      center.y + cameraDistance * Math.sin(cameraAngle),
      center.z - cameraDistance * Math.cos(cameraAngle)
    );
    this.controls.target = center;
    this.controls.update();

    // Enable the camera rotation animation.
    this.controls.autoRotate = true;
    // How fast to rotate around the target. Default is 2.0, which equates to 30 seconds per orbit at 60fps.
    this.controls.autoRotateSpeed = 3.0;

    if (MODEL_FULLSCREEN_DEMO) {
      // After the timeout, flip the VP and plaque rendering.
      const toggleVPAndPlaqueVisibility = (show: boolean) => {
        if (this.props.setVPAndPlaqueVisibility) {
          this.props.setVPAndPlaqueVisibility(show);
        }
        setTimeout(() => {
          toggleVPAndPlaqueVisibility(!show);
        }, 1000 * 60);
      };
      toggleVPAndPlaqueVisibility(true);
    }
  }

  // Attaches the pose the camera will travel to when selecting each mesh,
  // to each mesh
  attachCameraFocusPoses() {
    // Get the centre and extents of the anatomy meshes
    let grpbb = new THREE.Box3().setFromObject(this.groupMeshes);
    let grpcent = new THREE.Vector3();
    grpbb.getCenter(grpcent);
    let grpsize = new THREE.Vector3();
    grpbb.getSize(grpsize);

    // Use a bounding sphere to calculate the radius, and define a length which
    // will extend the points on this radius to an acceptable position
    let grprad = new THREE.Vector3().copy(grpsize).length() / 2.0;
    let desiredlength = grprad * 2;

    // For every 'focusable' object, calculate the position the camera will
    // go to when looking at it
    let _ = this;
    this.groupMeshes.traverse(function (node: any) {
      if (node instanceof THREE.Mesh) {
        let mesh = node;
        const cameraPostion = getCameraPosition(mesh.name);
        const fp = new THREE.Vector3().copy(cameraPostion);
        mesh['cameraFocusPosition'] = fp;

        if (MODEL_DEBUG_MODE) {
          var geometry = new THREE.SphereGeometry(5, 32, 32);
          var material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
          var sphere = new THREE.Mesh(geometry, material);
          sphere.position.set(fp.x, fp.y, fp.z);
          _.scene.add(sphere);
        }
      }
    });

    // The meshes group also requires a focus point so that we can have a
    // default position
    this.groupMeshes['cameraFocusPosition'] = new THREE.Vector3()
      .copy(grpcent)
      .add(new THREE.Vector3(0, 0.5, 1).setLength(desiredlength));
  }

  // Helper to update the slice visualizer during the update loop
  updateSliceVisualizer(sliceidx?: number) {
    if (!this.groupMeshes) return;
    // use props if not defined (will be defined if sliceidx changed externally)
    sliceidx = sliceidx ? sliceidx : this.props.sliceidx;

    // Ensure that slicer is positioned over the current state and slice. Try
    // to get the mesh with the associated report name
    let selected = this.groupMeshes.getObjectByName(this.props.vesselID);
    let slicer = this.groupInteract.getObjectByName('slicer');
    if (selected) {
      let mesh = selected;
      if (mesh['cl_mm'] && mesh['norms']) {
        if (
          sliceidx >= 0 &&
          mesh['cl_mm'][sliceidx] &&
          mesh['norms'][sliceidx]
        ) {
          // Position the slicer over the slice coordinate at that point
          let cl_coord = mesh['cl_mm'][sliceidx];
          let cl_norms = mesh['norms'][sliceidx];
          slicer.position.set(+cl_coord[0], +cl_coord[1], +cl_coord[2]);

          // Get rotation from normal and apply
          var mx = new THREE.Matrix4().lookAt(
            new THREE.Vector3(cl_norms[0], cl_norms[1], cl_norms[2]),
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(0, 1, 0)
          );
          slicer.setRotationFromMatrix(mx);
          slicer.visible = true;
        } else {
          // Only slice on valid slice indices
          slicer.visible = false;
        }
      } else {
        // Only visualize the slice on sliceable objects
        slicer.visible = false;
      }
    } else {
      // Only visualize the slice when an object is selected
      slicer.visible = false;
    }
  }

  updateStenosis(stenosis: any) {
    if (!this.groupMeshes) return;
    for (const key in stenosis) {
      const mesh = this.groupMeshes.getObjectByName(key);
      if (!mesh) return;
      mesh.stenosis = stenosis[key];
      let stenosistexture = mesh.geometry.getAttribute('stenosistexture');
      let nverts = mesh.geometry.getAttribute('position').count;
      for (let i = 0; i < nverts; i++) {
        let slice = mesh.v2s[i];
        let value = mesh.slice_cmap[slice];
        let colAtValue = new THREE.Color(VESSEL_STENOSIS_COLOR[value]);
        if (colAtValue !== null) {
          stenosistexture.setXYZ(i, colAtValue.r, colAtValue.g, colAtValue.b);
        }
      }
    }
    this.changeVisuals('stenosis');
  }

  async reloadVesselData() {
    if (!this.props.contrastLesionData || !this.props.vesselData) return;

    // retrieve vessel axes again
    const vesselAxes = await api.getJSON(
      `/data/${this.props.patientID}/${this.props.runID}/vessel/${this.props.vesselID}/mpr/axes/all`
    );
    // Check the component is still mounted.
    if (!this.mounted) {
      return;
    }

    // Check the vessel axes loaded ok before trying access them.
    if (vesselAxes?.view_idx) {
      Object.keys(vesselAxes.view_idx).forEach((key) => {
        this.vesselDirections[parseInt(key)] = vesselAxes.view_idx[key];
      });
    }

    // Removing old markers
    this.groupVulnerablePlaques.children
      .filter((m: THREE.Group) => m.name === `vp_group__${this.props.vesselID}`)
      .forEach((marker: THREE.Group) => {
        this.groupVulnerablePlaques.remove(marker);
      });

    const vesselCenterline = this.props.vesselData[this.props.vesselID]
      ?.centerline;
    const vesselLesions = this.props.contrastLesionData[this.props.vesselID];
    const sliceLesionMapping = this.props.vesselData[this.props.vesselID]
      ?.slice_to_lesion_mapping;
    const markerPoints = this.getMarkerPoints(
      vesselCenterline,
      vesselLesions,
      sliceLesionMapping
    );

    // Adding new ones
    if (markerPoints) {
      this.renderVulnerablePlaque(
        markerPoints as MarkerPoints,
        this.props.vesselID
      );
    }
    // This currently recalculates for all vessels ... we might be able to make this specific to the newly reloaded vessel - at least if vessel geometries haven't changed.
    this.precomputeVPMarkerPositions();

    // updating vessel slice map for stenosis per lesion
    const vesselMesh = this.groupMeshes.children.find(
      (v: THREE.Mesh) => v.name === `${this.props.vesselID}`
    );

    if (vesselMesh) {
      const position = vesselMesh.geometry.getAttribute('position');
      let nverts = position.count;

      let stenosistexture = vesselMesh.geometry.getAttribute('stenosistexture');
      for (var i = 0; i < nverts; i++) {
        // Set the i'th vertex to a given RGB based on the stenosis color at
        // that pointAxesHelper
        let slice = vesselMesh.v2s[i];

        let value = this.props.vesselData[this.props.vesselID].slice_cmap[
          slice
        ];
        let colAtValue = new THREE.Color(VESSEL_STENOSIS_COLOR[value]);
        if (colAtValue !== null) {
          stenosistexture.setXYZ(i, colAtValue.r, colAtValue.g, colAtValue.b);
        }
      }
      this.changeVisuals('stenosis');
    }
  }

  update(loop: boolean = true) {
    // When the component unmounts we stop this update loop.
    if (this.mounted !== true || this.renderer == null) {
      return;
    }

    // Uses current time by default.
    TWEEN.update();

    // Choose the best position for the VP markers.
    this.moveVPMarkers();

    // Update the VP markers so they are facing the camera.
    this.orientateVPMarkers();

    // Call update helpers for specific tasks.
    this.updateSliceVisualizer();

    // Take control update and redraw the render.
    this.controls.update();

    // Draw projection to screen buffer and swap.
    this.renderer.render(this.scene, this.camera);

    if (loop) {
      // Loop once complete
      // TODO: This will keep re-rendering the 3D model even when it's not doing anything. In the future we should kick off
      // this render loop off when something changes and stop it when TWEEN.update() return false.
      window.requestAnimationFrame(() => this.update());
    }
  }

  shouldComponentUpdate(nextProps: Model3dProps) {
    if (
      this.props.resetCamera !== nextProps.resetCamera &&
      nextProps.resetCamera
    ) {
      this.changeVisuals('toggle_vulnerable_plaque');
      this.changeVisuals('toggle_plaque');
      this.focusOn(this.props.priorityVesselName);
      this.resetZoom(true);
    }

    if (nextProps.stenosis !== this.props.stenosis) {
      this.updateStenosis(nextProps.stenosis);
    }

    if (nextProps.sliceidx !== this.props.sliceidx) {
      this.updateSliceVisualizer(nextProps.sliceidx);
    }

    if (this.props.showReport !== nextProps.showReport) {
      setTimeout(this.onWindowResize, 0);
    }

    if (this.props.contrastLesionData !== nextProps.contrastLesionData) {
      this.reloadVesselData();
    }

    return true;
  }

  componentDidUpdate(prevProps: Model3dProps) {
    // Only swap to a new view if we're out of the loading screen and the vessel
    // ID is new and the vessel has not been selected on the 3d model
    if (this.props.vesselID && prevProps.vesselID !== this.props.vesselID) {
      this.focusOn(this.props.vesselID);
    }

    // Button interactivity
    if (this.props.showPlaque !== prevProps.showPlaque) {
      this.changeVisuals('toggle_plaque');
    }
    if (this.props.showVP !== prevProps.showVP) {
      this.changeVisuals('toggle_vulnerable_plaque');
    }

    // When switching between tabs back to patient view call onWindowResize
    // to ensure model is rendered
    if (
      this.state.loaded &&
      prevProps.openTab !== this.props.openTab &&
      this.props.openTab
    ) {
      this.onWindowResize();
    }
    // If we have newly updated 3D model geometry for a vessel add it now.
    if (
      prevProps.model3dData !== this.props.model3dData &&
      this.props.model3dData
    ) {
      this.addVessel(this.props.model3dData);
    }

    // Updated plaque opacity
    if (prevProps.plaqueOpacity !== this.props.plaqueOpacity) {
      this.changeVisuals('plaque_opacity');
    }
  }

  render() {
    return (
      <div className="model-3d" ref={(ref) => (this.mount = ref)}>
        {!this.state.loaded ? <Loader large /> : null}
      </div>
    );
  }
}

export default Model3d;
