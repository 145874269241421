import isEqual from 'lodash/isEqual';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  DEFAULT_WINDOW,
  PRESET_MAP,
  WINDOW_LEVEL_DEFAULT,
  WINDOW_LEVEL_MAX,
  WINDOW_LEVEL_MIN,
  WINDOW_WIDTH_DEFAULT,
  WINDOW_WIDTH_MAX,
  WINDOW_WIDTH_MIN,
} from '../config';
import { clampNumber } from '../utils/shared';
import { WindowContextValue } from './types';
import { LabelledWindowLevels, WindowLevels } from './window-types';

const WindowContext = React.createContext<WindowContextValue | undefined>(
  undefined
);
WindowContext.displayName = 'WindowContext';

interface Props {
  children: ReactNode;
}

export function WindowProvider({ children }: Props): ReactElement<Props> {
  const [contrastWindowLabel, setContrastWindowLabel] = useState<string | null>(
    null
  );
  const [contrastWindowLevels, _setContrastWindowLevels] = useState<
    WindowLevels
  >(DEFAULT_WINDOW);
  const [nonContrastWindowLabel, setNonContrastWindowLabel] = useState<
    string | null
  >(null);
  const [nonContrastWindowLevels, _setNonContrastWindowLevels] = useState<
    WindowLevels
  >(DEFAULT_WINDOW);

  const setContrastWindowLevels = useCallback((x: WindowLevels) => {
    const newWindowLevels: WindowLevels = {
      windowCenter: clampNumber(
        x ? x.windowCenter : WINDOW_LEVEL_DEFAULT,
        WINDOW_LEVEL_MIN,
        WINDOW_LEVEL_MAX
      ),
      windowWidth: clampNumber(
        x ? x.windowWidth : WINDOW_WIDTH_DEFAULT,
        WINDOW_WIDTH_MIN,
        WINDOW_WIDTH_MAX
      ),
    };
    _setContrastWindowLevels(newWindowLevels);
  }, []);

  const setNonContrastWindowLevels = useCallback((x: WindowLevels) => {
    const newWindowLevels: WindowLevels = {
      windowCenter: clampNumber(
        x ? x.windowCenter : WINDOW_LEVEL_DEFAULT,
        WINDOW_LEVEL_MIN,
        WINDOW_LEVEL_MAX
      ),
      windowWidth: clampNumber(
        x ? x.windowWidth : WINDOW_WIDTH_DEFAULT,
        WINDOW_WIDTH_MIN,
        WINDOW_WIDTH_MAX
      ),
    };
    _setNonContrastWindowLevels(newWindowLevels);
  }, []);

  const resetWindowLevels = useCallback(() => {
    setContrastWindowLevels(DEFAULT_WINDOW);
    setNonContrastWindowLevels(DEFAULT_WINDOW);
  }, [setContrastWindowLevels, setNonContrastWindowLevels]);

  useEffect(() => {
    const keypressCallback = (e: KeyboardEvent) => {
      // Check if the user focused on an input field, if so do not set window levels with PRESETS
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLSelectElement
      )
        return;
      const newLevel = PRESET_MAP[e.code]?.window || null;
      if (newLevel !== null) {
        setContrastWindowLevels(newLevel);
      }
    };
    document.addEventListener('keypress', keypressCallback);
    return () => document.removeEventListener('keypress', keypressCallback);
  }, [setContrastWindowLevels]);

  useEffect(() => {
    let newLabel = null;
    const matchingWindowLevels = Object.values(PRESET_MAP).find(
      (windowLevelsConfig: LabelledWindowLevels) => {
        return isEqual(contrastWindowLevels, windowLevelsConfig.window);
      }
    );
    if (matchingWindowLevels) {
      newLabel = matchingWindowLevels.label;
    }
    setContrastWindowLabel(newLabel);
  }, [contrastWindowLevels]);

  useEffect(() => {
    let newLabel = null;
    const matchingWindowLevels = Object.values(PRESET_MAP).find(
      (windowLevelsConfig: LabelledWindowLevels) => {
        return isEqual(nonContrastWindowLevels, windowLevelsConfig.window);
      }
    );
    if (matchingWindowLevels) {
      newLabel = matchingWindowLevels.label;
    }
    setNonContrastWindowLabel(newLabel);
  }, [nonContrastWindowLevels]);

  const windowContext: WindowContextValue = {
    contrastWindowLabel,
    contrastWindowLevels,
    setContrastWindowLevels,
    nonContrastWindowLabel,
    nonContrastWindowLevels,
    setNonContrastWindowLevels,
    resetWindowLevels,
  };

  return (
    <WindowContext.Provider value={windowContext}>
      {children}
    </WindowContext.Provider>
  );
}

export function useWindowContext(): WindowContextValue {
  const context = useContext(WindowContext);

  if (context === undefined) {
    throw new Error('useWindowContext must be used within an WindowProvider.');
  }
  return context;
}
