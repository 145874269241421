import Auth from '@aws-amplify/auth';
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { ReactComponent as SignOutIcon } from '../../assets/icons/sign-out.svg';
import { ReactComponent as ArtryaLogo } from '../../assets/logos/SalixLogoReverse.svg';
import ReportAmendmentModal from '../../components/ReportAmendmentModal';
import {
  APP_VERSION_NUMBER,
  ASSET_STORAGE,
  NAV_TABS,
  USER_MANUAL_FILENAME,
  FFR_MANUAL_FILENAME,
  MEASUREMENT_MANUAL_FILENAME,
} from '../../config';
import { InlineReportingActions } from '../../context/inlineReporting/actions';
import { useInlineReportingContext } from '../../context/inlineReporting/context';
import { UnsavedChangesToInlineReport } from '../../context/inlineReporting/types';
import { useStoreContext } from '../../context/store-context';
import { DashboardDataSetResponse } from '../../context/types';
import { useUserContext } from '../../context/user-context';
import { WorkflowActions } from '../../context/workflow/actions';
import { useWorkflowContext } from '../../context/workflow/context';
import { useAnyInlineReportingChangesMade } from '../../selectors/reporting';
import { useStudySeriesInfo, SeriesStatus } from '../../selectors/study';
import { useIsAuditorUserSelector } from '../../selectors/user';
import * as auth from '../../utils/auth';
import { ActionModal } from '../ActionModal/ActionModal';
import InlineReportWarning from '../InlineReportingWarning';
import Select from '../Select/Select';

const ACCOUNT_OPTIONS = {
  settings: 'settings',
  auditlog: 'auditlog',
  userManual: 'usermanual',
  ffrManual: 'ffrmanual',
  measurementManual: 'measurementmanual',
  signout: 'signout',
  disabled: 'disabled',
};

const NavBar: React.FC = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const {
    visibleTab,
    setVisibleTab,
    initialDataLoaded,
    editingReport,
    dashboardData,
    clearDraftReport,
    setEditingReport,
    setEditingImpressions,
    setEditingCoronaryFindings,
  } = useStoreContext();

  const { clientConfig } = useUserContext();

  const {
    dispatch,
    state: { studyReviewCount },
  } = useWorkflowContext();

  const {
    dispatch: dispatchInlineReportingAction,
  } = useInlineReportingContext();

  const [pageTitleText, setPageTitleText] = useState('Dashboard');
  const [auditorLogin, setAuditorLogin] = useState(false);

  const [modalDetails, setModalDetails] = useState<
    { showModal: boolean; navigationAction: () => void } | undefined
  >();

  const onDashboard = location.pathname === '/';
  const onError = location.pathname === '/error';
  const onPatientPage = location.pathname.includes('/study');
  const subMenuOptions = useRef<
    { label: React.ReactNode; value: string; isDisabled?: boolean }[]
  >([]);

  useMemo(() => {
    subMenuOptions.current = [
      {
        label: (
          <>
            <span className="option-icon">
              <HelpIcon />
            </span>
            User manual
          </>
        ),
        value: ACCOUNT_OPTIONS.userManual,
      },
      {
        label: (
          <>
            <span className="option-icon">
              <SignOutIcon />
            </span>
            Sign out
          </>
        ),
        value: ACCOUNT_OPTIONS.signout,
      },
      {
        label: (
          <>
            <span>
              <div>Salix version {APP_VERSION_NUMBER}</div>
              <div>Copyright &copy; {new Date().getFullYear()} Artrya</div>
            </span>
          </>
        ),
        value: ACCOUNT_OPTIONS.disabled,
        isDisabled: true,
      },
    ];

    if (clientConfig) {
      // Inject the FFR Manual menu item into the dropdown if user has FFR enabled
      if (clientConfig.ffr_enabled) {
        const ffrManualMenuItem = {
          label: (
            <>
              <span className="option-icon">
                <HelpIcon />
              </span>
              FFR manual
            </>
          ),
          value: ACCOUNT_OPTIONS.ffrManual,
        };
        subMenuOptions.current.splice(1, 0, ffrManualMenuItem);
      }

      // Inject the Measurement Manual menu item into the dropdown if user has Measurement enabled
      if (clientConfig.measurement_enabled) {
        const measurementManualMenuItem = {
          label: (
            <>
              <span className="option-icon">
                <HelpIcon />
              </span>
              Measurement manual
            </>
          ),
          value: ACCOUNT_OPTIONS.measurementManual,
        };
        subMenuOptions.current.splice(2, 0, measurementManualMenuItem);
      }
    }
  }, [clientConfig]);

  const setStudyReviewCount = useCallback(
    (reviewCount: number) => {
      dispatch(WorkflowActions.setStudyReviewCount(reviewCount));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!dashboardData) return;
    const myReviewCount = Object.entries(
      dashboardData as DashboardDataSetResponse
    ).filter(
      ([key, s]) =>
        !!s.report_active_review &&
        s.report_active_review.assignee.email === user.email
    ).length;
    setStudyReviewCount(myReviewCount);
  }, [dashboardData, user, setStudyReviewCount]);

  const handleAccountChange = useCallback(
    async (value) => {
      if (value === ACCOUNT_OPTIONS.auditlog) history.push('/auditlog');
      if (value === ACCOUNT_OPTIONS.userManual)
        window.open(`${ASSET_STORAGE}/${USER_MANUAL_FILENAME}`, '_blank');
      if (value === ACCOUNT_OPTIONS.ffrManual)
        window.open(`${ASSET_STORAGE}/${FFR_MANUAL_FILENAME}`, '_blank');
      if (value === ACCOUNT_OPTIONS.measurementManual)
        window.open(
          `${ASSET_STORAGE}/${MEASUREMENT_MANUAL_FILENAME}`,
          '_blank'
        );
      if (value === ACCOUNT_OPTIONS.signout) {
        await Auth.signOut();
        auth.clearTokens();
        history.push('/');
      }
    },
    [history]
  );

  const returnToDashboard = useCallback(async () => {
    history.push('/');
  }, [history]);

  const anyInlineReportingChangesMade = useAnyInlineReportingChangesMade();

  const checkBeforeNavigatingAway = useCallback(
    (
      navigationAction: () => void,
      shouldCheckInlineReporting: boolean = false
    ) => {
      // Only raise modal if editing report and navigating away from the report overview
      if (editingReport) {
        setModalDetails({ showModal: true, navigationAction });
      } else if (shouldCheckInlineReporting && anyInlineReportingChangesMade) {
        dispatchInlineReportingAction(
          InlineReportingActions.setUnsavedChangesWarning({
            type: UnsavedChangesToInlineReport.LeavingStudy,
            passthroughAction: navigationAction,
          })
        );
      } else {
        navigationAction();
      }
    },
    [
      editingReport,
      setModalDetails,
      anyInlineReportingChangesMade,
      dispatchInlineReportingAction,
    ]
  );

  const isAuditorUser = useIsAuditorUserSelector();

  useEffect(() => {
    // Add audit log menu item if user is part of the artrya group
    // TODO change check to be 'artrya' group (i.e. AUTH_USER_GROUP.artrya)
    if (isAuditorUser) {
      setPageTitleText('Study Audit Logs');
      setAuditorLogin(true);
    } else {
      setAuditorLogin(false);
    }
  }, [isAuditorUser, setPageTitleText, setAuditorLogin]);

  const studySeriesInfo = useStudySeriesInfo();
  const showPatientOverview = useMemo(() => {
    return (
      studySeriesInfo?.contrast !== SeriesStatus.Fail &&
      studySeriesInfo?.contrast !== SeriesStatus.NotAvailable
    );
  }, [studySeriesInfo]);
  const showReviewReport = useMemo(() => {
    return studySeriesInfo?.contrast !== SeriesStatus.NotAvailable;
  }, [studySeriesInfo]);

  const onConfirmModal = useCallback(() => {
    modalDetails?.navigationAction();
    setModalDetails(undefined);
    setEditingReport(false);
    setEditingImpressions(false);
    setEditingCoronaryFindings(false);
    clearDraftReport();
  }, [
    modalDetails,
    setModalDetails,
    setEditingReport,
    setEditingImpressions,
    setEditingCoronaryFindings,
    clearDraftReport,
  ]);

  const onCloseModal = useCallback(() => {
    setModalDetails(undefined);
  }, [setModalDetails]);

  return (
    <div className={'nav-bar'}>
      <div className="nav-bar__spacer" />
      <div className="nav-bar__container">
        <div className="nav-bar__left">
          {onDashboard || auditorLogin ? (
            <>
              <div className="nav-bar__logo">
                <ArtryaLogo />
              </div>
              <span className="nav-bar__back">
                {pageTitleText}
                {onDashboard && studyReviewCount > 0 && (
                  <span className="nav-bar__notification">
                    {studyReviewCount}
                  </span>
                )}
              </span>
            </>
          ) : (
            !onError && (
              <button
                className="nav-bar__back"
                type="button"
                onClick={() =>
                  checkBeforeNavigatingAway(returnToDashboard, true)
                }
              >
                <ChevronIcon /> Back to Dashboard
                {studyReviewCount > 0 && (
                  <span className="nav-bar__notification">
                    {studyReviewCount}
                  </span>
                )}
              </button>
            )
          )}
        </div>

        <div className="nav-bar__center">
          {onPatientPage && initialDataLoaded && (
            <>
              {showPatientOverview && (
                <button
                  className={cn('nav-bar__tab', {
                    'nav-bar__tab--active': visibleTab === NAV_TABS.patientTab,
                  })}
                  type="button"
                  onClick={() =>
                    checkBeforeNavigatingAway(() =>
                      setVisibleTab(NAV_TABS.patientTab)
                    )
                  }
                >
                  Patient Overview
                </button>
              )}
              <button
                className={cn('nav-bar__tab', {
                  'nav-bar__tab--active': visibleTab === NAV_TABS.ctVolumeTab,
                })}
                type="button"
                onClick={() =>
                  checkBeforeNavigatingAway(() =>
                    setVisibleTab(NAV_TABS.ctVolumeTab)
                  )
                }
              >
                CT Volume
              </button>
              {showReviewReport && (
                <button
                  className={cn('nav-bar__tab', {
                    'nav-bar__tab--active':
                      visibleTab === NAV_TABS.reportReviewTab,
                  })}
                  type="button"
                  onClick={() => setVisibleTab(NAV_TABS.reportReviewTab)}
                >
                  Review Report
                </button>
              )}
            </>
          )}
        </div>

        <div className="nav-bar__right">
          <span className="nav-bar__username">{user.name || 'Account'}</span>
          <Select
            theme="alternative"
            options={subMenuOptions.current}
            onChange={handleAccountChange}
          />
        </div>
        <ActionModal
          confirmText="Discard Changes"
          closeText="Cancel"
          onConfirm={onConfirmModal}
          onClose={onCloseModal}
          visible={!!modalDetails?.showModal}
          headerContent={<>Leaving Report?</>}
        >
          <p>
            Are you sure you want to leave the report? Your unsaved changes will
            be discarded.
          </p>
        </ActionModal>
        <InlineReportWarning />
        <ReportAmendmentModal />
      </div>
    </div>
  );
};

export default NavBar;
