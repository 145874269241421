import './initCornerstone.js';
import { getImageData, loadImageData } from '../ReactVTKJS';
import { vtkApi } from '../ReactVTKJS/ReactVTKJSTypes';
import {
  ContrastViewType,
  ContrastVolume,
  CrosshairValues,
} from '../../../context/contrast-types';
import { WindowLevels } from '../../../context/window-types';
import AWS from 'aws-sdk';
import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder';

/**
 * Given the viewType get the index of the axis cooresponding to it's default viewUp vector.
 * If the value is negative it means the axis vector must be inverted.
 */
export function getViewUpAxis(viewType: ContrastViewType): number {
  switch (viewType) {
    // Axial View
    case 0:
      return -2;
    // Sagittal View
    case 1:
      return 0;
    // Coronal View.
    case 2:
      return 0;
    // Range error.
    default:
      console.error('getViewUpAxis: range error');
      return 0;
  }
}

/**
 * Get the descriptive name for a viewType
 */
export function getContrastViewTypeName(viewType: ContrastViewType) {
  switch (viewType) {
    case ContrastViewType.Axial:
      return 'Axial';
    case ContrastViewType.Sagittal:
      return 'Sagittal';
    case ContrastViewType.Coronal:
      return 'Coronal';
    case ContrastViewType.MPR:
      return 'MPR';
    default:
      return '';
  }
}

/**
 * Load the image from s3.
 */
export function s3Loader(uri: string) {
  const s3 = new AWS.S3();
  const parts = uri.split('/');
  const bucket = parts.slice(0, 2).join('/');
  const key = parts.slice(2).join('/');
  return new Promise((resolve, reject) => {
    s3.getObject({ Bucket: bucket, Key: key }, (err, data) => {
      if (err != null) {
        reject(err);
      } else {
        resolve(data.Body);
      }
    });
  });
}

/**
 * Use the array of image ids to construct the data object.
 */
export function loadDataset(imageIds: string[], displaySetInstanceUid: string) {
  try {
    const imageDataObject = getImageData(imageIds, displaySetInstanceUid);
    loadImageData(imageDataObject);
    return imageDataObject;
  } catch (err) {
    console.error('Error loading contrast CT volume', err);
    return undefined;
  }
}

/**
 * Calculate the rgbTransferFunction range from the window levels.
 */
export function windowLevelsToRange(windowLevels: WindowLevels): number[] {
  return [
    windowLevels.windowCenter - windowLevels.windowWidth / 2,
    windowLevels.windowCenter + windowLevels.windowWidth / 2,
  ];
}

/**
 * Calculate the window levels from a rgbTransferFunction range.
 */
export function rangeToWindowLevels(range: number[] | undefined): WindowLevels {
  if (!range) return { windowWidth: 0, windowCenter: 0 };
  const windowWidth = Math.abs(range[1] - range[0]);
  const windowCenter = range[0] + (range[1] - range[0]) / 2;
  return { windowWidth, windowCenter };
}

/**
 * Convert from a view type to the axis the slices of that view are aligned with.
 */
export function viewTypeToAxis(viewType: ContrastViewType) {
  switch (viewType) {
    case ContrastViewType.Axial:
      return 2;
    case ContrastViewType.Sagittal:
      return 0;
    case ContrastViewType.Coronal:
      return 1;
    default:
      break;
  }
  console.error('viewTypeToAxis called with invalid viewType', viewType);
  return 2;
}

/**
 * Translate the camera so that it keeps its orientation but is centered on the current
 * crosshair position. Refresh the view and crosshairs.
 */
export function centerViewOnCrosshairs(
  api: vtkApi,
  contrastVolume: ContrastVolume
) {
  const { crosshairWorldPosition } = contrastVolume;

  // Get the view's camera.
  const camera = api.genericRenderWindow.getRenderer().getActiveCamera();

  // NOTE: We used to center the view on the crosshairs by explicitly calling setFocalPoint
  // and setPosition on the camera. This 'should' have been the most concise solution and
  // 'shouldn't' of had any issues. Unfortunately it resulted in SALIX-880 for reasons that
  // are still unknown. Using a matrix builder to create a matrix transform that should
  // result in the exact same changes seems to keep vtk happy so we now do it this way.
  const prevFocalPoint = camera.getFocalPoint();
  const matrix = vtkMatrixBuilder
    .buildFromRadian()
    .translate(
      crosshairWorldPosition[0] - prevFocalPoint[0],
      crosshairWorldPosition[1] - prevFocalPoint[1],
      crosshairWorldPosition[2] - prevFocalPoint[2]
    )
    .getMatrix();
  camera.applyTransform(matrix);

  // Refresh the view and crosshairs.
  api.refreshViewImmediately(true, true);
}

/**
 * A simple function to round the number and clamp it to the min-max range.
 */
const roundAndClamp = (num: number, min: number, max: number) => {
  return num <= min ? min : num >= max ? max : Math.round(num);
};

/**
 * Get the HU value and the crosshair position for the current crosshair's world position.
 * { huValue: number, crosshairPos: [X, Y, Z] }
 */
export function getCrosshairValues(
  contrastVolume: ContrastVolume
): CrosshairValues {
  const worldPos = [...contrastVolume.crosshairWorldPosition];
  if (contrastVolume.volume && worldPos) {
    const mapper = contrastVolume.volume.getVolumes().getMapper();
    const inputData = mapper.getInputData();
    const pointData = inputData.getPointData();
    const scalarData = pointData.getScalars().getData();
    const volumeDimensions = inputData.getDimensions();

    // Convert the position from world space to the volume space.
    let indexPos = [0, 0, 0];
    inputData.worldToIndex(worldPos, indexPos);

    // Round and clamp the position.
    indexPos[0] = roundAndClamp(indexPos[0], 0, volumeDimensions[0]);
    indexPos[1] = roundAndClamp(indexPos[1], 0, volumeDimensions[1]);
    indexPos[2] = roundAndClamp(indexPos[2], 0, volumeDimensions[2]);

    // Convert the volume position to a volume index.
    const index =
      indexPos[0] +
      indexPos[1] * volumeDimensions[0] +
      indexPos[2] * volumeDimensions[0] * volumeDimensions[1];
    return {
      huValue: scalarData[index],
      crosshairPos: indexPos,
    };
  }
  return {};
}
