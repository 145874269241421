import LVMassIndex from '../../components/LVMassIndex/LVMassIndex';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import Stats from '../../components/Stats/Stats';
import Toggle from '../../components/Toggle/Toggle';
import { STENOSIS_RISK_LEVEL_MAPPING, TIMEDATE_FORMAT } from '../../config';
import { useStoreContext } from '../../context/store-context';
import { useUserContext } from '../../context/user-context';
import OverallMeasurements from '../../components/OverallMeasurements/OverallMeasurements';
import { useAppSelector } from '../../hooks';
import { currentStudySelector } from '../../selectors/study';

export interface PatientSummaryProps {
  loading?: boolean;
}

export const PatientSummary: React.FC<PatientSummaryProps> = ({
  loading = false,
}) => {
  const selectedStudy = useAppSelector(currentStudySelector);
  const {
    patientStats,
    currentReport,
    displayMeasurements,
    setDisplayMeasurements,
  } = useStoreContext();

  const { clientConfig } = useUserContext();
  const [stats, setStats] = useState([]) as any;

  useEffect(() => {
    const {
      calcium_score: calciumScore,
      maximum_stenosis: maxStenosis,
      priority_vessel: priorityVessel,
      cad_rads: cadRads,
      sis,
    } = patientStats || {};

    let riskLevel = null;
    if (maxStenosis) {
      riskLevel = STENOSIS_RISK_LEVEL_MAPPING[maxStenosis.split(' ')[0]];
    }

    let priorityVesselVal = priorityVessel;
    // check for no disease, where there should be no priorityVessel
    if (!calciumScore && cadRads?.match(/^0(\/.*|$)/)) {
      priorityVesselVal = undefined;
    }

    setStats([
      {
        label: 'Calcium Score (MESA)',
        value: Math.round(parseFloat(`${calciumScore}`)),
        subtext: currentReport?.mesa_percentile
          ? `(${currentReport?.mesa_percentile}%)`
          : '',
        show: true,
      },
      {
        label: 'Maximum Stenosis',
        value: maxStenosis,
        riskLevel,
        show: true,
      },
      {
        label: 'Priority Vessel',
        value: priorityVesselVal ?? 'N/A',
        show: true,
      },
      {
        label: 'Vulnerable Plaque',
        value: (currentReport?.vulnerable_plaque ?? '') || 'None',
        show: true,
      },
      {
        label: 'Segment Involvement Score',
        value: sis,
        show: true,
      },
      {
        label: 'CAD-RADS',
        value: cadRads,
        show: true,
      },
    ]);
  }, [
    displayMeasurements,
    patientStats,
    currentReport?.vulnerable_plaque,
    currentReport?.mesa_percentile,
  ]);

  function getScanDate() {
    /**
     * Function to fetch the scan date if it exists, otherwise render the study date as a fallback
     *
     * @return a formated time date string
     */
    if (selectedStudy) {
      // This is our fallback study date that comes from the ingest date
      const studyDateFallback = Moment(selectedStudy.study_date).format(
        TIMEDATE_FORMAT
      );

      if (selectedStudy.patient_plaintext) {
        // If the study has the patient data required, convert the string to JSON
        const stringReplace = selectedStudy.patient_plaintext.replace(
          /'/g,
          '"'
        );
        const patientJSON = JSON.parse(stringReplace);
        // If the JSON both a StudyTime and a valid Moment StudyDate in the expected format (YYYYMMDD)
        // combine the two and return as a formated time date string
        if (
          patientJSON.StudyTime &&
          Moment(patientJSON.StudyDate, 'YYYYMMDD').isValid()
        ) {
          const studyDateTime = patientJSON.StudyDate + patientJSON.StudyTime;
          return Moment(studyDateTime, 'YYYYMMDDhhmmss').format(
            TIMEDATE_FORMAT
          );
        }
      }
      return studyDateFallback;
    }
    return;
  }

  return (
    <>
      {selectedStudy && (
        <div className="card">
          {clientConfig?.measurement_enabled && (
            <div className="disclaimer-text disclaimer-text_patient">
              Measurement display and ruler tools for research purposes only.
            </div>
          )}
          <div className="card__header">
            <div className="card__title">
              {selectedStudy.patient_plaintext
                ? `${selectedStudy.patient_name}`
                : `${selectedStudy.study_id}`}
              's Overview
            </div>

            {clientConfig?.measurement_enabled && (
              <Toggle
                name="measurement"
                label="Measurements"
                checked={displayMeasurements}
                onChange={() => {
                  setDisplayMeasurements(!displayMeasurements);
                }}
              ></Toggle>
            )}

            {loading && <Loader inline />}
          </div>

          <div className="card__title-patient">Scan Date: {getScanDate()}</div>
          <Stats data={stats} />
          {displayMeasurements && (
            <>
              <OverallMeasurements />
              <LVMassIndex />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PatientSummary;
