import { useCallback, useMemo } from 'react';
import { useStoreContext } from '../context/store-context';
import { useVesselDataContext } from '../context/vessel-data-context';
import { VesselDataActions, VesselDataResponse } from '../reducers/vessel-data';

export function useVesselStateSelector() {
  const { state, dispatch } = useVesselDataContext();
  const vessels = useVesselsSelector();
  const selectedVesselData = useSelectedVesselDataSelector();
  const savingSelectedVessel = useSavingSelectedVesselSelector();
  const selectedVesselName = useSelectedVesselNameSelector();
  const selectedVesselViewerData = useSelectedVesselViewerDataSelector();

  return {
    ...state.selectedVesselData,
    vesselData: state.vesselData,
    vesselViewerData: state.vesselViewerData,
    ctNonContrastViewerData: state.ctNonContrastViewerData,
    vessels,
    selectedVesselData,
    selectedVesselViewerData,
    savingSelectedVessel,
    selectedVesselName,
    dispatch,
  };
}

export function useSetVesselDataSelector() {
  const { dispatch } = useVesselDataContext();

  return useCallback(
    (vesselData: VesselDataResponse) => {
      dispatch(VesselDataActions.addVesselData(vesselData));
    },
    [dispatch]
  );
}

/**
 * Used when selecting a Vessel
 * on initial load
 * on reset to priorityVessel
 * on vessel switcher
 * on clicking a vessel other than the currently selected one in 3d/CPR
 */
export function useSetSelectedVesselSelector() {
  const {
    state: { vesselData },
    dispatch,
  } = useVesselDataContext();

  /**
   * Set the selected vessel by name, optionally set the slice index (index of the point along the centerline).
   * If adding a centerline we need to pass in nSlices because the vesselData will not yet be updated in the store (even though we have just set the new vesselData).
   * @param nSlices Normally leaving this as the default 0 is fine because we can get the number from the vesselData
   *        but if we just added the vessel this won't be up to date yet and the value needs to be passed in.
   */
  return useCallback(
    (
      newSelectedVesselName?: string,
      newHighSliceIndex?: number,
      newMidSliceIndex: number = 10,
      newLowSliceIndex?: number,
      nSlices: number = 0
    ) => {
      // Get the number of slices for the vessel (if this value was not passed in).
      if (
        nSlices <= 0 &&
        vesselData &&
        newSelectedVesselName &&
        vesselData[newSelectedVesselName]
      ) {
        nSlices =
          vesselData &&
          newSelectedVesselName &&
          vesselData[newSelectedVesselName]
            ? vesselData[newSelectedVesselName].n_slices
            : 10;
      }

      dispatch(VesselDataActions.selectVessel(newSelectedVesselName));

      // Limit the new slice index to the valid range of slices.
      newMidSliceIndex = Math.min(Math.max(newMidSliceIndex, 0), nSlices - 1);

      // Setting high slice index (ie the one rendered at the top and with the lowest value).
      if (newHighSliceIndex === undefined) {
        newHighSliceIndex = newMidSliceIndex - 10;
      } else {
        newHighSliceIndex = Math.min(newHighSliceIndex, newMidSliceIndex);
      }

      // Setting low slice index (ie the one rendered at the bottom and with the highest value).
      // If its greater than the number of slices then set it to the last slice index.
      if (newLowSliceIndex === undefined) {
        newLowSliceIndex = newMidSliceIndex + 10;
      } else {
        newHighSliceIndex = Math.max(newMidSliceIndex, newMidSliceIndex);
      }
      dispatch(
        VesselDataActions.updateSliceIndices({
          mid: newMidSliceIndex,
          high: Math.max(newHighSliceIndex, 0),
          low: Math.min(newLowSliceIndex, nSlices - 1),
        })
      );
    },
    [dispatch, vesselData]
  );
}

export function useSetSliceIndicesSelector() {
  const setMidSliceIndex = useSetMidSliceIndexSelector();
  const setHighSliceIndex = useSetHighSliceIndexSelector();
  const setLowSliceIndex = useSetLowSliceIndexSelector();

  return {
    setMidSliceIndex,
    setHighSliceIndex,
    setLowSliceIndex,
  };
}

export function usePriorityVesselSelector() {
  const {
    state: {
      selectedVesselData: { priorityVessel },
    },
  } = useVesselDataContext();

  return priorityVessel;
}

/**
 * Resets to the priorty vessel and highest priority slice
 * Button exists on the patient screen
 */
export function useResetToPriorityVesselSelector() {
  const setSelectedVessel = useSetSelectedVesselSelector();
  const {
    state: {
      selectedVesselData: { priorityVessel, highestPriorityIdx },
    },
  } = useVesselDataContext();

  return useCallback(
    () =>
      setSelectedVessel(
        priorityVessel,
        undefined,
        highestPriorityIdx,
        undefined
      ),
    [setSelectedVessel, priorityVessel, highestPriorityIdx]
  );
}

/**
 * Used when going back to the dashboard to clear out vesselData/vesselViewerData
 * and reset slice handles to default positions.
 */
export function useClearDataSelector() {
  const { clearPatient } = useStoreContext();
  const { dispatch } = useVesselDataContext();

  return useCallback(() => {
    clearPatient();
    dispatch(VesselDataActions.clear());
  }, [clearPatient, dispatch]);
}

/**
 * Returns an ordered list of vessels to choose from.
 * Primarily used for the Vessel Switcher
 */
function useVesselsSelector() {
  const {
    state: { vesselData },
  } = useVesselDataContext();

  return useMemo(() => {
    return Object.keys(vesselData || {}).sort((a, b) => {
      if (a === 'lm') return -1;
      if (b === 'lm') return 1;
      if (a === 'lad') return -1;
      if (b === 'lad') return 1;
      if (a === 'lcx') return -1;
      if (b === 'lcx') return 1;
      if (a === 'rca') return -1;
      if (b === 'rca') return 1;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
  }, [vesselData]);
}

/**
 * Is the selected vessel currently saving on the backend? ie so can't be fetched.
 */
function useSavingSelectedVesselSelector(): boolean {
  const {
    state: {
      selectedVesselData: { name: selectedVesselName },
      savingVesselId,
    },
  } = useVesselDataContext();

  return useMemo(() => savingVesselId === selectedVesselName, [
    savingVesselId,
    selectedVesselName,
  ]);
}

/**
 * Used when moving the mid slice selector
 * on 3d Model
 * on CPR (curved)
 * on MPR
 * on Short Axis
 */
function useSetMidSliceIndexSelector() {
  const { dispatch } = useVesselDataContext();
  return useCallback(
    (mid: number) => dispatch(VesselDataActions.updateSliceIndices({ mid })),
    [dispatch]
  );
}

/**
 * Used when moving the low slice selector
 * on 3d Model
 * on CPR (curved)
 * on Short Axis
 */
function useSetLowSliceIndexSelector() {
  const { dispatch } = useVesselDataContext();

  return useCallback(
    (low: number) => dispatch(VesselDataActions.updateSliceIndices({ low })),
    [dispatch]
  );
}

/**
 * Used when moving the low slice selector
 * on 3d Model
 * on CPR (curved)
 * on Short Axis
 */
function useSetHighSliceIndexSelector() {
  const { dispatch } = useVesselDataContext();

  return useCallback(
    (high: number) => dispatch(VesselDataActions.updateSliceIndices({ high })),
    [dispatch]
  );
}

function useSelectedVesselNameSelector() {
  const {
    state: {
      selectedVesselData: { name },
    },
  } = useVesselDataContext();

  return name;
}

/**
 * This selector should be used anywhere the vesselData for the currently selected vessel is required.
 */
function useSelectedVesselDataSelector() {
  const {
    state: {
      vesselData,
      selectedVesselData: { name },
    },
  } = useVesselDataContext();

  return useMemo(() => {
    if (vesselData && name) {
      return vesselData[name];
    }

    return undefined;
  }, [vesselData, name]);
}

function useSelectedVesselViewerDataSelector() {
  const {
    state: {
      vesselViewerData,
      selectedVesselData: { name },
    },
  } = useVesselDataContext();

  return useMemo(() => {
    if (vesselViewerData && name) {
      return vesselViewerData[name];
    }

    return undefined;
  }, [vesselViewerData, name]);
}
