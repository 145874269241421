import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as FailedIcon } from '../../assets/icons/warning-filled.svg';
import AnnotationModal from '../../components/AnnotationModal/AnnotationModal';
import Confirm from '../../components/Confirm/Confirm';
import { CPRViewer } from '../../components/CPRViewer/CPRViewer';
import {
  onEditCenterlineFetchImages,
  onEditCenterlineV2Result,
} from '../../components/CPRViewer/Utils';
import { IconButton } from '../../components/IconButton/IconButton';
import MeasurementToolbar from '../../components/measurementToolbar/MeasurementToolbar';

import LoadingOverlay, {
  LoadingOverlayProps,
} from '../../components/LoadingOverlay/LoadingOverlay';
import { LongAxisMPRViewer } from '../../components/LongAxisMPRViewer/LongAxisMPRViewer';
import Select from '../../components/Select/Select';
import { ShortAxisMPRViewer } from '../../components/ShortAxisMPRViewer/ShortAxisMPRViewer';
import { TabButtons } from '../../components/TabButtons/TabButtons';
import { showToast } from '../../components/Toast/showToast';
import {
  CPR_SLICE_INDICATOR_BUFFER,
  KEY_CPR,
  KEY_MPR_LONG_AXIS,
  MOUSE_BUTTONS,
  THEME,
} from '../../config';
import { useCprContext } from '../../context/cpr-context';
import { useStoreContext } from '../../context/store-context';
import { EditModeActions } from '../../context/types';
import { useUserContext } from '../../context/user-context';
import { useWindowContext } from '../../context/window-context';
import { WindowLevels } from '../../context/window-types';
import { useAppSelector } from '../../hooks';
import useFfrData from '../../hooks/use-ffr-data';
import { VesselDataActions } from '../../reducers/vessel-data';
import { cprVersionSelector } from '../../selectors/study';
import {
  useSetSelectedVesselSelector,
  useSetSliceIndicesSelector,
  useVesselStateSelector,
} from '../../selectors/vessels';
import { LineArray } from '../../types';
import * as api from '../../utils/api';
import { timeFuncFactory } from '../../utils/shared';
import LesionInfo from './LesionInfo/LesionInfo';
import {
  EditCenterlineV2_request,
  EditCenterlineV2_result,
  EditCenterlineV2_result_save,
} from './types';

interface ConfirmingDialog {
  // We can show the confirm dialog for two reasons:
  // false: The user double clicked to stop editing the centerline.
  // true: The user tried to hide the centerline, if they accept we thus also need to hide the centerline.
  hideAnnosAfterConfirming: boolean;
}

export const VesselViewer: React.FC = () => {
  const {
    patientID,
    runID,
    editMode,
    dispatchEditModeAction,
    updateVersionHead,
    updateBackendVersionHead,
    versionHead,
    contrastLesionData,
  } = useStoreContext();

  const {
    midSliceIdx: sliceidx,
    highSliceIdx: highSliceidx,
    lowSliceIdx: lowSliceidx,
    vessels,
    savingSelectedVessel,
    selectedVesselName,
    selectedVesselViewerData,
    dispatch: dispatchVesselDataAction,
    selectedVesselData,
  } = useVesselStateSelector();

  const {
    setMidSliceIndex: setSliceidx,
    setHighSliceIndex: setHighSliceidx,
    setLowSliceIndex: setLowSliceidx,
  } = useSetSliceIndicesSelector();

  const setSelectedVesselName = useSetSelectedVesselSelector();

  const cprVersion = useAppSelector(cprVersionSelector);

  const {
    contrastWindowLevels,
    setContrastWindowLevels,
    contrastWindowLabel,
  } = useWindowContext();
  const { clientConfig } = useUserContext();
  const [currentLesionId, setCurrentLesionId] = useState<string | null>(null);
  const [confirming, setConfirming] = useState<ConfirmingDialog | undefined>(
    undefined
  );
  const [loadingOverlayProps, setLoadingOverlayProps] = useState<
    LoadingOverlayProps | undefined
  >();
  const [lesionidx, setLesionidx] = useState(0);
  const [lesionLength, setLesionLength] = useState(0);
  const [showFFRLabels, setShowFFRLabels] = useState(false);
  const [ffrenabled, setFfrenabled] = useState(true);
  const [ffrStatus, setFfrStatus] = useState('');
  const [ffrDetail, setFfrDetail] = useState('');
  const [initalLoadCPR, setInitialLoadCPR] = useState(true);
  const [initalLoadMPRLongAxis, setInitialLoadMPRLongAxis] = useState(false);
  const [activeLine, setActiveLine] = useState('');
  const mprScreenshotRef = useRef(null);
  const longAxialScreenshotRef = useRef(null);
  const axialScreenshotRef = useRef(null);
  const { cprSliceidx, selectedMPRView, setSelectedMPRView } = useCprContext();
  const addEditCentrelineCount = useRef(0);

  const [showAnnos, setShowAnnos] = useState(false);
  const highShortAxisRef = useRef<HTMLDivElement | null>(null);
  const midShortAxisRef = useRef<HTMLDivElement | null>(null);
  const lowShortAxisRef = useRef<HTMLDivElement | null>(null);

  const { data: ffrData, isError: ffrError } = useFfrData(
    patientID || '',
    ffrenabled && (clientConfig?.ffr_enabled || false)
  );

  useEffect(() => {
    // In the event of FFR fetch erroring, reset the enable flag to prevent retry
    if (ffrError) {
      setFfrenabled(false);
    }
  }, [ffrError]);

  useEffect(() => {
    if (
      ffrData &&
      selectedVesselName &&
      ffrData[selectedVesselName].status !== 'Processing'
    ) {
      setFfrenabled(false);
    } else {
      setFfrenabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ffrData]);

  const resetInitialLoad = useCallback(() => {
    if (selectedMPRView === KEY_MPR_LONG_AXIS) {
      setInitialLoadCPR(false);
      setInitialLoadMPRLongAxis(true);
    } else {
      setInitialLoadCPR(true);
      setInitialLoadMPRLongAxis(false);
    }
  }, [selectedMPRView]);

  const screenShotsEnabled = useMemo(() => !showFFRLabels, [showFFRLabels]);

  useEffect(() => {
    if (!initalLoadCPR && selectedMPRView === KEY_CPR) {
      setInitialLoadCPR(true);
      setFfrenabled(false);
    }
    if (!initalLoadMPRLongAxis && selectedMPRView === KEY_MPR_LONG_AXIS) {
      setInitialLoadMPRLongAxis(true);
      setFfrenabled(false);
    }
  }, [selectedMPRView, initalLoadCPR, initalLoadMPRLongAxis]);

  useEffect(() => {
    resetInitialLoad();
  });

  useEffect(() => {
    timeFuncFactory(() => {
      if (!contrastLesionData || !selectedVesselName || !sliceidx) return;
      const lesionData = contrastLesionData[selectedVesselName];
      if (lesionData) {
        const filteredData = Object.keys(lesionData).filter((key) => {
          const lesion = lesionData[key];
          return lesion.slices.indexOf(sliceidx) >= 0;
        });
        // NOTE: There could be muliple lesions across a slice
        //       For now, retrieve the first lesion
        setLesionLength(filteredData.length);
        const slicelesionData = lesionData[filteredData[lesionidx]];

        setCurrentLesionId(
          slicelesionData ? `lesion #${filteredData[lesionidx]}` : 'none'
        );
      } else {
        setCurrentLesionId('none');
      }
    }, 'vessel switch sliceidx vesselviewer lesionidx')();
  }, [contrastLesionData, selectedVesselName, sliceidx, lesionidx]);

  // Reset lesionidx if the slice change
  useEffect(() => {
    setLesionidx(0);
  }, [sliceidx]);

  const handleLesionidxChange = useCallback(
    (dx: number) => {
      setLesionidx((value) => {
        const nextValue = value + dx;
        if (nextValue > lesionLength) return value;
        if (nextValue >= 0) return nextValue;
        return value;
      });
    },
    [lesionLength]
  );

  const onMouseDownScrollBlocker = useCallback((e: React.MouseEvent) => {
    if (e.buttons === MOUSE_BUTTONS.MIDDLE) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  const saveCenterlineEditsV2 = (editedPoints: LineArray) => {
    return new Promise(async (resolve, reject) => {
      if (editedPoints.length) {
        const errorMsg = `Failed to save ${selectedVesselName?.toUpperCase()} centreline changes`;
        const appliedMsg = `${selectedVesselName?.toUpperCase()} centreline changes applied`;
        const successMsg = `${selectedVesselName?.toUpperCase()} centreline changes saved`;
        const request: EditCenterlineV2_request = {
          study_id: patientID!,
          run_id: runID!,
          vessel_id: selectedVesselName!,
          payload: {
            view_idx: cprSliceidx,
            data: editedPoints,
            version_id: versionHead!,
          },
        };

        let allowSubsequentEdit = false;
        let allUpdatedImagesLoaded = false;
        let finalMessageReceived = false;
        const closeSocketIfDone = (socket: WebSocket) => {
          if (allUpdatedImagesLoaded && finalMessageReceived) {
            socket.close();
          }
        };

        try {
          const socket = await api.getWebsocket('/ws/tasks/v2/edit-centreline');

          socket.addEventListener('open', (_event) => {
            socket.send(JSON.stringify(request));
          });

          socket.addEventListener('error', (error) => {
            console.error(error);
            socket.close();
            reject(errorMsg);
          });

          socket.addEventListener('message', (event) => {
            const json = JSON.parse(event.data);

            switch (json.type) {
              // Something went terribly wrong.
              case 'error':
                socket.close();
                reject(json.data.message);
                break;

              // The data has been updated (but not yet saved on the backend). Update the data for the CPRViewer.
              case 'result':
                {
                  const result: EditCenterlineV2_result = json.data;
                  updateVersionHead(result.new_version_id);

                  setLoadingOverlayProps({
                    open: true,
                    text: 'Fetching new imagery',
                  });
                  showToast.success(appliedMsg);
                  allowSubsequentEdit = result.can_edit;

                  // Fetch the aux annos and first image slice and get the resulting CPRVesselData.
                  if (!selectedVesselData) {
                    console.error(
                      'Editing centerline V2 without valid selectedVesselData'
                    );
                  }
                  onEditCenterlineV2Result(
                    patientID!,
                    selectedVesselName!,
                    result.result,
                    cprSliceidx,
                    selectedVesselData ? selectedVesselData.n_slices : 0
                  )
                    .then((cprVesselData) => {
                      // Exit edit mode. This seems to be the optimal point.
                      if (allowSubsequentEdit) {
                        dispatchEditModeAction(EditModeActions.stopEditing());
                      }

                      // Set the vessel viewer data with the first image slice loaded.
                      dispatchVesselDataAction(
                        VesselDataActions.addVesselViewerDataCPRViewerData(
                          patientID!,
                          runID!,
                          selectedVesselName!,
                          cprVesselData
                        )
                      );

                      // Load the remaining images.
                      onEditCenterlineFetchImages(
                        result.result.image_keys.path,
                        cprVesselData.shape.length,
                        cprSliceidx,
                        (sliceIndex: number, image: Buffer) => {
                          // Add the image to the selectedVesselViewerData.cprVesselData.
                          dispatchVesselDataAction(
                            VesselDataActions.addVesselViewerDataCPRViewerDataImage(
                              patientID!,
                              runID!,
                              selectedVesselName!,
                              sliceIndex,
                              image
                            )
                          );
                        }
                      ).finally(() => {
                        allUpdatedImagesLoaded = true;
                        closeSocketIfDone(socket);
                      });
                    })
                    .catch((e) => {
                      showToast.error('Error fetching centreline imagery');
                      setLoadingOverlayProps(undefined);
                    });
                }
                break;

              // The backend has now saved the new centerline.
              case 'result_save':
                {
                  const result: EditCenterlineV2_result_save = json.data;
                  // The data has been saved on the backend, close the socket if done, and update the version id.
                  finalMessageReceived = true;
                  closeSocketIfDone(socket);
                  if (!allowSubsequentEdit) {
                    dispatchEditModeAction(EditModeActions.stopEditing());
                  }
                  if (result.result === 'Success') {
                    updateBackendVersionHead(result.version_id);
                    setLoadingOverlayProps(undefined);
                    resolve(successMsg);
                  } else {
                    reject(errorMsg);
                  }
                }
                break;

              // We don't expect to get here.
              default:
                break;
            }
          });
        } catch (error) {
          console.error(error);
          reject(errorMsg);
        }
      } else {
        reject('No points selected to track');
      }
    });
  };

  /**
   * The user has confirmed they want to save the centerline changes, try to save then and exit edit mode.
   */
  const onConfirmReproject = () => {
    setLoadingOverlayProps({ open: true, text: 'Re-projecting Vessel' });
    onSaveCentrelineEdits();
    if (confirming?.hideAnnosAfterConfirming) {
      setShowAnnos(false);
    }
    setConfirming(undefined);
  };

  /**
   * The user doesn't want to exit edit mode just yet, we can just hide the confirm dialog and carry on.
   */
  const onDismissReproject = () => {
    setConfirming(undefined);
  };

  /**
   * Try saving the centerline edits, on success we can exit edit mode.
   */
  const onSaveCentrelineEdits = () => {
    if (!editMode.centerline) {
      return;
    }
    addEditCentrelineCount.current++;
    const addEditCount = addEditCentrelineCount.current;
    // Disable the vessel selector
    dispatchVesselDataAction(
      VesselDataActions.savingVessel(selectedVesselName)
    );

    // Use the correct edit centerline function for the CPR version we are working with.
    if (cprVersion === 2) {
      saveCenterlineEditsV2(editMode.centerline)
        .then((msg) => {
          showToast.success(String(msg));
        })
        .catch((e) => {
          showToast.error(e);
          console.error(e);
        })
        .finally(() => {
          setLoadingOverlayProps(undefined);
          // Flag that we are no longer saving the newly edited vessel on the backend, or editing.
          // To avoid race conditions, only do this if we were the last submitted add or edit.
          if (addEditCount === addEditCentrelineCount.current) {
            dispatchVesselDataAction(VesselDataActions.clearSavingVessel());
          }
        });
    } else {
      console.warn('Editing the centreline on a V1 CPR is invalid');
    }
  };

  const onStartEditing = (showAuxAnnos: boolean) => {
    if (editMode.editing) {
      return;
    }
    setShowFFRLabels(false);
    dispatchEditModeAction(
      EditModeActions.startEditing(showAnnos, showAuxAnnos)
    );
  };

  /**
   * Confirm that the centerline edits should be saved and remember if the annos should be hidden after saving.
   */
  const onSaveEdits = (hideAnnosAfterConfirming: boolean) => {
    setConfirming({ hideAnnosAfterConfirming });
  };

  const onDiscardEdits = () => {};

  if (!selectedVesselName) return null;

  const onWindowLevelsChange = (windowLevels: WindowLevels) => {
    // Set the window levels for the WebGLViewer views and contrast views.
    setContrastWindowLevels(windowLevels);
  };

  return (
    <>
      <div className="vessel-viewer" onMouseDown={onMouseDownScrollBlocker}>
        <div className="vessel-viewer__column">
          <div className="vessel-viewer__header">
            <div className="vessel-viewer__title">
              <div className="vessel-viewer__vessel-select">
                <Select
                  options={vessels.map((vessel: string) => ({
                    value: vessel,
                    label: vessel.toUpperCase(),
                  }))}
                  value={
                    vessels.includes(selectedVesselName)
                      ? selectedVesselName
                      : undefined
                  }
                  onChange={setSelectedVesselName}
                  scrollable
                  // Block changing the vessel while an add or edit is still saving on the backend.
                  // Swapping to another vessel would be fine but without per vessel data being cached locally we wouldn't be able
                  // to go back to the newly added / edited vessel until the save operation is finished.
                  disabled={savingSelectedVessel}
                />
                <AnnotationModal
                  screenshotRef={
                    selectedMPRView === KEY_CPR
                      ? mprScreenshotRef
                      : longAxialScreenshotRef
                  }
                  vesselName={selectedVesselName}
                  viewName={selectedMPRView}
                  hideText
                  screenshotDisabled={!screenShotsEnabled}
                />
                {clientConfig?.ffr_enabled && (
                  <div
                    className={cn('ffr-actions', {
                      'ffr-actions--processing':
                        ffrStatus.toLowerCase() === 'processing',
                      'ffr-actions--failed':
                        ffrStatus.toLowerCase() === 'failed' ||
                        ffrStatus.toLowerCase() === 'unavailable',
                    })}
                  >
                    <IconButton
                      selected={showFFRLabels}
                      disabled={
                        ffrStatus.toLowerCase() === 'failed' ||
                        ffrStatus.toLowerCase() === 'unavailable' ||
                        ffrStatus.toLowerCase() === 'processing'
                      }
                      color="toggle"
                      icon="ffr"
                      onClick={() => {
                        if (!editMode.editing) {
                          setShowFFRLabels(!showFFRLabels);
                        }
                      }}
                    />
                    {(ffrStatus.toLowerCase() === 'failed' ||
                      ffrStatus.toLowerCase() === 'unavailable' ||
                      ffrStatus.toLowerCase() === 'processing') && (
                      <div className="ffr-actions__failed-msg">
                        {ffrStatus.toLowerCase() === 'processing' ? (
                          <InfoIcon
                            style={{ color: THEME.colors.base.dodgerblue }}
                          />
                        ) : (
                          <FailedIcon
                            style={{ color: THEME.colors.base.dodgerblue }}
                          />
                        )}
                        <p>{ffrDetail || 'Error loading FFR data'}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <TabButtons
              tabs={[
                {
                  value: KEY_CPR,
                  label: 'Curved',
                },
                { value: KEY_MPR_LONG_AXIS, label: 'Straightened' },
              ]}
              value={selectedMPRView}
              onChange={setSelectedMPRView}
            />
          </div>
          <div className="vessel-viewer__view-wrap">
            <div
              ref={mprScreenshotRef}
              className={cn('vessel-viewer__view', {
                'vessel-viewer__view--show': selectedMPRView === KEY_CPR,
              })}
            >
              {initalLoadCPR && (
                <>
                  <CPRViewer
                    windowLevels={contrastWindowLevels}
                    windowLabel={contrastWindowLabel}
                    onWindowLevelsChange={onWindowLevelsChange}
                    editMode={editMode}
                    dispatchEditModeAction={dispatchEditModeAction}
                    onStartEditing={onStartEditing}
                    onSaveEdits={onSaveEdits}
                    onDiscardEdits={onDiscardEdits}
                    activeLine={activeLine}
                    ffrData={ffrData}
                    showFFRLabels={showFFRLabels}
                    setFfrenabled={setFfrenabled}
                    setFfrStatus={setFfrStatus}
                    setFfrDetail={setFfrDetail}
                    onSetActiveLine={setActiveLine}
                    showHighLowIndicators
                    cprVesselData={selectedVesselViewerData?.cprVesselData}
                    showAnnos={showAnnos}
                    setShowAnnos={setShowAnnos}
                    cprVersion={cprVersion}
                    cover
                  />
                  <Confirm
                    onSuccess={onConfirmReproject}
                    open={confirming !== undefined}
                    title="Are you sure?"
                    onDismiss={onDismissReproject}
                    dontShowAgainCheckbox
                  >
                    You will not be able to edit the vessel until re-projection
                    is completed.
                  </Confirm>
                  <LoadingOverlay
                    text={loadingOverlayProps?.text}
                    open={loadingOverlayProps?.open}
                  />
                </>
              )}
            </div>
            <div
              ref={longAxialScreenshotRef}
              className={cn('vessel-viewer__view', {
                'vessel-viewer__view--show':
                  selectedMPRView === KEY_MPR_LONG_AXIS,
              })}
            >
              {initalLoadMPRLongAxis && (
                <LongAxisMPRViewer
                  showHighLowIndicators={true}
                  onSetActiveLine={setActiveLine}
                  activeLine={activeLine}
                  windowLevels={contrastWindowLevels}
                  windowLabel={contrastWindowLabel}
                  onWindowLevelsChange={onWindowLevelsChange}
                  viewerData={selectedVesselViewerData?.longAxisViewerData}
                  ffrData={ffrData}
                  showFFRLabels={showFFRLabels}
                  setFfrenabled={setFfrenabled}
                  setFfrStatus={setFfrStatus}
                  setFfrDetail={setFfrDetail}
                />
              )}
            </div>
          </div>
        </div>
        <div className="vessel-viewer__col">
          <div className="vessel-viewer__header">
            <div className="vessel-viewer__title">Axial</div>
            {clientConfig?.measurement_enabled && <MeasurementToolbar />}
          </div>
          <div className="vessel-viewer__axial-rows">
            <div
              ref={highShortAxisRef}
              className={cn('vessel-viewer__wrapper', {
                active: activeLine === 'high',
              })}
            >
              <div
                className={cn('vessel-viewer__axial')}
                ref={axialScreenshotRef}
              >
                <ShortAxisMPRViewer
                  windowLevels={contrastWindowLevels}
                  onWindowLevelsChange={onWindowLevelsChange}
                  viewIdx={highSliceidx}
                  maxThreshold={
                    sliceidx > CPR_SLICE_INDICATOR_BUFFER
                      ? sliceidx - CPR_SLICE_INDICATOR_BUFFER
                      : 0
                  }
                  viewerData={selectedVesselViewerData?.shortAxisViewerData}
                  onChangeViewIdx={setHighSliceidx}
                  type="prox"
                />
              </div>
            </div>
            <div
              ref={midShortAxisRef}
              className={cn('vessel-viewer__wrapper', {
                active: activeLine === 'mid',
              })}
            >
              <div
                className={cn('vessel-viewer__axial')}
                ref={axialScreenshotRef}
              >
                <ShortAxisMPRViewer
                  windowLevels={contrastWindowLevels}
                  onWindowLevelsChange={onWindowLevelsChange}
                  viewIdx={sliceidx}
                  viewerData={selectedVesselViewerData?.shortAxisViewerData}
                  onChangeViewIdx={setSliceidx}
                  lesionId={currentLesionId}
                  type="mid"
                />
              </div>
              <LesionInfo
                handleLesionidxChange={handleLesionidxChange}
                lesionidx={lesionidx}
                lesionId={currentLesionId}
                lesionLength={lesionLength}
              />
            </div>
            <div
              ref={lowShortAxisRef}
              className={cn('vessel-viewer__wrapper', {
                active: activeLine === 'low',
              })}
            >
              <div
                className={cn('vessel-viewer__axial', 'last')}
                ref={axialScreenshotRef}
              >
                <ShortAxisMPRViewer
                  windowLevels={contrastWindowLevels}
                  onWindowLevelsChange={onWindowLevelsChange}
                  viewIdx={lowSliceidx}
                  minThreshold={
                    lowSliceidx < sliceidx + CPR_SLICE_INDICATOR_BUFFER
                      ? sliceidx + CPR_SLICE_INDICATOR_BUFFER
                      : sliceidx
                  }
                  viewerData={selectedVesselViewerData?.shortAxisViewerData}
                  onChangeViewIdx={setLowSliceidx}
                  type="dist"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VesselViewer;
