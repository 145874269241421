import * as PIXI from 'pixi.js-legacy';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Vec2 } from 'three';
import {
  COLOR_CPR_CENTRELINE,
  COLOR_MPR_SHORT_AXIS_INNER_WALL,
  COLOR_MPR_SHORT_AXIS_OUTER_WALL,
  KEY_MPR_SHORT_AXIS,
  MOUSE_BUTTONS,
  NAV_TABS,
} from '../../config';
import { useMeasurementToolContext } from '../../context/measurement-tools-context';
import { useStoreContext } from '../../context/store-context';
import {
  PlaqueMeasurementsPerSlice,
  WallSliceMeasurements,
} from '../../context/types';
import { WindowLevels } from '../../context/window-types';
import { ShortAxisViewerData, XYCoords } from '../../reducers/vessel-data';
import { useVesselStateSelector } from '../../selectors/vessels';
import { PointObject } from '../../types';
import {
  fetchPlaqueMeasurementsPerSlice,
  fetchWallSliceMeasurementsSlice,
} from '../../utils/api';
import {
  createEllipseSprite,
  createRulerSprite,
  MEASUREMENT_SETTINGS,
  onMouseDownEllipse,
  onMouseDownRuler,
  onMouseMoveEllipse,
  onMouseMoveRuler,
  onMouseUpEllipse,
  onMouseUpRuler,
  onZoomMeasurementTools,
  isRulerHandled,
} from '../../utils/measurementTools';
import {
  HandlePosition,
  HuData,
  markerPositionsInterface,
  MeasurementGraphics,
} from '../../utils/measurementTools/types';
import { getAxisMPRViewPixelPerMm } from '../../utils/measurementTools/utils';
import { ToolBar } from '../ToolBar/ToolBar';
import { OnReadyInf } from '../WebGLViewer/types';
import WebGLViewer from '../WebGLViewer/WebGLViewer';
import { processData } from './helpers';

/**
 * Clear a sprite reference.
 * @param spriteRef The sprite ref to clear.
 */
const clearSpriteRef = (spriteRef: MutableRefObject<PIXI.Graphics | null>) => {
  // Delete the current indicator.
  if (spriteRef.current) {
    spriteRef.current.destroy({
      children: true,
      baseTexture: true,
      texture: true,
    });
    spriteRef.current = null;
  }
};

export type ShortAxisMPRViewerType = 'prox' | 'mid' | 'dist';

const SETTINGS = {
  COLOR_LUMEN: COLOR_MPR_SHORT_AXIS_INNER_WALL,
  COLOR_OUTER: COLOR_MPR_SHORT_AXIS_OUTER_WALL,
  COLOR_CENTRE: COLOR_CPR_CENTRELINE,
  LINE_WIDTH: 2,
  SIMPLIFY_AMOUNT: 4,
};

interface Props {
  minThreshold?: number;
  maxThreshold?: number;
  windowLevels?: WindowLevels;
  onWindowLevelsChange?: (levels: WindowLevels) => void;
  viewerData: ShortAxisViewerData | undefined;
  viewIdx: number;
  onChangeViewIdx: (index: number) => void;
  disableControls?: boolean;
  disableKeyboardControls?: boolean;
  lesionId?: string | null;
  // This is really great for knowing which ShortAxisMPRViewer you're looking at
  type: ShortAxisMPRViewerType;
}

export const ShortAxisMPRViewer = ({
  minThreshold,
  maxThreshold,
  windowLevels,
  onWindowLevelsChange,
  viewIdx,
  onChangeViewIdx,
  disableControls = false,
  disableKeyboardControls = false,
  lesionId = null,
  viewerData,
  type: _type,
}: Props) => {
  const { selectedVesselName } = useVesselStateSelector();

  //measurement tool context
  const {
    isEllipseActive,
    isRulerActive,
    measurementToolStartPoint,
    setMeasurementToolStartPoint,
    isDraggingMeasurementToolRef,
    isClickDownMeasurementToolRef,
    measurementTargetRef,
    isMeasurementMode,
    isOverMeasurementToolRef,
    hitAreaEllipseToolRef,
    clearMeasurements,
  } = useMeasurementToolContext();

  // When not ready the WebGLViewer is not shown.
  const [ready, setReady] = useState(false);
  // This is a work around for the lack of full state management; so we can tell when the results from an async task are no longer wanted.
  // Show the annotations (ie, outer wall and lumen wall).
  const [showAnnos, setShowAnnos] = useState(true);
  // huData including mean and standard deviation within an area - used with measurement tool
  const [huData, setHuData] = useState<HuData | undefined>();
  // The current HU value under the mouse cursor.
  const hueRef = useRef<any | undefined>();
  const containerRef = useRef<PIXI.Container | null>(null);
  const appRef = useRef<PIXI.Application | null>(null);
  // The array of lumen polygon data (ie arrays of polygon vertices) (the wall surrounding the outer wall and calcium deposits).
  const lumenDataRef = useRef<Vec2[][]>([]);
  // The lumen PIXI sprite (the wall surrounding the outer wall and calcium deposits).
  const lumenSpriteRef = useRef<PIXI.Graphics | null>(null);
  // The array of outer wall polygon data (ie arrays of polygon vertices).
  const outerDataRef = useRef<Vec2[][]>([]);
  //current mouse position
  const mousePosRef = useRef<PointObject>({ x: 0, y: 0 });
  // The outer wall PIXI sprite.
  const outerSpriteRef = useRef<PIXI.Graphics | null>(null);
  // The PIXI.Container holding the annotations, all image sprites etc are added to this.
  const annoRef = useRef<PIXI.Container | null>(null);
  // The ref to this component's main div which is used when capturing screenshots (not a ref to the screenshot itself).
  const screenshotRef = useRef<HTMLDivElement | null>(null);
  // The outer WebGLViewer div element.
  const holderRef = useRef<HTMLDivElement | null>(null);
  // The default scaling to apply to the image so that it will fit snugly inside the view.
  const defaultScaleRef = useRef<number>(1);

  const crosshairSpriteRef = useRef<PIXI.Graphics | null>(null);
  // Has the polygon data for the current vessel been processed yet?
  const processedPolyData = useRef(false);

  // measurement tools
  const msmToolsContainerRef = useRef<PIXI.Container | null>(null);
  const measurementSpriteRef = useRef<MeasurementGraphics | null>(null);
  const handleTargetNameRef = useRef<HandlePosition | null>(null);
  const isEllipseActiveRef = useRef(isEllipseActive);
  const isRulerActiveRef = useRef(isRulerActive);
  //store all positions of markers of the measurement tools to work with collision detection
  const markerPositionsRef = useRef<markerPositionsInterface>({});
  const {
    patientID,
    runID,
    displayMeasurements,
    visibleTab,
  } = useStoreContext();

  const [plaqueMeasurementsPerSlice, setPlaqueMeasurementsPerSlice] = useState<
    PlaqueMeasurementsPerSlice
  >();

  const [wallSliceMeasurements, setWallSliceMeasurements] = useState<
    WallSliceMeasurements
  >();

  useEffect(() => {
    if (!patientID || !runID || !selectedVesselName) {
      return;
    }

    fetchPlaqueMeasurementsPerSlice(patientID, runID, selectedVesselName)
      .then((res: PlaqueMeasurementsPerSlice) => {
        const totalArea = res.area?.total?.map((value) =>
          Number(value.toFixed(2))
        );
        const totalBurden = res.burden?.total?.map((value) =>
          Number((value * 100).toFixed(2))
        );
        res &&
          setPlaqueMeasurementsPerSlice({
            area: { total: totalArea },
            burden: { total: totalBurden },
          });
      })
      .catch(() => {
        setPlaqueMeasurementsPerSlice(undefined);
      });

    fetchWallSliceMeasurementsSlice(patientID, runID, selectedVesselName)
      .then((res: WallSliceMeasurements) => {
        const lumenArea = res?.lumen_area?.map((value) =>
          Number(value.toFixed(2))
        );
        const outerArea = res?.outer_area?.map((value) =>
          Number(value.toFixed(2))
        );
        res &&
          setWallSliceMeasurements({
            lumen_area: lumenArea,
            outer_area: outerArea,
          });
      })
      .catch(() => {
        setWallSliceMeasurements(undefined);
      });
  }, [patientID, runID, selectedVesselName]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVesselName]);

  /**
   * Cleanup PIXI on unmount.
   */
  useEffect(() => {
    document.addEventListener('keydown', onDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', onDocumentKeyDown);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (visibleTab !== NAV_TABS.patientTab) return;

    isEllipseActiveRef.current = isEllipseActive;
    isRulerActiveRef.current = isRulerActive;
    const scale = calculateScaledLineThickness();
    // if there is a measurement tool active, reset the state
    if (
      measurementTargetRef.current &&
      measurementTargetRef.current.lineName ===
        MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse
    ) {
      createEllipseSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.ShortAxis,
        sprite: measurementTargetRef.current,
        points: measurementTargetRef.current.points,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        callback: onMouseDownEllipseNode,
        hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
        huData,
        pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
      });
    }
    if (
      measurementTargetRef.current &&
      measurementTargetRef.current.lineName ===
        MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler
    ) {
      createRulerSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.ShortAxis,
        sprite: measurementTargetRef.current,
        start: measurementTargetRef.current.startPoint,
        end: measurementTargetRef.current.endPoint,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
      });
    }
    // disable click event for ellipse
    if (isEllipseActiveRef.current && msmToolsContainerRef.current) {
      msmToolsContainerRef.current.children.forEach((child: any) => {
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse)
          child.cursor = 'move';
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler) {
          child.cursor = 'default';
          child._fillStyle.alpha = 1;
        }
      });
    }
    // disable click event for ruler
    if (isRulerActiveRef.current && msmToolsContainerRef.current) {
      msmToolsContainerRef.current.children.forEach((child: any) => {
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler)
          child.cursor = 'move';
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse) {
          child.cursor = 'default';
          child._fillStyle.alpha = 0;
        }
      });
    }

    isDraggingMeasurementToolRef.current = undefined;
    measurementTargetRef.current = null;
    handleTargetNameRef.current = null;
    isOverMeasurementToolRef.current = '';
  }, [isRulerActive, isEllipseActive]);

  /**
   * cleanup all measurement tools
   **/
  const cleanUpMeasurementTools = useCallback(() => {
    if (msmToolsContainerRef.current) {
      msmToolsContainerRef.current.destroy({
        children: true,
      });
      msmToolsContainerRef.current = null;
      measurementSpriteRef.current = null;
      handleTargetNameRef.current = null;
      isOverMeasurementToolRef.current = '';
      hitAreaEllipseToolRef.current = {};

      msmToolsContainerRef.current = new PIXI.Container();
      if (containerRef.current) {
        containerRef.current.addChild(msmToolsContainerRef.current);
      }
    }
  }, [hitAreaEllipseToolRef, isOverMeasurementToolRef]);

  useEffect(() => {
    if (!isMeasurementMode) cleanUpMeasurementTools();
    return () => {
      isDraggingMeasurementToolRef.current = undefined;
      measurementTargetRef.current = null;
    };
  }, [isMeasurementMode]);

  useEffect(() => {
    cleanUpMeasurementTools();
  }, [clearMeasurements]);

  const calculateScaledLineThickness = useCallback(() => {
    // We use `y` here, but it could've just as easily have been `x`. We just need a scalar.
    return containerRef.current?.scale.y ?? 1;
  }, []);

  const createPolygonSprite = useCallback(
    (polygon: Vec2[], color: any) => {
      if (!annoRef.current) {
        console.error('ShortAxisMPRViewer adding polygon before init');
        return;
      }
      const toScaleWidth = calculateScaledLineThickness();

      const sprite = new PIXI.Graphics();
      sprite.lineStyle(
        SETTINGS.LINE_WIDTH / toScaleWidth,
        color.replace('#', '0x')
      );

      const polygonArray: number[][] = polygon.map((p: Vec2) => [p.x, p.y]);

      const simplified = simplifyPolyline(
        polygonArray,
        SETTINGS.SIMPLIFY_AMOUNT
      );

      sprite.moveTo(simplified[0][0], simplified[0][1]);

      const t = 1;
      for (let i = 0; i < simplified.length; i++) {
        const p0 = i > 0 ? simplified[i - 1] : simplified[0];
        const p1 = simplified[i];
        const p2 = simplified[i + 1] ? simplified[i + 1] : simplified[0];
        const p3 = simplified[i + 2] ? simplified[i + 2] : simplified[1];

        const cp1x = p1[0] + ((p2[0] - p0[0]) / 6) * t;
        const cp1y = p1[1] + ((p2[1] - p0[1]) / 6) * t;

        const cp2x = p2[0] - ((p3[0] - p1[0]) / 6) * t;
        const cp2y = p2[1] - ((p3[1] - p1[1]) / 6) * t;

        sprite.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2[0], p2[1]);
      }

      annoRef.current.addChild(sprite);
      return sprite;
    },
    [calculateScaledLineThickness]
  );

  const createLumenSprites = useCallback(
    (index: number) => {
      if (lumenSpriteRef.current && lumenSpriteRef.current.parent) {
        lumenSpriteRef.current.parent.removeChild(lumenSpriteRef.current);
      }

      if (!annoRef.current) {
        console.error('ShortAxisMPRViewer createLumenSprites before init');
        return;
      }

      const polygon = lumenDataRef.current[index] ?? [];

      if (polygon.length !== 0) {
        const polySprite = createPolygonSprite(polygon, SETTINGS.COLOR_LUMEN);

        if (polySprite) {
          lumenSpriteRef.current?.destroy({
            children: true,
            baseTexture: true,
            texture: true,
          });

          lumenSpriteRef.current = polySprite;
        }
      }
    },
    [createPolygonSprite]
  );

  const createOuterSprites = useCallback(
    (index: number) => {
      if (outerSpriteRef.current && outerSpriteRef.current.parent) {
        outerSpriteRef.current.parent.removeChild(outerSpriteRef.current);
      }

      if (!annoRef.current) {
        console.error('ShortAxisMPRViewer createOuterSprites before init');
        return;
      }

      const polygon = outerDataRef.current[index] ?? [];

      if (polygon.length !== 0) {
        const polySprite = createPolygonSprite(polygon, SETTINGS.COLOR_OUTER);

        if (polySprite) {
          outerSpriteRef.current?.destroy({
            children: true,
            baseTexture: true,
            texture: true,
          });
          outerSpriteRef.current = polySprite;
        }
      }
    },
    [createPolygonSprite]
  );

  useEffect(() => {
    //clean measurement tools if slice change from CMPR
    if (isMeasurementMode) cleanUpMeasurementTools();
    // can't use onSliceChange due to infinite callback loop
    if (annoRef.current) {
      createLumenSprites(viewIdx);
      createOuterSprites(viewIdx);
    }
  }, [viewIdx, createLumenSprites, createOuterSprites]);

  /**
   * Clear all polygon data and sprites.
   */
  const clearPolyData = useCallback(() => {
    clearSpriteRef(crosshairSpriteRef);
    clearSpriteRef(lumenSpriteRef);
    clearSpriteRef(outerSpriteRef);
    lumenDataRef.current = [];
    outerDataRef.current = [];
    // The polygon data hasn't been processed.
    processedPolyData.current = false;
  }, []);

  /**
   * If the polygon data has not been processed yet then process it now and create the sprites.
   */
  const processPolyData = useCallback(() => {
    if (
      annoRef.current &&
      !processedPolyData.current &&
      viewerData?.polygonsLumen &&
      viewerData.polygonsOuter
    ) {
      lumenDataRef.current = processData(viewerData.polygonsLumen);
      outerDataRef.current = processData(viewerData.polygonsOuter);

      drawCentreCrossHair('refresh');
      createLumenSprites(viewIdx);
      createOuterSprites(viewIdx);
      // The polygon data has been processed.
      processedPolyData.current = true;
    }
  }, [viewerData, viewIdx]);

  /**
   * The WebGLViewer will call this when it is ready.
   */
  const init = useCallback(
    ({ container, app, holder, defaultScale }: OnReadyInf) => {
      annoRef.current = new PIXI.Container();
      appRef.current = app;
      container.addChild(annoRef.current);
      annoRef.current.visible = showAnnos;
      holderRef.current = holder;
      // Clear any existing polygon data.
      clearPolyData();
      // Process the polygon data (if available).
      processPolyData();

      if (!msmToolsContainerRef.current) {
        defaultScaleRef.current = defaultScale;
        msmToolsContainerRef.current = new PIXI.Container();
        msmToolsContainerRef.current.sortableChildren = true;
      }
      container.addChild(msmToolsContainerRef.current);
      containerRef.current = container;

      setReady(true);
    },
    [clearPolyData, processPolyData, setReady]
  );

  /**
   * The polygon data may not have been available when this component initialised, if not we can try initializing it now.
   */
  useEffect(() => {
    processPolyData();
    // eslint-disable-next-line
  }, [viewerData?.polygonsLumen, viewerData?.polygonsOuter]);

  const onDocumentKeyDown = (e: KeyboardEvent) => {
    //delete current measurement tool active
    if (
      MEASUREMENT_SETTINGS.DELETE_KEYS.includes(e.code) &&
      measurementTargetRef.current
    ) {
      measurementTargetRef.current.destroy({
        children: true,
      });
      measurementTargetRef.current = null;
      isDraggingMeasurementToolRef.current = undefined;
    }
  };

  /**
   * Clean up all allocated PIXI buffers etc and ensure we have exited add and editing modes.
   */
  const cleanup = () => {
    setReady(false);
    // schedule texture destruction
    const annoPrev = annoRef.current;
    if (annoPrev && annoPrev.destroy) {
      setTimeout(() => {
        annoPrev.destroy();
      }, 0);
    }
    mousePosRef.current = { x: 0, y: 0 };
    holderRef.current = null;
    annoRef.current = null;
    markerPositionsRef.current = {};
    handleTargetNameRef.current = null;
    clearPolyData();
  };

  const reset = () => {
    cleanup();
    setTimeout(() => setReady(true), 0);
  };

  const simplifyPolyline = (polyline: number[][], nth: number) => {
    return polyline.filter((_, i) => i % nth === 0);
  };

  const crosshairCenterRef = useRef<Vec2 | null>(null);

  const drawCentreCrossHair = (
    crosshairCenterCalculationMode: 'cached' | 'refresh',
    shouldDraw = true
  ) => {
    const color: any = SETTINGS.COLOR_CENTRE;

    if (!shouldDraw) return;

    if (!annoRef.current || !containerRef.current || !appRef.current) {
      console.error('ShortAxisMPRViewer adding centre crosshair before init');
      return;
    }

    if (crosshairSpriteRef.current) {
      crosshairSpriteRef.current.parent.removeChild(crosshairSpriteRef.current);
    }

    clearSpriteRef(crosshairSpriteRef);

    const toScaleWidth = calculateScaledLineThickness();
    const sprite = new PIXI.Graphics();

    sprite.lineStyle(
      SETTINGS.LINE_WIDTH / toScaleWidth,
      color.replace('#', '0x')
    );

    const calculateCenter = (container: PIXI.Container) => {
      return {
        x: container.width / 2 / container.scale.x,
        y: container.height / 2 / container.scale.y,
      };
    };

    if (
      crosshairCenterCalculationMode === 'refresh' ||
      crosshairCenterRef.current == null
    ) {
      crosshairCenterRef.current = calculateCenter(containerRef.current);
    }

    if (!crosshairCenterRef.current) return;

    const centre = crosshairCenterRef.current;

    const lineLength = 6.5 / toScaleWidth;

    sprite.moveTo(centre.x - lineLength, centre.y);
    sprite.lineTo(centre.x + lineLength, centre.y);
    sprite.moveTo(centre.x, centre.y - lineLength);
    sprite.lineTo(centre.x, centre.y + lineLength);

    crosshairSpriteRef.current = sprite;
    if (containerRef.current.children.length > 0) {
      // add to the first position to avoid that it is in front of measurement markers
      containerRef.current.addChildAt(crosshairSpriteRef.current, 1);
    } else {
      containerRef.current.addChild(crosshairSpriteRef.current);
    }
  };

  const onSliceChange = (index: number) => {
    onChangeViewIdx(index);

    // Only redraw the crosshairs if showAnnos is on
    drawCentreCrossHair('cached', showAnnos);
  };

  const onZoom = (index: number) => {
    // Only redraw the crosshairs if showAnnos is on
    drawCentreCrossHair('cached', showAnnos);
    createLumenSprites(index);
    createOuterSprites(index);
    // Set the measurement tools to the correct position for the current zoom.
    if (
      msmToolsContainerRef.current &&
      msmToolsContainerRef.current?.children.length > 0
    ) {
      const scale = calculateScaledLineThickness();
      onZoomMeasurementTools(
        MEASUREMENT_SETTINGS.PARENT.ShortAxis,
        msmToolsContainerRef.current?.children,
        scale,
        onMouseDownEllipseNode,
        () => getAxisMPRViewPixelPerMm(),
        hitAreaEllipseToolRef.current,
        huData
      );
    }
  };

  const onResize = (index: number) => {
    // Only redraw the crosshairs if showAnnos is on
    drawCentreCrossHair('cached', showAnnos);
    createLumenSprites(index);
    createOuterSprites(index);
  };

  const onMouseLeave = () => {
    if (hueRef.current && hueRef.current.setValue) {
      hueRef.current.setValue(null);
    }
  };

  const resetStateMeasurementTool = (resetState: any) => {
    if (!resetState) return;
    'measurementToolStartPoint' in resetState &&
      setMeasurementToolStartPoint(resetState.measurementToolStartPoint);
    'isDraggingMeasurementToolRef' in resetState &&
      (isDraggingMeasurementToolRef.current =
        resetState.isDraggingMeasurementToolRef);
    'measurementTargetRef' in resetState &&
      (measurementTargetRef.current = resetState.measurementTargetRef);
    'handleTargetNameRef' in resetState &&
      (handleTargetNameRef.current = resetState.handleTargetNameRef);
    'isClickDownMeasurementToolRef' in resetState &&
      (isClickDownMeasurementToolRef.current =
        resetState.isClickDownMeasurementToolRef);
    'isOverMeasurementToolRef' in resetState &&
      (isOverMeasurementToolRef.current = resetState.isOverMeasurementToolRef);
    'hitAreaEllipseToolRef' in resetState &&
      (hitAreaEllipseToolRef.current = resetState.hitAreaEllipseToolRef);
  };

  const onMouseDown = (event: React.MouseEvent) => {
    const scale = calculateScaledLineThickness();
    if (isMeasurementMode) {
      let resetState = null;
      if (isRulerActiveRef.current) {
        resetState = onMouseDownRuler({
          isClickDownMeasurementToolRef: isClickDownMeasurementToolRef.current,
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementTargetRef: measurementTargetRef.current,
          scale: scale,
          btnClicked: event.buttons,
          pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
        });
      }
      if (isEllipseActiveRef.current && msmToolsContainerRef.current) {
        resetState = onMouseDownEllipse({
          isClickDownMeasurementToolRef: isClickDownMeasurementToolRef.current,
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          scale,
          onMouseDownEllipseNode,
          hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
          huData,
          pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
          isOverMeasurementToolRef: isOverMeasurementToolRef.current,
          msmToolsContainerRef: msmToolsContainerRef.current,
          btnClicked: event.buttons,
        });
      }

      resetStateMeasurementTool(resetState);

      setMeasurementToolStartPoint(mousePosRef.current);
      measurementSpriteRef.current = new PIXI.Graphics() as MeasurementGraphics;
      msmToolsContainerRef.current &&
        msmToolsContainerRef.current.addChild(measurementSpriteRef.current);
    }
  };

  const onMouseDownEllipseNode = (event: React.MouseEvent) => {
    if (isMeasurementMode && isEllipseActiveRef.current && event.target) {
      isDraggingMeasurementToolRef.current =
        MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle;
      isClickDownMeasurementToolRef.current = true;

      measurementTargetRef.current = event.target;
    }
  };

  const onMouseDownStraightLine = (event: any) => {
    if (isMeasurementMode && isRulerActiveRef.current && event.target) {
      const scale = calculateScaledLineThickness();

      //active ruler clickin on a handle or line
      let target = event.target;
      if (isRulerHandled(target.lineName)) {
        target = target.parent;
      }
      if (target && target.startPoint) {
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: target,
          start: target.startPoint,
          end: target.endPoint,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale,
          pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
        });
      }
      //if there is a ruler active and the user clicks on the ruler, then we need to change the state to inative
      if (
        (isDraggingMeasurementToolRef.current ===
          MEASUREMENT_SETTINGS.RULER_STATE.Active ||
          isDraggingMeasurementToolRef.current ===
            MEASUREMENT_SETTINGS.RULER_STATE.Handle) &&
        measurementTargetRef.current
      ) {
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef.current,
          start: measurementTargetRef.current.startPoint,
          end: measurementTargetRef.current.endPoint,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
          state: MEASUREMENT_SETTINGS.STATES.finish,
          scale,
          pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
        });
      }
      if (isRulerHandled(event.target.lineName)) {
        isDraggingMeasurementToolRef.current =
          MEASUREMENT_SETTINGS.RULER_STATE.Handle;
        handleTargetNameRef.current = event.target.lineName;
        isClickDownMeasurementToolRef.current = true;
      }

      measurementTargetRef.current = target;
      isDraggingMeasurementToolRef.current =
        isDraggingMeasurementToolRef.current ||
        MEASUREMENT_SETTINGS.RULER_STATE.Active;
      isClickDownMeasurementToolRef.current = true;
    }
  };

  const onMouseUp = () => {
    //measurement tools mouseUp
    if (!isMeasurementMode) return;

    const scale = calculateScaledLineThickness();
    let resetState = null;
    if (isRulerActiveRef.current) {
      resetState = onMouseUpRuler({
        isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
        measurementSpriteRef: measurementSpriteRef.current,
        measurementTargetRef: measurementTargetRef.current,
        start: measurementToolStartPoint,
        end: mousePosRef.current,
        scale: scale,
        callbackMouseDownStraightLine: onMouseDownStraightLine,
        pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
      });
    }
    if (isEllipseActiveRef.current) {
      resetState = onMouseUpEllipse({
        isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
        measurementSpriteRef: measurementSpriteRef.current,
        measurementTargetRef: measurementTargetRef.current,
        scale: scale,
        callbackMouseDownEllipseNode: onMouseDownEllipseNode,
        hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
        huData,
        pixelsPerMillimeter: getAxisMPRViewPixelPerMm(),
      });
    }
    resetStateMeasurementTool(resetState);
    //end measurement tools mouseUp
  };
  const onMouseMove = (event: React.MouseEvent) => {
    // Get the mouse position over the image in the [0, 0] - [renderWidth, renderHeight] range.
    mousePosRef.current = getMousePos(event);
    const scale = calculateScaledLineThickness();
    if (isMeasurementMode) {
      // if measurement tools are active, don't zoom
      if (
        isMeasurementMode &&
        isDraggingMeasurementToolRef.current &&
        event.buttons === MOUSE_BUTTONS.BOTH
      ) {
        measurementTargetRef.current = null;
        handleTargetNameRef.current = null;
        isClickDownMeasurementToolRef.current = true;
        isDraggingMeasurementToolRef.current = undefined;
        return;
      }
      let resetState: any = null;
      if (isRulerActiveRef.current && isClickDownMeasurementToolRef.current)
        resetState = onMouseMoveRuler({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          handleTargetNameRef: handleTargetNameRef.current,
          start: measurementToolStartPoint,
          mousePosition: mousePosRef.current,
          scale: scale,
          offsetX: event.movementX,
          offsetY: event.movementY,
          recalculatePixelsToMm: () => getAxisMPRViewPixelPerMm(),
        });
      if (isEllipseActiveRef.current)
        resetState = onMouseMoveEllipse({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          start: measurementToolStartPoint,
          mousePosition: mousePosRef.current,
          scale: scale,
          offsetX: event.movementX,
          offsetY: event.movementY,
          isOverMeasurementToolRef: isOverMeasurementToolRef.current,
          hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
          huData,
          recalculatePixelsToMm: () => getAxisMPRViewPixelPerMm(),
        });

      resetStateMeasurementTool(resetState);
    }
  };

  /**
   * Get the position of the mouse over the container from the global page position in the event.
   */
  const getMousePos = (event: React.MouseEvent): XYCoords => {
    const pos = { x: 0, y: 0 };
    if (holderRef.current && containerRef.current) {
      // Get the position and size of the component on the page.
      const holderOffset = holderRef.current.getBoundingClientRect();
      pos.x =
        (event.pageX - holderOffset.x - containerRef.current.x) /
        containerRef.current.scale.x;
      pos.y =
        (event.pageY - holderOffset.y - containerRef.current.y) /
        containerRef.current.scale.y;
    }
    return pos;
  };

  const onVisibilityChange = () => {
    setShowAnnos(!showAnnos);
    if (annoRef.current && crosshairSpriteRef.current) {
      annoRef.current.visible = !showAnnos;
      crosshairSpriteRef.current.visible = !showAnnos;
    }
  };

  return (
    <div
      className="shortAxisMPRViewer"
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={screenshotRef}
    >
      {ready && (
        <WebGLViewer
          viewType={KEY_MPR_SHORT_AXIS}
          onReady={init}
          slice={viewIdx}
          onSliceChange={onSliceChange}
          disableControls={disableControls}
          disableKeyboardControls={disableKeyboardControls}
          disableLoadingProgress={true}
          maxThresholdSlice={maxThreshold}
          minThresholdSlice={minThreshold}
          windowLevels={windowLevels}
          onZoom={onZoom}
          onResize={onResize}
          onWindowLevelsChange={onWindowLevelsChange}
          shapeData={viewerData?.shape}
          imageBufferData={viewerData?.imageBufferData}
          onHueChange={(hue) => {
            if (hueRef.current && hueRef.current.setValue)
              hueRef.current.setValue(hue || '-');
          }}
          onUpdateHuData={(huData) => setHuData(huData)}
          onCleanup={cleanup}
        />
      )}
      {displayMeasurements && (
        <div className="short-axial-measurement">
          <div className="short-axial-measurement_vessel">
            <span className="short-axial-measurement_vessel_value">
              {plaqueMeasurementsPerSlice?.area?.total &&
                plaqueMeasurementsPerSlice?.area.total.length > 0 &&
                !plaqueMeasurementsPerSlice?.burden?.total && (
                  <span>
                    {plaqueMeasurementsPerSlice?.area.total[viewIdx]} mm² (-%)
                  </span>
                )}

              {!plaqueMeasurementsPerSlice?.area?.total &&
                plaqueMeasurementsPerSlice?.burden?.total &&
                plaqueMeasurementsPerSlice?.burden.total.length > 0 && (
                  <span>
                    -mm² ({plaqueMeasurementsPerSlice?.burden.total[viewIdx]}%)
                  </span>
                )}

              {plaqueMeasurementsPerSlice?.area?.total &&
              plaqueMeasurementsPerSlice?.area.total.length > 0 &&
              plaqueMeasurementsPerSlice?.burden?.total &&
              plaqueMeasurementsPerSlice?.burden.total.length > 0 ? (
                <span>
                  {plaqueMeasurementsPerSlice?.area.total[viewIdx]} mm² (
                  {plaqueMeasurementsPerSlice?.burden.total[viewIdx]}%)
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
            <span className="short-axial-measurement_vessel_values">
              <span className="short-axial-measurement_vessel_outer"></span>
              {wallSliceMeasurements?.outer_area &&
              wallSliceMeasurements?.outer_area.length > 0
                ? wallSliceMeasurements?.outer_area[viewIdx] + ' mm²'
                : '-'}
            </span>
          </div>
          <div className="short-axial-measurement_vessel">
            <span className="short-axial-measurement_vessel_label">
              Plaque Area (Burden)
            </span>
            <span className="short-axial-measurement_vessel_values">
              <span className="short-axial-measurement_vessel_inner"></span>
              {wallSliceMeasurements?.lumen_area &&
              wallSliceMeasurements?.lumen_area.length > 0
                ? wallSliceMeasurements?.lumen_area[viewIdx] + ' mm²'
                : '-'}
            </span>
          </div>
        </div>
      )}

      <ToolBar
        vessel={selectedVesselName}
        slice={`${viewIdx}`}
        HURef={hueRef}
        visibility={showAnnos}
        screenshotRef={screenshotRef}
        viewName={KEY_MPR_SHORT_AXIS}
        onVisibilityChange={onVisibilityChange}
        showVisibilityIcon={true}
      />
    </div>
  );
};
