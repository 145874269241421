import { useEffect } from 'react';
import cornerstone from 'cornerstone-core';
import './initCornerstone.js';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper';
import { Study } from '../../../context/types';
import {
  ContrastVolumeActions,
  ContrastVolumeAction,
  BlendMode,
  getStudySeriesId,
  StudySeriesMap,
  ContrastViewType,
  ContrastVolumeStatus,
} from '../../../context/contrast-types';
import { WindowLevels } from '../../../context/window-types';
import { s3Loader, loadDataset, windowLevelsToRange } from './Utils';
import PromisePool from 'es6-promise-pool';
import { useContrastContext } from '../../../context/contrast-context';
import { useWindowContext } from '../../../context/window-context';
import showToast from '../../../components/Toast/showToast';
import * as Sentry from '@sentry/react';

/**
 * Load the specifed series name from the specified study.
 */
export async function loadContrastVolume(
  study: Study,
  seriesName: string,
  activeStudySeries: StudySeriesMap,
  dispatchContrastVolumeAction: (action: ContrastVolumeAction) => void,
  windowLevels: WindowLevels
) {
  // Get the actual series.
  const series = study.series[seriesName];

  // Get the id for this study and series combo.
  const studySeriesId = getStudySeriesId(study, seriesName);

  // Tell the store that this volume is now loading.
  dispatchContrastVolumeAction({
    study,
    seriesName,
    type: ContrastVolumeActions.LOAD,
  });

  // Switch to an error state and show an alert for the user.
  const onLoadError = (
    userMessage: string,
    errorMessage?: string | undefined
  ) => {
    // Console log the error.
    console.error(errorMessage || userMessage);
    // Set the error state on the contrast CT volume in the store.
    dispatchContrastVolumeAction({
      study,
      seriesName,
      type: ContrastVolumeActions.SET_STATUS,
      status: ContrastVolumeStatus.LOAD_FAILED,
    });
    // Show an alert.
    showToast.error(userMessage);
  };

  // Check that a series was selected.
  if (!seriesName) {
    onLoadError('Series information was not found');
    return;
  }

  // Check that a study was provided and the series exists.
  if (!study || !study.series) {
    onLoadError('Study information was not found');
    return;
  }
  // Get the list of images for the CT volume.
  let slices = series.slices;
  if (!slices) {
    onLoadError('Series is missing required slice information');
    return;
  }

  // Two possible payloads for slices either the legacy "fat" payload (array)
  // or the "skinny" payload (object for constructing urls)
  if (typeof slices === 'object' && !Array.isArray(slices)) {
    // "skinny" payload
    // as javascript doesn't support format strings manual replacement of the expected
    // format string is required
    // TODO generalise this
    const { path, num_slices } = slices;
    if (!(path && typeof num_slices !== 'undefined')) {
      onLoadError(
        'Series is missing required slice information',
        'Missing path and/or num_slices from slices object'
      );
      return;
    }
    const matchFmtPattern = path.match(/%(0\d)?d/);
    if (!matchFmtPattern) {
      onLoadError(
        'Series is missing required slice information',
        'Slice path missing format pattern'
      );
      return;
    }
    const zeroPadding = parseInt(matchFmtPattern[1] || '0');
    slices = [];
    const num_slices_int = parseInt(num_slices);
    for (let i = 0; i < num_slices_int; i++) {
      slices.push(
        path.replace(
          matchFmtPattern[0],
          i.toString().padStart(zeroPadding, '0')
        )
      );
    }
  } else if (typeof slices !== 'object') {
    onLoadError(
      'Series is missing required slice information',
      'Bad type for slices found'
    );
    return;
  }

  // Set the number of image slices we need to load in the store.
  dispatchContrastVolumeAction({
    study,
    seriesName,
    type: ContrastVolumeActions.SET_IMAGE_COUNT,
    imageCount: slices.length,
  });

  // TODO: We are using the cornerstone image cache which will automagically free up memory as required.
  // It's working fine for now but if we want to explicitly clear things up we can use imageCache.
  // cornerstone.imageCache.purgeCache();
  // Set the size of the Cornerstone image cache to 1GB.
  cornerstone.imageCache.setMaximumSizeBytes(1024 * 1024 * 1024);

  // Load the CT images via Cornerstone.
  const imageIds = slices.map((slice: string) => `wadouri:${slice}`);

  // The PromisePool uses a producer function to get the next Promise when a position becomes available.
  // We originally returned a promise to load a single slice at a time with a pool size of 10 but returning
  // blocks of 5 images at a time with a promise pool of 5 is faster. Also worth noting the progress
  // refreshes seem to be slower than they should be  - possible future improvements could be made there.
  var i = 0;
  let countLoaded = 0;
  const promiseProducer = () => {
    // Stop loading images if the StudySeries is no longer active.
    if (!activeStudySeries.has(studySeriesId)) {
      return null;
    }
    // Stop loading images if they have all been loaded.
    if (i >= imageIds.length) {
      return null;
    }

    const blockIndex = i;
    let blockCount = Math.min(5, imageIds.length - i);
    i += blockCount;

    // Build up a small block of promises.
    const promises: any = [];
    for (let j = blockIndex; j < blockIndex + blockCount; j++) {
      promises.push(
        cornerstone.loadAndCacheImage(imageIds[j], { loader: s3Loader })
      );
    }

    // Wait for all images to finish loading.
    return Promise.all(promises)
      .then(() => {
        countLoaded += blockCount;
        // Update the number of images loaded for use by the UI?
        dispatchContrastVolumeAction({
          study,
          seriesName,
          type: ContrastVolumeActions.IMAGE_LOADED,
          imageCountLoaded: countLoaded,
        });
      })
      .catch((error) => {
        //do nothing as error messages are already shown for the number of slices that couldn't be loaded
      });
  };

  try {
    // Create the pool, start it, and wait for it to finish.
    // @ts-ignore
    await new PromisePool(promiseProducer, 5).start();
  } catch (error) {
    // If there was an error but we are still mounted then let the user know.
    onLoadError('Failed to load all CT volume slices');
    return;
  }

  // Abort the load if the StudySeries is no longer active.
  if (!activeStudySeries.has(studySeriesId)) {
    // console.log('Aborting load of series', studySeriesId);
    return;
  }

  // Set the volume data (the second param identifies the dataset id; it must be unique for this study and series).
  const onAllPixelDataInsertedCallback = () => {
    const vtkImageData = imageData?.vtkImageData;
    if (!vtkImageData) {
      onLoadError('Image data is empty');
      return;
    }

    // Show a warning if the volume had to cull slices (due to matching location information) when it was loaded.
    const dimensions = vtkImageData.getDimensions();
    if (dimensions[2] !== imageIds.length) {
      showToast.warning(
        `Warning: The CT volume data may be corrupt, only ${dimensions[2]} of ${imageIds.length} slices will be shown`
      );
    }

    /* NOTE: We no longer use per-series WW/WL.
    // Set the default range with the WW and WL provided with the series but if those values aren't given then we can get the full range from the data itself.
    let defaultWindowLevels: WindowLevels;
    if (series.ww && series.wl) {
      defaultWindowLevels = {
        windowWidth: parseInt(series.ww),
        windowCenter: parseInt(series.wl),
      };
    } else {
      defaultWindowLevels = rangeToWindowLevels(
        vtkImageData.getPointData().getScalars().getRange()
      );
    }
    */

    try {
      // Create the volume.
      const volume = vtkVolume.newInstance();

      // Get the spacing of the volume (ie size of each voxel in [X, Y, Z]).
      const spacing = vtkImageData.getSpacing();

      // Determine the best sample distance for the volume render.
      // That is the distance between each sample taken along the ray.
      // TODO This following commented out calculation is the sort of default the ReactVTKJS code was using but it seems like it will potentially miss slices.
      //      eg a study with spacing of [0.43, 0.43, 0.3] would get a sampleDistance of 0.47
      // const minSampleDistance = 0.7 * vec3.length(spacing);
      const minSampleDistance = 0.25;
      // Make the sample distance equal to the minimum spacing between voxels in any of the 3 directions (but with a sensible minimum sampleDistance).
      const sampleDistance = Math.max(
        Math.min(Math.min(spacing[0], spacing[1]), spacing[2]),
        minSampleDistance
      );

      // TODO: Use nearest neighbour rendering for speed?
      const property = volume.getProperty() as any;
      // property.setInterpolationTypeToNearest();
      property.setInterpolationTypeToLinear();

      // Set up the volume mapper (step size for each ray etc).
      const mapper = vtkVolumeMapper.newInstance();
      // Tell the mapper the data it is mapping.
      mapper.setInputData(vtkImageData);
      // The maximum number of samples that can be taken along a ray.
      mapper.setMaximumSamplesPerRay(2048);
      // Set the distance between each sample taken along the ray.
      mapper.setSampleDistance(sampleDistance);
      // Set the distance between each ray that is cast through the view (in pixels).
      // ie 1.0 = 1 x 1 (or 1) ray per pixel
      //    0.5 = 2 x 2 (or 4) rays per pixel
      mapper.setImageSampleDistance(1.0);
      // If set to true this tries to reduce render quality (ie temporarily adjusts the values of mapper.setSampleDistance()
      // and mapper.setImageDistance()) dynamically to ensure a smooth frame rate.
      // The target frame rate is determined by interactor.setDesiredUpdateRate().
      mapper.setAutoAdjustSampleDistances(false); // TODO: Enable this?
      // Set the slab blend mode to MIP, MinIP, or AvgIP.
      mapper.setBlendMode(BlendMode.MAXIMUM_INTENSITY_BLEND);
      // Tell the volume to use this mapper.
      volume.setMapper(mapper);
      // Set the window levels for the rgbTransferFunction.
      const defaultRange = windowLevelsToRange(windowLevels);
      property
        .getRGBTransferFunction(0)
        .setRange(defaultRange[0], defaultRange[1]);

      // Set the spacing and working volume in the store.
      dispatchContrastVolumeAction({
        study,
        seriesName,
        type: ContrastVolumeActions.SET_VOLUME_SPACING_AND_WINDOW_LEVELS,
        volume,
        spacing,
        /* NOTE: We no longer use per-series WW/WL.
        defaultWindowLevels: defaultWindowLevels,
        */
      });
    } catch (error) {
      onLoadError('Failed to create volume');
      Sentry.captureException(error);
      return;
    }

    // TODO: We now assume the correct WW/WL has been set by the other WebGLViewer based views like the MPR.
    // Set the MPRView, LongAxisMPRViewer, ShortAxisMPRViewer, VesselViewer, CPRViewer to the same WW/WL.
    /*
    if (seriesName === study.ai_assessed.contrast_id) {
      setWindowLevels({ ...defaultWindowLevels });
    }
    */

    // Set that the contrast volume has now loaded and initialized values (like the crosshairWorldPosition).
    dispatchContrastVolumeAction({
      study,
      seriesName,
      type: ContrastVolumeActions.SET_STATUS,
      status: ContrastVolumeStatus.LOADED,
    });
  };

  // Use the array of image ids to construct the data object.
  const imageData = loadDataset(imageIds, `${study.study_id}_${seriesName}`);

  // Check loadImageData succeeded.
  if (imageData) {
    // NOTE: Because the slices are cached it's possible they are already loaded at this point. If so we should
    // manually call the callback because onAllPixelDataInserted will not fire off.
    if (imageData.loaded) {
      onAllPixelDataInsertedCallback();
    } else {
      imageData.onAllPixelDataInserted(onAllPixelDataInsertedCallback);
    }
  } else {
    // Show an alert and set the error state.
    onLoadError('Failed to load slices');
    return;
  }

  return;
}

/**
 * Examine the views being shown and ensure the required series name from the specified studies are loaded.
 */
export function useContrastLoader() {
  const {
    activeStudySeries,
    contrastViews,
    dispatchContrastVolumeAction,
  } = useContrastContext();
  const { contrastWindowLevels } = useWindowContext();

  // Whenever the set of contrast views changes we need to update which study series are active, start loading (or continue loading) any
  // that need to be loaded and stop loading and free any that are no longer required.
  useEffect(() => {
    // Build a set of all the study series that should now be active.
    const nextActiveStudySeries: StudySeriesMap = new Map();
    contrastViews.forEach((contrastView) => {
      // Empty views have no associated volume.
      if (
        contrastView.viewType !== ContrastViewType.Empty &&
        contrastView.study &&
        contrastView.seriesName
      ) {
        nextActiveStudySeries.set(
          getStudySeriesId(contrastView.study, contrastView.seriesName),
          {
            study: contrastView.study,
            seriesName: contrastView.seriesName,
          }
        );
      }
    });

    // Build a set of all the study series that are newly active.
    const newStudySeries: StudySeriesMap = new Map(nextActiveStudySeries);
    activeStudySeries.forEach((_, studySeriesId) =>
      newStudySeries.delete(studySeriesId)
    );

    // Build a set of all the study series that are no longer active.
    const inactiveStudySeries: StudySeriesMap = new Map(activeStudySeries);
    nextActiveStudySeries.forEach((_, studySeriesId) => {
      inactiveStudySeries.delete(studySeriesId);
    });

    // Mutate the store's active study series.
    newStudySeries.forEach((studySeries, studySeriesId) => {
      activeStudySeries.set(studySeriesId, studySeries);
    });
    inactiveStudySeries.forEach((_, studySeriesId) => {
      activeStudySeries.delete(studySeriesId);
    });

    // Start loading any study series that are newly active.
    newStudySeries.forEach((value) => {
      loadContrastVolume(
        value.study,
        value.seriesName,
        activeStudySeries,
        dispatchContrastVolumeAction,
        contrastWindowLevels
      );
    });

    // Remove the newly inactive study series from the store's ContrastVolumeMap.
    inactiveStudySeries.forEach((studySeries) => {
      dispatchContrastVolumeAction({
        study: studySeries.study,
        seriesName: studySeries.seriesName,
        type: ContrastVolumeActions.REMOVE,
      });
    });
  }, [contrastViews]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      // Remove all study series as we can't be interested in any of them.
      const inactiveStudySeries: StudySeriesMap = new Map(activeStudySeries);
      inactiveStudySeries.forEach((studySeries, studySeriesId) => {
        dispatchContrastVolumeAction({
          study: studySeries.study,
          seriesName: studySeries.seriesName,
          type: ContrastVolumeActions.REMOVE,
        });
        activeStudySeries.delete(studySeriesId);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
